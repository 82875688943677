import { type EventDispatcher } from 'three';
import { useEffect } from 'react';

import { type IEvent3dObjectModelConfig } from '../../types/event-3d-object-model-config';
import { type BasicEvent } from '../events/basic-event';

export const useConfigureObject3dListeners = <T extends string>(
    object3D: EventDispatcher<BasicEvent<T>> | null,
    config: IEvent3dObjectModelConfig<T>,
) => {
    useEffect(() => {
        if (object3D) {
            config.forEach((handler, eventType) => object3D.addEventListener<T>(eventType, handler));
        }
    }, [object3D]);
};
