import { type RootState, useThree } from '@react-three/fiber';
import React from 'react';
import { type OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// Takes a lookAt position and adjusts the camera accordingly

interface TOverridenRoot extends Omit<RootState, 'controls'> {
    controls: OrbitControls;
}

export const CameraLookAt = React.memo(({ lookAtPosition }: { lookAtPosition: [number, number, number] }) => {
    // "Hook into" camera and set the lookAt position
    const { camera, controls } = useThree<TOverridenRoot>();
    camera.lookAt(lookAtPosition[0], lookAtPosition[1], lookAtPosition[2]);
    controls?.target?.set(lookAtPosition[0], lookAtPosition[1], lookAtPosition[2]);

    // Return an empty fragment
    return <></>;
});
