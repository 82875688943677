import { type MeshStandardMaterial } from 'three';

import { type BasicEvent } from '../basic-event';
import { WheelEventType } from './event-types';
import { createMaterialMutationEventHandler } from '../create-material-mutation-event-handler';
import { ChangeExternalMaterialEvent, type TRoughnessType } from '../common-events/change-external-material';

const mapNumberToRougnessType: Record<TRoughnessType, number> = {
    glossy: 0,
    semiMatte: 0.5,
    matte: 1,
};

const setMaterials = (material: MeshStandardMaterial, event: BasicEvent<WheelEventType>) => {
    if (event instanceof ChangeExternalMaterialEvent && event.type === WheelEventType.RIM_MATERIAL) {
        switch (material.name) {
            case 'rim':
                material.roughness = mapNumberToRougnessType[event.roughnessType];
                break;
        }
    }
};

export const rimExternalMaterialChangeEventHandler = createMaterialMutationEventHandler(setMaterials);
