import { type IDtoResult } from '../queries/use-get-pairs';

export const getIdsStringFromList = (data: IDtoResult) => {
    return data.reduce((acc, currentValue, currentIndex) => {
        const isLast = currentIndex === data.length - 1;
        const idsString = `${currentValue[0].person_id},${currentValue[1].person_id}${isLast ? '' : ','}`;

        return acc.concat(idsString);
    }, '');
};
