import React, { useEffect, useState } from 'react';
import { type Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { useGLTF } from '@react-three/drei';
import { TextGeometry, type TextGeometryParameters } from 'three/examples/jsm/geometries/TextGeometry';
import { Box3, Mesh, MeshStandardMaterial, type Object3D } from 'three';

import { BASE_API } from '../../../constants/base-api';
import { type IPlateProps } from '../../../types/plate-props';

const RUSSIAN_PLATES_FONT_BASE_CONFIG = {
    height: 0.0001,
    curveSegments: 8,
    bevelEnabled: true,
    bevelThickness: 0.01,
    bevelSize: 0.0001,
    bevelOffset: 0,
    bevelSegments: 4,
};

const RUSSIAN_PLATES_FONT_NUMBER_CONFIG = {
    size: 0.08,
    ...RUSSIAN_PLATES_FONT_BASE_CONFIG,
};

const RUSSIAN_PLATES_FONT_REGION_CONFIG = {
    size: 0.05,
    ...RUSSIAN_PLATES_FONT_BASE_CONFIG,
};

const MARKER_POINTS_AND_SYMBOLS_MATCH_MASK = [
    'Letter_1',
    'Number_1',
    'Number_2',
    'Number_3',
    'Letter_2',
    'Letter_3',
    'Number_11',
    'Number_12',
    'Number_13',
];

const RUSSIAN_PLATES_FONT_MATERIAL = new MeshStandardMaterial({
    color: '#000',
    metalness: 1,
    roughness: 0.01,
});

const PLATE_SYMBOL_NAME = 'Plate_Symbol';

const createSymbolMesh = (font: Font, symbol: string, config: Omit<TextGeometryParameters, 'font'>) => {
    const geometry = new TextGeometry(symbol, {
        font,
        ...config,
    });
    geometry.center();
    const mesh = new Mesh(geometry, RUSSIAN_PLATES_FONT_MATERIAL);
    mesh.name = PLATE_SYMBOL_NAME;
    mesh.position.set(0, 0, 0);
    return mesh;
};

export const RussianPlates = ({ symbols, frontPlatesCoords, backPlatesCoords }: IPlateProps) => {
    const loader = new FontLoader();
    const [font, setFont] = useState<Font>();
    const [markerMeshes, setMarkerMeshes] = useState<Object3D[]>([]);
    const numberPlatesModel = useGLTF(`${BASE_API}/project/obves/Frame_3.glb`);
    useEffect(() => {
        // Подгрузка необходимых ресурсов и объявление стейта
        loader
            .loadAsync(`${BASE_API}/static/assets/numbers/roadtypeface.json`)
            .then(setFont)
            .then(() => {
                if (numberPlatesModel) {
                    const markerMeshes: Object3D[] = [];
                    numberPlatesModel.scene.traverse((child) => {
                        if (MARKER_POINTS_AND_SYMBOLS_MATCH_MASK.includes(child.name)) {
                            markerMeshes.push(child);
                        }
                    });
                    setMarkerMeshes(markerMeshes);
                }
            });
    }, [numberPlatesModel]);

    useEffect(() => {
        if (font && markerMeshes.length) {
            const previousLetters = numberPlatesModel.scene.children.filter((m) => m.name === PLATE_SYMBOL_NAME);
            numberPlatesModel.scene.remove(...previousLetters);
            symbols.forEach((symbol, index) => {
                let symbolMesh: Mesh;
                if (index <= 5) {
                    symbolMesh = createSymbolMesh(font, symbol, RUSSIAN_PLATES_FONT_NUMBER_CONFIG);
                } else {
                    symbolMesh = createSymbolMesh(font, symbol, RUSSIAN_PLATES_FONT_REGION_CONFIG);
                }
                numberPlatesModel.scene.add(symbolMesh);
                const bb = new Box3().setFromObject(symbolMesh, true);
                const object3DHeight = bb.max.y - bb.min.y;
                const matchedMarker = markerMeshes.find(
                    (mm) => mm.name === MARKER_POINTS_AND_SYMBOLS_MATCH_MASK[index],
                );
                symbolMesh.position.set(
                    matchedMarker?.position?.x || 0,
                    (matchedMarker?.position?.y || 0) + object3DHeight / 2,
                    matchedMarker?.position?.z || 0,
                );
            });
        }
    }, [symbols, markerMeshes, font]);

    return (
        <>
            <primitive position={frontPlatesCoords} object={numberPlatesModel.scene} />
            <primitive
                position={backPlatesCoords}
                rotation={[0, Math.PI, 0]}
                object={numberPlatesModel.scene.clone(true)}
            />
        </>
    );
};
