import { useQuery } from 'react-query';

import { useAccessToken } from '../components/access-token-provider/access-token-provider';
import { getUsers } from '../util/get-users';
import { type IProfile } from '../features/profile/profile';
import { type BridgeSendInterface } from '../components/bridge-context-provider/bridge-context-provider';

export const useGetUsers = (ids: string, bridgeSend: BridgeSendInterface<any>, enabled: boolean = true) => {
    const token = useAccessToken();
    return useQuery(
        ['users', ids],
        async () => {
            return await getUsers<IProfile>(ids, bridgeSend, token, 'photo_100');
        },
        { enabled },
    );
};
