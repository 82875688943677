import { MODEL_URLS } from 'constants/query-keys';
import { type IModelData } from 'queries/use-GLTF-urls';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export const useRelatedRim = () => {
    const queryClient = useQueryClient();
    const { rims } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};

    const findRelatedRim = useCallback((carId: string) => {
        const rimsFiltered = rims?.map((rim) => rim.model_id);
        const alikeRim = rimsFiltered?.find(
            (rim) =>
                rim.toLowerCase().includes(carId.toLowerCase()) ||
                rim.replaceAll('-', '_').toLowerCase().includes(carId.toLowerCase()),
        );

        return alikeRim || 'PW_DC09'
    }, [rims])

    return findRelatedRim
};
