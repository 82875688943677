import axios from 'axios';
import { useQuery } from 'react-query';

export const useGetSalonPromotedIds = () => {
    const PROJECT_ID = 'obfsgn0y';
    const DATASET = 'production';
    const FILTERS_QUERY = encodeURIComponent(`*[_type == 'salon']{models}.models`);
    const URL = `https://${PROJECT_ID}.apicdn.sanity.io/v2021-10-21/data/query/${DATASET}?query=${FILTERS_QUERY}`;

    return useQuery('salon-promoted-ids', async () => {
        const response = await axios.get<{ result: string[][] }>(URL);

        const data = response.data.result.flat(2).filter(Boolean);
        if (!data || !Array.isArray(data)) return [];

        return data;
    });
};
