import React, { createContext, useContext } from 'react';

export const AccessTokenContext = createContext<{ token: string; setToken: (value: string) => void } | null>(null);

export const AccessTokenProvider = (props: {
    children: React.ReactNode;
    value: string;
    setToken: (value: string) => void;
}) => {
    const { children, value, setToken } = props;

    const providerValue = {
        token: value,
        setToken,
    };

    return <AccessTokenContext.Provider value={providerValue}>{children}</AccessTokenContext.Provider>;
};
export const useAccessToken = () => {
    const value = useContext(AccessTokenContext);

    if (value === null) {
        throw new Error('useAccessToken must be used within a AccessTokenProvider.');
    }
    return value.token;
};

export const useGetSetAccessToken = () => {
    const value = useContext(AccessTokenContext);
    if (value === null) {
        throw new Error('useAccessToken must be used within a AccessTokenProvider.');
    }
    return value.setToken;
};
