import React from 'react';
import chunk from 'lodash/chunk';
import { Button, type ButtonProps } from '@vkontakte/vkui';

import { useMediaQuery } from '../../hooks/use-media-query';

import styles from './keyboard.module.css';

interface IProps {
    symbols: string[];
    onButtonClick: (symbol: string) => void;
    onDeleteSymbol: VoidFunction;
    disabled?: boolean;
}

const KeyBoardButton = ({ ...props }: ButtonProps) => {
    return <Button style={{ minWidth: '60px' }} size="l" mode="secondary" stretched {...props} />;
};

export const Keyboard = ({ symbols, onButtonClick, onDeleteSymbol, disabled = false }: IProps) => {
    const matches = useMediaQuery('(min-width: 762px)');
    const chunkSize = matches ? 6 : 5;
    const firstChunk = symbols.slice(0, chunkSize - 1);
    const otherChunks = chunk(symbols.slice(4), chunkSize);
    let lastChunk: string[] | undefined = [];

    if (otherChunks[otherChunks.length - 1].length !== chunkSize) {
        lastChunk = otherChunks.pop();
    }

    return (
        <>
            <div className={styles.keyboardGridContainer}>
                {firstChunk.map((symbol) => {
                    return (
                        <KeyBoardButton disabled={disabled} onClick={() => onButtonClick(symbol)}>
                            {symbol}
                        </KeyBoardButton>
                    );
                })}
                <KeyBoardButton appearance="negative" onClick={onDeleteSymbol}>
                    {'\u232b'}
                </KeyBoardButton>
                {otherChunks.map((ch) => (
                    <>
                        {ch.map((symbol) => {
                            return (
                                <KeyBoardButton disabled={disabled} onClick={() => onButtonClick(symbol)}>
                                    {symbol}
                                </KeyBoardButton>
                            );
                        })}
                    </>
                ))}
            </div>
            <div
                style={{
                    display: 'flex',
                    padding: '0 16px',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    paddingBottom: '16px',
                }}
            >
                {lastChunk?.map((symbol) => {
                    return (
                        <KeyBoardButton
                            style={{
                                flexBasis: `${Math.round(100 / chunkSize)}%`,
                                flexGrow: 0,
                                margin: matches ? '0 8px' : '0 4px',
                                justifySelf: 'center',
                            }}
                            disabled={disabled}
                            onClick={() => onButtonClick(symbol)}
                        >
                            {symbol}
                        </KeyBoardButton>
                    );
                })}
            </div>
        </>
    );
};
