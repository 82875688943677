import { FormItem } from '@vkontakte/vkui';
import React from 'react';
import { DebouncedSlider } from 'components/debounced-slider';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { TuningHeader } from '../tuning-header';
import { useGetCarModel } from '../../../../hooks/use-get-car-model';
import { vehicleConfigs } from '../../../../engine/vehicle-configs';

export const EditTirePickTireSizeModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const modelInfo = useGetCarModel(currentValue.id);

    const handleTireSizeReset = React.useCallback(() => {
        setCurrentValue({ tire_diameter: vehicleConfigs.defaults.tire_diameter });
    }, [currentValue]);

    return (
        <div>
            <TuningHeader children="Выберите размер" onReset={handleTireSizeReset} />
            <FormItem top="Диаметр">
                <DebouncedSlider
                    min={28}
                    max={modelInfo?.config?.maxTireSize || vehicleConfigs.vehicles.default.maxTireSize}
                    step={1}
                    currentValue={currentValue.tire_diameter}
                    setCurrentValue={(value) => setCurrentValue({ tire_diameter: value })}
                />
            </FormItem>
        </div>
    );
};
