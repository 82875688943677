import { type IEvent3dObjectModelConfig } from '../../../types/event-3d-object-model-config';
import { BodyEventTypes } from './event-types';
import { colorChangeEventHandler } from './color-change-event';
import { changeInteriorEventHandler } from './change-interior-event-handler';
import { changeBodyMaterialHandler } from './change-body-material';
import { changeGlassTone } from './change-glass-tone';
import { changeInteriorMaterialHandler } from './change-interior-material';

const mapBodyConfiguration: IEvent3dObjectModelConfig<BodyEventTypes> = new Map();
mapBodyConfiguration.set(BodyEventTypes.COLOR_CHANGE, colorChangeEventHandler);
mapBodyConfiguration.set(BodyEventTypes.INTERIOR_CHANGE, changeInteriorEventHandler);
mapBodyConfiguration.set(BodyEventTypes.GLASS_TONE_CHANGE, changeGlassTone);
mapBodyConfiguration.set(BodyEventTypes.CHANGE_BODY_MATERIAL, changeBodyMaterialHandler);
mapBodyConfiguration.set(BodyEventTypes.CHANGE_INTERIOR_MATERIAL, changeInteriorMaterialHandler);

export default mapBodyConfiguration;
