import { Mesh, type MeshStandardMaterial } from 'three';

import { type BasicEvent } from './basic-event';

export const createMaterialMutationEventHandler = <T>(
    mutateMaterial: (material: MeshStandardMaterial, event: BasicEvent<T>) => void,
) => {
    return (event: BasicEvent<T>) =>
        event.target.traverseVisible((child) => {
            if (child instanceof Mesh) {
                // Cast shadows from mesh.
                child.castShadow = true;

                // Ensure that the material is always an array.
                const materials = Array.isArray(child.material) ? child.material : [child.material];

                // Set material properties for each material.
                materials.forEach((material) => {
                    mutateMaterial(material, event);
                });
            }
        });
};
