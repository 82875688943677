import { Icon24Share } from '@vkontakte/icons';
import { Card, ModalCard, ScreenSpinner, Spacing, Title, useAdaptivityWithJSMediaQueries } from '@vkontakte/vkui';

import { type IWithId } from '../../../../types/interfaces';
import { CustomHeader } from '../../../../components/custom-header/custom-header';
import { ButtonFullWidth } from '../../../../components/button-full-width/button-full-width';
import { useSendToBattle } from '../../../../queries/use-send-to-battle';
import { PAGE_BATTLE_CHALLENGE, router } from '../../../../router';
import { useShareApp } from '../../../../queries/use-share-app';
import { useGetProvideAccess } from '../../../../hooks/use-get-provide-access';
import { useGetSetAccessToken } from '../../../../components/access-token-provider/access-token-provider';
import { useBridge } from '../../../../components/bridge-context-provider/bridge-context-provider';
import { type IProject } from '../../../../queries/use-get-all-projects';

import { BASE_API } from '../../../../constants/base-api';
interface IProps extends IWithId {
    project: IProject | null;
    onClose: () => void;
}

export const ModalProjectShare = ({ id, project, onClose }: IProps) => {
    const { mutateAsync: sendToBattle } = useSendToBattle();
    const { mutateAsync: shareApp } = useShareApp();
    const provideAccess = useGetProvideAccess();
    const setAccessToken = useGetSetAccessToken();
    const { bridgeSend } = useBridge();
    const { isDesktop } = useAdaptivityWithJSMediaQueries();

    return (
        <ModalCard onClose={onClose} header={<CustomHeader>Поделиться проектом</CustomHeader>} id={id}>
            {project?.id ? (
                <>
                    <Spacing size={16} />
                    <Card
                        style={{
                            backgroundImage: `url(${BASE_API}/${project.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div
                            style={{
                                height: 300,
                                width: 280,
                            }}
                        />
                    </Card>
                    <Spacing size={8} />
                    <ButtonFullWidth
                        onClick={() => {
                            if (project.id) {
                                sendToBattle(project.id).then(() => {
                                    localStorage.setItem('isFromTuning', 'yes');
                                    router.pushPage(PAGE_BATTLE_CHALLENGE);
                                });
                            }
                        }}
                        height={44}
                    >
                        <Title weight="3" level="3">
                            Отправить на баттл
                        </Title>
                    </ButtonFullWidth>
                    <Spacing size={8} />
                    <ButtonFullWidth
                        onClick={async () => {
                            let checkScopes;
                            if (isDesktop) {
                                checkScopes = await bridgeSend('VKWebAppCheckAllowedScopes', {
                                    scopes: 'photos',
                                });
                            }
                            const isPhotosScopeAllowed = checkScopes?.result[0].allowed;
                            if (!isPhotosScopeAllowed) {
                                const token = await provideAccess('photos');
                                if (token) {
                                    setAccessToken(token);
                                    setTimeout(() => shareApp(project.id), 100);
                                }
                            } else {
                                await shareApp(project.id);
                            }
                        }}
                        before={<Icon24Share />}
                        mode="secondary"
                        height={44}
                    >
                        <Title weight="3" level="3">
                            Поделиться с друзьями
                        </Title>
                    </ButtonFullWidth>
                </>
            ) : (
                <>
                    <Spacing size={100} />
                    <ScreenSpinner />
                    <Spacing size={100} />
                </>
            )}
        </ModalCard>
    );
};
