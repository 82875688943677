import axios from '../axios-instance';
import { type IDtoResult } from './use-get-pairs';
import { getUserId } from '../util/get-user-id';
import { BASE_API } from '../constants/base-api';

export const fetchPairs = async () => {
    const userId = getUserId();
    const { data } = await axios.get<IDtoResult>(`${BASE_API}/voting/doubles?vk_id=${userId}`);
    return data;
};
