import React from 'react';
import { Icon24BrowserBack, Icon24Dismiss } from '@vkontakte/icons';
import { IconButton, ModalPage, ModalPageHeader, SimpleCell, Avatar } from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';
import { PAGE_SALONS, PAGE_SALON_ITEM } from 'router';

import { ButtonFullWidth } from '../../../../components/button-full-width/button-full-width';
import { useUsedSalonIdsLayer } from '../../../../components/advertisement-provider/advertisement-provider';

import { getSanityURL } from '../../../../util/get-sanity-url';
import { type IWithId } from '../../../../types/interfaces';

import styles from './ad-modal.module.css';

interface IProps extends IWithId {}

export const AdModal = ({ id }: IProps) => {
    const router = useRouter();
    const { usedSalons } = useUsedSalonIdsLayer();

    const handleAllSalons = React.useCallback(() => {
        router.pushPage(PAGE_SALONS);
    }, []);

    const handleSalonOpen = React.useCallback((salonId: string) => {
        router.pushPage(PAGE_SALON_ITEM, { id: salonId });
    }, []);

    const handleCloseModal = React.useCallback(() => {
        router.popPageIfModal();
    }, []);

    return (
        <ModalPage
            header={
                <ModalPageHeader
                    before={
                        <IconButton onClick={handleCloseModal}>
                            <Icon24BrowserBack />
                        </IconButton>
                    }
                    after={
                        <IconButton onClick={handleCloseModal}>
                            <Icon24Dismiss />
                        </IconButton>
                    }
                >
                    Доступно в салонах
                    <div className={styles.subtitle}>{`Похожие услуги найдены в ${usedSalons.length} салонах`}</div>
                </ModalPageHeader>
            }
            id={id}
        >
            <div style={{ height: '100%' }}>
                {usedSalons.map((salon) => (
                    <SimpleCell
                        key={salon.id}
                        expandable="auto"
                        before={<Avatar size={48} src={getSanityURL(salon.image)} />}
                        subtitle={<div className={styles.subtitleCell}>{salon.address}</div>}
                        onClick={() => handleSalonOpen(salon.id)}
                    >
                        {salon.name}
                    </SimpleCell>
                ))}
            </div>
            <div className={styles.buttonsContainer}>
                <ButtonFullWidth onClick={handleAllSalons} mode="secondary" height={44}>
                    Перейти ко всем салонам
                </ButtonFullWidth>
                <div style={{ height: '12px' }}></div>
            </div>
        </ModalPage>
    );
};
