import { Caption, Link, ModalPageHeader } from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';

import { useCallback } from 'react';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { type IDefaults } from '../../../../types/tuning-model';
import { PAGE_TUNING } from '../../../../router';
import { CustomHeader } from '../../../../components/custom-header/custom-header';
import { useMakeCameraTransitions } from '../../../../engine/hooks/use-make-camera-transitions';
import { Keyboard } from '../../../../components/keyboard/keyboard';

import styles from '../order-modal/order-modal.module.css';
import { useBoolean } from '../../../../hooks/use-boolean';

import { useSetModalHeight } from '../../../../hooks/use-set-modal-height';

import modalStyles from './edit-plate-number-modal.module.css';

type TCurrentValue = Pick<IDefaults, 'number_plates'>;

enum InputModeEnum {
    ALPHABETIC,
    NUMERIC,
}

const getRussianNumberPlatesInputMode = (value: TCurrentValue['number_plates']['number']): InputModeEnum => {
    if (value.length === 0 || (value.length >= 4 && value.length < 6)) {
        return InputModeEnum.ALPHABETIC;
    }
    return InputModeEnum.NUMERIC;
};

const areValidNumbers = (value: TCurrentValue['number_plates']['number']) => {
    return value.length >= 8;
};

export const EditPlateNumberModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const router = useRouter();
    const { rollback } = useMakeCameraTransitions();
    const { value: isValid, setValue, setTrue: setIsValidTrue } = useBoolean(true);
    const currentLocation = location.hash.split('?')[0].split('/')[1];

    useSetModalHeight();

    const onKeyboardButtonClick = useCallback(
        (symbol: string) => {
            const symbolValue = symbol === 'У' ? 'Y' : symbol;
            setCurrentValue({
                number_plates: {
                    number: [...currentValue.number_plates.number, symbolValue],
                    type: 'RU',
                },
            });
            setIsValidTrue();
        },
        [currentValue.number_plates.number],
    );

    const onResetButtonClick = useCallback(() => {
        setCurrentValue({
            number_plates: {
                number: currentValue.number_plates.number.slice(0, currentValue.number_plates.number.length - 1),
                type: 'RU',
            },
        });
    }, [currentValue.number_plates.number]);

    return (
        <div>
            <ModalPageHeader
                className={styles.modalHeaderText}
                after={
                    <Link
                        onClick={() => {
                            const isValid = areValidNumbers(currentValue.number_plates.number);
                            if (isValid) {
                                rollback();
                                router.pushPage(`/${currentLocation}`);
                            }
                            setValue(isValid);
                        }}
                    >
                        Готово
                    </Link>
                }
                before={
                    <Link
                        onClick={() => {
                            setCurrentValue({
                                number_plates: {
                                    type: undefined,
                                    number: [],
                                },
                            });
                            rollback();
                            router.pushPage(PAGE_TUNING);
                        }}
                    >
                        Сбросить
                    </Link>
                }
            >
                <CustomHeader>Введите номер</CustomHeader>
            </ModalPageHeader>
            {!isValid && (
                <Caption level="1" className={modalStyles.errorMessage}>
                    Введите оставшиеся символы
                </Caption>
            )}
            <Keyboard
                onButtonClick={onKeyboardButtonClick}
                onDeleteSymbol={onResetButtonClick}
                disabled={currentValue.number_plates.number.length === 9}
                symbols={
                    getRussianNumberPlatesInputMode(currentValue.number_plates.number) === InputModeEnum.NUMERIC
                        ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
                        : ['A', 'B', 'E', 'K', 'M', 'H', 'E', 'O', 'P', 'C', 'T', 'У', 'X']
                }
            />
        </div>
    );
};
