import React, { Children, useState } from 'react';

import { type IWithChildren } from '../../types/interfaces';
import { StepContextProvider } from './step-context-provider';

interface IProps extends IWithChildren {
    onLastStep?: () => void;
}

export const Steps = ({ children, onLastStep }: IProps) => {
    const [step, setStep] = useState(0);
    const childrenArray = Children.toArray(children);
    const count = childrenArray.length;

    const moveToNextStep = React.useCallback(() => {
        if (step + 1 > count - 1) {
            onLastStep?.();
        } else {
            setStep(step + 1);
        }
    }, [step, setStep]);

    const moveToPrevStep = React.useCallback(() => setStep(Math.max(step - 1, 0)), [step]);
    const moveToBeginning = () => setStep(0);

    return (
        <StepContextProvider
            value={{
                moveToNextStep,
                moveToPrevStep,
                moveToBeginning,
            }}
        >
            {childrenArray[step]}
        </StepContextProvider>
    );
};
