import React from 'react';

import { type IDefaults } from 'types/tuning-model';

import Canvas from './canvas';

interface IProps {
    currentVehicle: IDefaults;
    setVehicle: React.Dispatch<any>;
    setCanvasLoaded: (canvasLoaded: boolean) => void;
}

export default function Engine({ currentVehicle, setVehicle, setCanvasLoaded }: IProps) {
    return (
        <Canvas
            currentVehicle={currentVehicle}
            setVehicle={setVehicle}
            cameraAutoRotate={false}
            setCanvasLoaded={setCanvasLoaded}
        />
    );
}
