import React, { useState } from 'react';
import { useRouter } from '@happysanta/router';

import { Spacing, Tabs, TabsItem } from '@vkontakte/vkui';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { type ITuningModalProps } from '../tuning-modal-props';
import { PAGE_TUNING } from '../../../../router';
import { ColorPicker } from '../../../../components/color-picker/color-picker';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { ChangeGlassToneEvent } from '../../../../engine/events/body-events-handlers/change-glass-tone';
import { useMakeCameraTransitions } from '../../../../engine/hooks/use-make-camera-transitions';
import { TuningHeader } from '../tuning-header';

export const EditGlassTone = ({ page = PAGE_TUNING, contestConfig }: ITuningModalProps) => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const router = useRouter();
    const vehicle = useDetailsContext().vehicle;
    const { lookAtGlassTone, lookAtHeadlightsTone } = useMakeCameraTransitions();
    const [tab, setTab] = useState<'glass' | 'headlights'>('glass');

    const handleGlassToneReset = () => {
        const glass_tone = contestConfig?.glass_tone;
        const headLightsTone = contestConfig?.headLightsTone;

        vehicle.current?.dispatchEvent(
            new ChangeGlassToneEvent(
                vehicle.current,
                tab === 'glass' ? 'glass' : 'glass_light',
                tab === 'glass' ? glass_tone : headLightsTone,
            ),
        );

        setCurrentValue({ glass_tone });
        router.pushPage(page);
    };

    return (
        <div>
            <TuningHeader children="Выберите тонировку" onReset={handleGlassToneReset} />

            <Tabs>
                <TabsItem
                    onClick={() => {
                        lookAtGlassTone();
                        setTab('glass');
                    }}
                    selected={tab === 'glass'}
                >
                    Стекла
                </TabsItem>
                <TabsItem
                    onClick={() => {
                        lookAtHeadlightsTone();
                        setTab('headlights');
                    }}
                    selected={tab === 'headlights'}
                >
                    Фары
                </TabsItem>
            </Tabs>

            <Spacing size={10} />
            <div style={{ width: '100%' }}>
                <ColorPicker
                    width="100%"
                    onChange={(color, event) => {
                        vehicle.current?.dispatchEvent(
                            new ChangeGlassToneEvent(
                                vehicle.current,
                                tab === 'glass' ? 'glass' : 'glass_light',
                                color.hex,
                            ),
                        );
                        if (tab === 'glass') {
                            setCurrentValue({ glass_tone: color.hex });
                        } else {
                            setCurrentValue({ headLightsTone: color.hex });
                        }
                        event.stopPropagation();
                    }}
                    color={tab === 'glass' ? currentValue.glass_tone : currentValue.headLightsTone}
                />
            </div>
        </div>
    );
};
