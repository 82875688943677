import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';

export interface ILeader {
    id: string;
    rating: number;
}

export const useGetLeaders = () => {
    return useQuery('leaders', async () => {
        const { data } = await axios.get<ILeader[]>(`${BASE_API}/voting/leaderboard`);
        return data;
    });
};
