import {
    Icon28CarOutline,
    Icon28ArticleOutline,
    Icon28CupOutline,
    Icon28UserOutline,
    Icon28StarsOutline,
} from '@vkontakte/icons';

import { PAGE_BATTLE_CHALLENGE, PAGE_PROFILE, PAGE_SALONS, PAGE_TUNING, PAGE_CONTEST } from '../router';

export const sectionButtons = [
    {
        label: 'Тюнинг',
        subtitle: 'Докажи, что твой сетап – самый лучший! Участвуй в баттле и попади в список лидеров!',
        Icon: <Icon28CarOutline />,
        path: PAGE_TUNING,
    },
    {
        label: 'Конкурс',
        subtitle: 'Собери свою Skoda Octavia и выиграй машину из шоу Dragtimes',
        Icon: <Icon28StarsOutline fill="#FF5263" />,
        path: PAGE_CONTEST,
    },
    {
        label: 'Салоны',
        subtitle: 'Находи нужные услуги у профессионалов и реализуй сетап своей мечты!',
        Icon: <Icon28ArticleOutline fill="#68B158" />,
        path: PAGE_SALONS,
    },
    {
        label: 'Баттл',
        subtitle: 'Соревнуйся с другими пользователями',
        Icon: <Icon28CupOutline fill="#7032B9" />,
        path: PAGE_BATTLE_CHALLENGE,
    },
    {
        label: 'Профиль',
        subtitle: 'Сохраняй свои сетапы',
        Icon: <Icon28UserOutline fill="#F2A43A" />,
        path: PAGE_PROFILE,
    },
];
