import { type Object3D } from 'three';

import { BasicEvent } from '../basic-event';

export type TRoughnessType = 'glossy' | 'semiMatte' | 'matte';

export class ChangeExternalMaterialEvent<T> extends BasicEvent<T> {
    private _roughnessType: TRoughnessType;

    constructor(target: Object3D, roughnessType: TRoughnessType, type: T) {
        super(type, target);
        this._roughnessType = roughnessType;
    }

    get roughnessType(): TRoughnessType {
        return this._roughnessType;
    }

    set roughnessType(value: TRoughnessType) {
        this._roughnessType = value;
    }
}
