import React from 'react';
import { Placeholder } from '@vkontakte/vkui';
import { Icon56GhostOutline } from '@vkontakte/icons';

export const BattlePlaceholder = () => {
    return (
        <Placeholder
            icon={<Icon56GhostOutline fill="var(--vkui--color_icon_accent)" />}
            header="Сейчас нет доступных баттлов"
        >
            Вернитесь позже
        </Placeholder>
    );
};
