import React from 'react';
import { FormItem } from '@vkontakte/vkui';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { vehicleConfigs } from '../../../../engine/vehicle-configs';
import { DebouncedSlider } from '../../../../components/debounced-slider';
import { useGetCarModel } from '../../../../hooks/use-get-car-model';
import { TuningHeader } from '../tuning-header';

export const EditLiftModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const modelInfo = useGetCarModel(currentValue.id);
    const min = modelInfo?.config?.minLift || -8;
    const max = modelInfo?.config?.maxLift || 0;

    const handleLiftReset = React.useCallback(() => {
        setCurrentValue({
            lift: vehicleConfigs.defaults.lift,
            wheel_offset: vehicleConfigs.defaults.wheel_offset,
        });
    }, [currentValue]);

    return (
        <div>
            <TuningHeader children="Выберите размеры" onReset={handleLiftReset} />
            <FormItem top="Высота подвески">
                <DebouncedSlider
                    min={0}
                    max={max - min}
                    step={1}
                    currentValue={currentValue.lift - min}
                    setCurrentValue={(v) => setCurrentValue({ lift: v + min })}
                />
            </FormItem>
            <FormItem top="Ширина колеи">
                <DebouncedSlider
                    min={0}
                    max={0.1}
                    step={0.01}
                    currentValue={currentValue.wheel_offset}
                    setCurrentValue={(v) => setCurrentValue({ wheel_offset: v })}
                    visualizeValue={(v) => Math.round(v * 100)}
                />
            </FormItem>
        </div>
    );
};
