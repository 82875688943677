import React from 'react';
import { SplitLayout } from '@vkontakte/vkui';

interface SplitLayoutContextInterface {
    setPopout: (el: React.ReactNode | null) => void;
    setModal: (el: React.ReactNode | null) => void;
}

export const SplitLayoutContext = React.createContext<SplitLayoutContextInterface>({
    setPopout: () => {},
    setModal: () => {},
});

interface SplitLayoutContextProviderInterface {
    children: React.ReactNode;
}

function SplitLayoutContextProvider({ children }: SplitLayoutContextProviderInterface) {
    const [popout, setPopout] = React.useState<null | React.ReactNode>(null);
    const [modal, setModal] = React.useState<null | React.ReactNode>(null);
    const [pendingModal, setPendingModal] = React.useState<null | React.ReactNode>(null);

    React.useEffect(() => {
        setModal(pendingModal);
    }, [pendingModal]);

    const handleModalChange = React.useCallback((modal: React.ReactNode | null) => {
        setModal(null);
        if (!modal) return;
        setPendingModal(modal);
    }, []);

    return (
        <SplitLayoutContext.Provider value={{ setPopout, setModal: handleModalChange }}>
            <SplitLayout modal={modal} popout={popout}>
                {children}
            </SplitLayout>
        </SplitLayoutContext.Provider>
    );
}

export default React.memo(SplitLayoutContextProvider);
