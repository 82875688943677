import { type MeshStandardMaterial, type Object3D, type Texture } from 'three';

import { BasicEvent } from '../basic-event';
import { BodyEventTypes } from './event-types';
import { createMaterialMutationEventHandler } from '../create-material-mutation-event-handler';

export class ChangeInteriorMaterial extends BasicEvent<BodyEventTypes> {
    private _texture?: Texture;

    constructor(target: Object3D, texture?: Texture) {
        super(BodyEventTypes.CHANGE_INTERIOR_MATERIAL, target);
        this._texture = texture;
    }

    get texture(): Texture | undefined {
        return this._texture;
    }

    set texture(value: Texture | undefined) {
        this._texture = value;
    }
}

const setMaterials = (material: MeshStandardMaterial, event: BasicEvent<BodyEventTypes>) => {
    if (event instanceof ChangeInteriorMaterial) {
        switch (material.name) {
            case 'interior':
                material.setValues({ map: event.texture || null });
                if (material.map) {
                    material.map.needsUpdate = true;
                }
                material.needsUpdate = true;
                break;
        }
    }
};

export const changeInteriorMaterialHandler = createMaterialMutationEventHandler(setMaterials);
