import { FormItem } from '@vkontakte/vkui';
import { DebouncedSlider } from 'components/debounced-slider';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

export const EditOffsetModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    return (
        <FormItem top="Ширина колеи">
            <DebouncedSlider
                min={0}
                max={0.1}
                step={0.01}
                currentValue={currentValue.wheel_offset}
                setCurrentValue={(v) => setCurrentValue({ wheel_offset: v })}
                visualizeValue={(v) => Math.round(v * 100)}
            />
        </FormItem>
    );
};
