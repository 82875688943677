import { useLocation, useRouter } from '@happysanta/router';
import { type ViewProps } from '@vkontakte/vkui';

export const useCommonViewProps = () => {
    const location = useLocation();
    const router = useRouter();

    const commonViewProps = (id: string) => {
        const props = {
            history: location.hasOverlay() ? [] : location.getViewHistory(id),
            id,
            activePanel: location.getViewActivePanel(id) as string,
            onSwipeBack: () => router.popPage(),
        };

        return props as ViewProps;
    };

    return { commonViewProps };
};
