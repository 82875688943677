import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { type IDtoPair } from './use-get-pairs';
import { BASE_API } from '../constants/base-api';

export const useGetBestProject = (vkId: number) => {
    return useQuery(['best_project', vkId], async () => {
        const result = await axios.get<IDtoPair>(`${BASE_API}/project/bestproject?vk_id=${vkId}`);
        return result.data;
    });
};
