import { useRouter } from '@happysanta/router';
import { Button, ButtonGroup, Image, Snackbar } from '@vkontakte/vkui';
import { BASE_API } from 'constants/base-api';
import { useIsContest } from 'queries/use-is-contest';
import React, { useEffect, useState } from 'react';
import { PAGE_CONTEST } from 'router';

interface IParticipateContestSnackbarProps {
    offsetY?: number;
}

interface IBanner {
    image: string;
    title: string;
    text_button: string;
} 

const BANNER_URL = `${BASE_API}/project/minibanner`;

export const ParticipateContestSnackbar = ({ offsetY = 0 }: IParticipateContestSnackbarProps) => {
    const { data: isContest } = useIsContest();
    const router = useRouter();
    const [bannerData, setBannerData] = useState<IBanner>();
    const [snackbar, setSnackbar] = useState<boolean>(true);

    const handleSnackbarClick = React.useCallback(() => {
        router.pushPage(PAGE_CONTEST);
    }, []);

    useEffect(() => {
        async function fetchBanner() {
            try {
                const response = await fetch(BANNER_URL);
                const data: IBanner = await response.json();
                setBannerData(data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchBanner();
    }, []);

    return (
        <>
            {isContest ? (
                <Snackbar
                    onClose={() => setSnackbar(false)}
                    style={{ display: snackbar ? '' : 'none', zIndex: 100000 }}
                    duration={5000}
                    offsetY={snackbar ? offsetY : 0}
                    before={<Image src={`${BASE_API}${bannerData?.image}`} size={40} />}
                >
                    <div>{bannerData?.title}</div>
                    <ButtonGroup style={{ marginTop: 8 }}>
                        <Button size="s" onClick={handleSnackbarClick}>
                            {bannerData?.text_button}
                        </Button>
                    </ButtonGroup>
                </Snackbar>
            ) : null}
        </>
    );
};
