import React from 'react';
import { useRouter } from '@happysanta/router';

import { useUsedSalonIdsLayer, useUsedService } from '../../../../components/advertisement-provider/advertisement-provider';
import { AD_MODAL } from '../../../../router';

import styles from './ad-button.module.css';

export const AdButton = () => {
    const router = useRouter();
    const { usedSalons } = useUsedSalonIdsLayer();
    const { usedService } = useUsedService();

    const handleAdClick = React.useCallback(() => {
        router.pushModal(AD_MODAL);
    }, []);

    return (
        <button onClick={handleAdClick} className={styles.adButton}>
            {usedService ?? "Доступно в салонах"}
            <div className={styles.countContainer}>{usedSalons.length}</div>
        </button>
    );
};
