import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { vehicleConfigs } from 'engine/vehicle-configs';

import uniqBy from 'lodash/uniqBy';

import { useRelatedRim } from 'hooks/use-related-rim';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { changeColorFn } from '../edit-color-modal/utils';
import { BASE_API } from '../../../../constants/base-api';
import { CardWithTick } from '../../../../components/card-with-tick/card-with-tick';
import { MODEL_URLS } from '../../../../constants/query-keys';
import { type IModelData } from '../../../../queries/use-GLTF-urls';
import { CategoriesSplit } from '../../../../components/categories-split/categories-split';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { TuningHeader } from '../tuning-header';

import '../styles.css';

export const EditCarPickModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const detailsHelper = useDetailsContext();
    const queryClient = useQueryClient();
    const { cars } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};
    // const { rims } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};
    const { tires } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};
    const categories = uniqBy(cars, (car) => car.mark).map((car) => ({
        label: car.mark,
        value: car.mark,
    }));
    const preSelectedCar = cars?.find((car) => car.model_id === currentValue.id)?.mark;
    const [selected, setSelected] = useState<string | undefined>(preSelectedCar);

    const relatedTire = useCallback(
        (carId: string, mark: string) => {
            const tiresFiltered = tires?.map((tire) => tire.model_id);
            const alikeTire = tiresFiltered?.find((tire) => tire.includes(carId));

            if (alikeTire) {
                return alikeTire;
            }

            const firstTireFromCarMark = tires?.filter((tire) => tire.mark === mark)[0].model_id;

            if (firstTireFromCarMark) {
                return firstTireFromCarMark;
            }

            return vehicleConfigs.defaults.tire;
        },
        [tires],
    );

    // const relatedRim = useCallback(
    //     (
    //         carId: string,
    //         // mark: string
    //     ) => {
    //         return useRelatedRim(carId)

    //         // const firstRimFromCarMark = rims?.filter((rim) => rim.mark === mark)[0].model_id;

    //         // if (firstRimFromCarMark) {
    //         //     return firstRimFromCarMark;
    //         // }
    //     },
    //     [],
    // );

    const relatedRim = useRelatedRim();

    const handleCarReset = React.useCallback(() => {
        setCurrentValue({
            ...vehicleConfigs.defaults,

            rim: vehicleConfigs.defaults.rim,
            tire: vehicleConfigs.defaults.tire,
            mark: vehicleConfigs.defaults.mark,
            addons: vehicleConfigs.defaults.addons,
            tire_diameter: vehicleConfigs.defaults.tire_diameter,
            lift: vehicleConfigs.defaults.lift,
        });
    }, []);

    return (
        <div>
            <TuningHeader children="Модель машины" onReset={handleCarReset} />
            <CategoriesSplit categories={categories} selected={selected} setSelected={setSelected}>
                {cars
                    ?.filter((car) => car.mark === selected)
                    ?.map((car) => {
                        const bgImage = car.photo_icon
                            ? `${BASE_API}${car.photo_icon}`
                            : `${BASE_API}/static/assets/images/car.png`;
                        return (
                            <div key={car.model_id}>
                                <CardWithTick
                                    backgroundUrl={bgImage}
                                    header={car.model_name}
                                    onClick={() => {
                                        detailsHelper.clearDetails();
                                        const minLift = car?.config?.minLift || -8;
                                        const maxLift = car?.config?.maxLift || 0;
                                        const defaultLift = car?.config?.defaultLift;
                                        const rim_diameter =
                                            car.config.rim_diameter || vehicleConfigs.defaults.rim_diameter;

                                        if (detailsHelper.vehicle.current)
                                            changeColorFn(
                                                'all',
                                                setCurrentValue,
                                                vehicleConfigs.defaults.color,
                                                detailsHelper.vehicle.current,
                                            );

                                        setCurrentValue({
                                            ...vehicleConfigs.defaults,
                                            id: car.model_id,
                                            rim: relatedRim(car.model_id),
                                            tire: relatedTire(car.model_id, car.mark),
                                            mark: car.mark,
                                            addons: [],
                                            tire_diameter: car.config.maxTireSize - 2,
                                            lift: defaultLift || Math.ceil((minLift + maxLift) / 2),
                                            rim_diameter,
                                        });
                                    }}
                                    ticked={car.model_id === currentValue.id}
                                />
                            </div>
                        );
                    })}
            </CategoriesSplit>
        </div>
    );
};
