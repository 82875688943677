import { useRouter } from '@happysanta/router';
import { Icon24Dismiss } from '@vkontakte/icons';
import { ModalPage, ModalPageHeader } from '@vkontakte/vkui';

import { BASE_API } from 'constants/base-api';
import CardProject from 'components/card-project';
import { useGetBattleProfileProjects } from 'queries/use-get-battle-profile-projects';

import { type IProfile } from 'features/profile/profile';
import { type IWithId } from 'types/interfaces';

interface BattleProfileProjectsModalsInterface extends IWithId {
    profile: IProfile | null;
}

export const BattleProfileProjectsModal = ({ id, profile }: BattleProfileProjectsModalsInterface) => {
    const router = useRouter();
    const { data = [] } = useGetBattleProfileProjects(profile?.id || '');
    const newData = data.map((item) => `${BASE_API}/${item.image}`);

    return (
        <ModalPage hideCloseButton dynamicContentHeight id={id}>
            <ModalPageHeader
                transparent
                separator={false}
                style={{ textAlign: 'center', padding: '0 12px' }}
                after={<Icon24Dismiss onClick={() => router.popPageIfModal()} />}
            >
                Проекты участника
            </ModalPageHeader>
            <div>
                {data
                    .sort((a, b) => b.id - a.id)
                    .map((item, index) => (
                        <CardProject
                            dataImages={newData}
                            key={item.id}
                            item={item}
                            index={index}
                            profile={profile}
                            dataLenght={data.length}
                            haveCell={true}
                        />
                    ))}
            </div>
        </ModalPage>
    );
};
