import { memo, useEffect, useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import { type EventDispatcher, type Material, type Mesh, Vector3 } from 'three';
import { useQueryClient } from 'react-query';

import { WHEEL_COEF } from '../../constants/wheel-coef';

import { useGetTireModel } from '../../hooks/use-get-tire';
import { vehicleConfigs } from '../vehicle-configs';
import getSetMaterialProperties from '../hooks/get-set-material-properties';
import { type ITransformItem } from '../../types/tuning-model';
import { MODEL_URLS } from '../../constants/query-keys';
import { type IModelData } from '../../queries/use-GLTF-urls';
import { useDetailsContext } from '../../components/details-context-provider/details-context-provider';
import { useConfigureObject3dListeners } from '../hooks/use-configure-object3d-listeners';
import { ChangeExternalMaterialEvent } from '../events/common-events/change-external-material';
import { type BasicEvent } from '../events/basic-event';
import { WheelEventType } from '../events/wheel-events-handlers/event-types';
import mapWheelsConfiguration from '../events/wheel-events-handlers';
import { RimBrakeColorChangeEvent } from '../events/wheel-events-handlers/rim-brake-color-change-event-handler';
import { linePoint } from '../../util/line-point';
import { type IWheelsProps } from '../../types/wheel-props';

const TIRE_ID = 'BMW_5-Series_G30_M_Tire';
const DEFAULT_TIRE_URL = 'https://vkcars.dnzg.dev/project/tiremodels/BMW_5-Series_G30_M_Tire_UH6r5Om_G7j9xx0.glb';
const DEFAULT_RIM_URL = 'https://vkcars.dnzg.dev/project/wheelmodels/BMW_5-Series_G30_M_Disk_0k8Bn08_WP60np9.glb/';

// Wheels.
const Wheels = memo(
    ({
        rim,
        rim_diameter,
        rim_width,
        rim_color,
        // tire,
        tire_diameter,
        offset,
        wheelbase,
        wheelStartingPoint = 0,
        axleHeight,
        color,
        tire_color,
        rim_color_secondary,
        rim_material,
    }: IWheelsProps) => {
        const queryClient = useQueryClient();
        const urls = queryClient.getQueryData<IModelData>(MODEL_URLS);
        const { wheelsRef } = useDetailsContext();
        const { setObjectMaterials } = getSetMaterialProperties();
        // Load models.
        const rim_url = urls?.rims?.find((u) => u.model_id === rim)?.link || DEFAULT_RIM_URL;

        const rimGltf = useGLTF(rim_url);
        // const tireGltf = useGLTF(urls?.tires?.find((u) => u.model_id === tire)?.link || '');
        const tireGltf = useGLTF(DEFAULT_TIRE_URL);

        const tireConfig = useGetTireModel(TIRE_ID);

        // Scale tires.
        const tireGeometry = useMemo(() => {
            const wheelWidth = (rim_width * WHEEL_COEF) / 4 / 100;
            const wheelWidthScale =
                wheelWidth /
                (tireConfig?.width ||
                    vehicleConfigs.wheels.tires[TIRE_ID]?.width ||
                    vehicleConfigs.wheels.tires.default.width);

            const tireOD =
                (tireConfig?.od || vehicleConfigs.wheels.tires[TIRE_ID]?.od || vehicleConfigs.wheels.tires.default.od) /
                2;
            const tireID =
                tireConfig?.id / 2 ||
                vehicleConfigs.wheels.tires[TIRE_ID]?.id / 2 ||
                vehicleConfigs.wheels.tires.default.id / 2;

            const newOd = (tire_diameter * WHEEL_COEF) / 10 / 2;
            const newId = (rim_diameter * WHEEL_COEF) / 18 / 2;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const geometry = tireGltf.scene.children[0].geometry.clone();

            geometry.scale(wheelWidthScale, 1, 1);

            const positionAttribute = geometry.getAttribute('position');
            const positionArray = positionAttribute.array;

            for (let i = 0, l = positionAttribute.count; i < l; i++) {
                const startVector = new Vector3().fromBufferAttribute(positionAttribute, i);

                const centerVector = new Vector3(startVector.x, 0, 0);

                const centerDist = centerVector.distanceTo(startVector);

                const rimDist = centerDist - tireID;

                const percentOut = rimDist / (tireOD - tireID);

                const newRimDist = (percentOut * (newOd - newId) + newId) / 10;

                const setVector = linePoint(centerVector, startVector, newRimDist);

                positionArray[i * 3 + 1] = setVector.y;
                positionArray[i * 3 + 2] = setVector.z;
            }

            return geometry;
        }, [tireGltf.scene.children, rim_diameter, rim_width, TIRE_ID, tire_diameter]);

        // Calculate rim scale as a percentage of diameter.
        const odScale = useMemo(
            () =>
                ((rim_diameter * WHEEL_COEF) / 100 + 0.1) /
                (vehicleConfigs.wheels.rims[rim]?.od || vehicleConfigs.wheels.rims.default.od),
            [rim, rim_diameter],
        );

        // Calculate rim width.
        const widthScale = useMemo(
            () =>
                (rim_width * WHEEL_COEF) /
                100 /
                (vehicleConfigs.wheels.rims[rim]?.width || vehicleConfigs.wheels.rims.default.width),
            [rim, rim_width],
        );

        // Set rim color.
        useEffect(() => {
            setObjectMaterials(rimGltf.scene, rim_color);
            wheelsRef.current?.dispatchEvent(
                new ChangeExternalMaterialEvent(wheelsRef?.current, rim_material, WheelEventType.RIM_MATERIAL),
            );
            wheelsRef.current?.dispatchEvent(new RimBrakeColorChangeEvent(wheelsRef?.current, rim_color_secondary));
        }, [rimGltf.scene, rim_color, wheelsRef.current]);

        useEffect(() => {
            setObjectMaterials(tireGltf.scene, tire_color);
        }, [tireGltf.scene, setObjectMaterials, tire_color, color]);

        // Build wheel transforms.
        const wheelTransforms = useMemo((): ITransformItem[] => {
            return [
                {
                    key: 'FL',
                    name: 'FL',
                    position: [offset, axleHeight, wheelStartingPoint + wheelbase / 2],
                    rotation: [0, 0, 0],
                },
                {
                    key: 'FR',
                    name: 'FR',
                    position: [-offset, axleHeight, wheelStartingPoint + wheelbase / 2],
                    rotation: [0, Math.PI, 0],
                },
                {
                    key: 'RL',
                    name: 'RL',
                    position: [offset, axleHeight, wheelStartingPoint - wheelbase / 2],
                    rotation: [0, 0, 0],
                },
                {
                    key: 'RR',
                    name: 'RR',
                    position: [-offset, axleHeight, wheelStartingPoint - wheelbase / 2],
                    rotation: [0, Math.PI, 0],
                },
            ];
        }, [offset, axleHeight, wheelbase]);

        useConfigureObject3dListeners(
            wheelsRef.current as unknown as EventDispatcher<BasicEvent<WheelEventType>>,
            mapWheelsConfiguration,
        );

        return (
            <group ref={wheelsRef} name="Wheels">
                {wheelTransforms.map((transform) => (
                    <group {...transform}>
                        <primitive name="Rim" object={rimGltf.scene.clone()} scale={[widthScale, odScale, odScale]} />
                        <mesh
                            name="Tire"
                            material={(tireGltf.scene.children[0] as Mesh).material as Material}
                            geometry={tireGeometry}
                            castShadow={true}
                        />
                    </group>
                ))}
            </group>
        );
    },
);

export default Wheels;
