import React from 'react';
import debounce from 'lodash/debounce';
import { Slider, type SliderProps } from '@vkontakte/vkui';

interface DebouncedSliderInterface extends SliderProps {
    setCurrentValue: (value: number) => void;
    currentValue: number;
    visualizeValue?: (v: number) => string | number;
}

export const DebouncedSlider = ({
    setCurrentValue,
    currentValue,
    min,
    max,
    step,
    visualizeValue,
    ...restProps
}: DebouncedSliderInterface) => {
    const [value, setValue] = React.useState(currentValue);

    React.useEffect(() => setValue(currentValue), [currentValue]);

    const debouncedValueChange = React.useMemo(() => debounce(setCurrentValue, 250), [setCurrentValue]);

    const handleValueChange = React.useCallback(
        (tire_diameter: number) => {
            setValue(tire_diameter);
            debouncedValueChange(tire_diameter);
        },
        [debouncedValueChange],
    );

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '100%',
                gap: '20px',
            }}
        >
            <Slider
                style={{ width: '100%' }}
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleValueChange}
                {...restProps}
            />
            <div style={{ width: '50px' }}>{visualizeValue?.(value) || value}</div>
        </div>
    );
};
