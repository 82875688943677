import type React from 'react';
import { useCallback, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { PerspectiveCamera } from 'three';

import { CAMERA_INITIAL_POSITION } from '../../constants/viewer-initial-config';

export default function Screenshot({ setVehicle }: { setVehicle: React.Dispatch<{ picture: string }> }) {
    const { gl, scene, camera, size } = useThree();
    const HEIGHT = 700;
    const WIDTH = 800;

    // Take screenshot.
    const takeScreenshot = useCallback(() => {
        if (camera instanceof PerspectiveCamera) {
            camera.position.setX(CAMERA_INITIAL_POSITION.x);
            camera.position.setY(CAMERA_INITIAL_POSITION.y);
            camera.position.setZ(CAMERA_INITIAL_POSITION.z);
            const focLength = camera.getFocalLength();

            setTimeout(() => {
                // Fixed render size.
                camera.aspect = WIDTH / HEIGHT;
                camera.setFocalLength(100);
                camera.updateProjectionMatrix();
                gl.setSize(WIDTH, HEIGHT);
                gl.render(scene, camera);
                setVehicle({ picture: gl.domElement.toDataURL('image/png') });

                // Restore canvas size.
                camera.aspect = size.width / size.height;
                camera.setFocalLength(focLength);
                camera.updateProjectionMatrix();
                gl.setSize(size.width, size.height);
                gl.render(scene, camera);
            }, 500);
        }
    }, [gl, scene, camera, size]);

    // Listen for screenshot event.
    useEffect(() => {
        window.addEventListener('takeScreenshot', takeScreenshot);
        return () => window.removeEventListener('takeScreenshot', takeScreenshot);
    }, [takeScreenshot]);

    return null;
}
