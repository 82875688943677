import { type Object3D } from 'three';

export class BasicEvent<T> {
    private _type: T;
    private _target: Object3D;

    constructor(type: T, target: Object3D) {
        this._type = type;
        this._target = target;
    }

    get type(): T {
        return this._type;
    }

    set type(value: T) {
        this._type = value;
    }

    get target(): Object3D {
        return this._target;
    }

    set target(value: Object3D) {
        this._target = value;
    }
}
