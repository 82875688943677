import type React from 'react';
import { type Object3D } from 'three';

import {
    ColorChangeEvent,
    type TNamePearl,
    type TNamePartOfCar,
} from '../../../../engine/events/body-events-handlers/color-change-event';
import { BodyEventTypes } from '../../../../engine/events/body-events-handlers/event-types';
import { type IDefaults } from '../../../../types/tuning-model';

const DEFAULT_PEARL_OBJECT = (status: boolean = false) => {
    return {
        all: status,
        body_bonnet: status,
        body_leftSide: status,
        body_rightSide: status,
        body_backBumper: status,
        body_boot: status,
        interior: status,
        both_sides: status,
    };
};

export const PEARL_BASE_COLOR = '#a17dff';

export type TCurrentValue = Pick<
    IDefaults,
    | 'color'
    | 'leftSideColor'
    | 'rightSideColor'
    | 'bonnetColor'
    | 'bumperColor'
    | 'bootColor'
    | 'interiorColor'
    | 'isPearl'
>;
export const getColorAccordingToCategory = (value: TCurrentValue) => {
    const result: Record<TNamePartOfCar, string | undefined> = {
        all: value.color,
        body_leftSide: value.leftSideColor,
        body_rightSide: value.rightSideColor,
        body_bonnet: value.bonnetColor,
        body_backBumper: value.bumperColor,
        body_boot: value.bootColor,
        interior: value.interiorColor,
        both_sides: value.rightSideColor === value.leftSideColor ? value.leftSideColor : undefined,
    };
    return result;
};

export const getIsPearl = (value: Pick<IDefaults, 'isPearl'>) => {
    const result: Record<'isPearl', TNamePearl> = {
        isPearl: value.isPearl,
    };

    return result;
};

export const changeColorFn = (
    category: TNamePartOfCar,
    setColor: React.Dispatch<Partial<IDefaults>>,
    color: string,
    vehicle: Object3D,
    isPearl: TNamePearl = DEFAULT_PEARL_OBJECT(),
) => {
    const colorChangeEvent = new ColorChangeEvent(
        category === 'interior' ? BodyEventTypes.INTERIOR_CHANGE : BodyEventTypes.COLOR_CHANGE,
        category,
        vehicle,
        color,
        isPearl,
    );
    vehicle.dispatchEvent(colorChangeEvent);
    switch (category) {
        case 'all':
            setColor({
                color,
                leftSideColor: color,
                bonnetColor: color,
                bumperColor: color,
                rightSideColor: color,
                bootColor: color,
                isPearl,
            });
            break;
        case 'body_leftSide':
            setColor({ leftSideColor: color, isPearl });
            break;
        case 'body_rightSide':
            setColor({ rightSideColor: color, isPearl });
            break;
        case 'body_bonnet':
            setColor({ bonnetColor: color, isPearl });
            break;
        case 'body_backBumper':
            setColor({ bumperColor: color, isPearl });
            break;
        case 'body_boot':
            setColor({ bootColor: color, isPearl });
            break;
        case 'interior':
            setColor({ interiorColor: color });
            break;
        case 'both_sides':
            setColor({
                leftSideColor: color,
                rightSideColor: color,
                isPearl,
            });
    }
};

export const pearlObject = (
    currentValue: TCurrentValue,
    selected: TNamePartOfCar,
    isPearl: boolean = false,
): TNamePearl => {
    let result = { ...getIsPearl(currentValue)?.isPearl, [selected]: isPearl };

    if (selected === 'both_sides') {
        result.body_leftSide = false;
        result.body_rightSide = false;
    }

    if (selected === 'all') {
        result = DEFAULT_PEARL_OBJECT(isPearl);
    }

    return result;
};
