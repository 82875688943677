import { type MeshStandardMaterial, type Object3D } from 'three';

import { BasicEvent } from '../basic-event';
import { WheelEventType } from './event-types';
import { createMaterialMutationEventHandler } from '../create-material-mutation-event-handler';

export class RimBrakeColorChangeEvent extends BasicEvent<WheelEventType> {
    private _color: string;

    constructor(target: Object3D, color: string) {
        super(WheelEventType.RIM_BRAKE_COLOR_CHANGE, target);
        this._color = color;
    }

    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = value;
    }
}

const setMaterials = (material: MeshStandardMaterial, event: BasicEvent<WheelEventType>) => {
    // Switch material name.
    if (event instanceof RimBrakeColorChangeEvent) {
        switch (material.name) {
            case 'rim_brake':
                material.color.setStyle(event.color);
                break;
            default:
        }
    }
};

export const rimBrakeColorChangeEventHandler = createMaterialMutationEventHandler(setMaterials);
