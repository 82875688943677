import { type FC } from 'react';

import { type IDefaults } from '../../../types/tuning-model';
import { RussianPlates } from './russian-plates';
import { type IPlateProps } from '../../../types/plate-props';

interface IProps extends IPlateProps {
    type: NonNullable<IDefaults['number_plates']['type']>;
}

const PLATES_COMPONENTS_MAP: Record<NonNullable<IDefaults['number_plates']['type']>, FC<IPlateProps>> = {
    RU: RussianPlates,
};

export const NumberPlates = ({ frontPlatesCoords, backPlatesCoords, type, symbols }: IProps) => {
    return PLATES_COMPONENTS_MAP[type]({ frontPlatesCoords, symbols, backPlatesCoords });
};
