import { type MeshStandardMaterial } from 'three';

import { type BasicEvent } from '../basic-event';
import { BodyEventTypes } from './event-types';
import { createMaterialMutationEventHandler } from '../create-material-mutation-event-handler';
import { ChangeExternalMaterialEvent, type TRoughnessType } from '../common-events/change-external-material';

export const mapNumberToRoughnessType: Record<TRoughnessType, number> = {
    glossy: 0,
    semiMatte: 0.2,
    matte: 0.6,
};

const setMaterials = (material: MeshStandardMaterial, event: BasicEvent<BodyEventTypes>) => {
    if (event instanceof ChangeExternalMaterialEvent && event.type === BodyEventTypes.CHANGE_BODY_MATERIAL) {
        switch (material.name) {
            case 'body':
                material.roughness = mapNumberToRoughnessType[event.roughnessType];
                break;
        }
    }
};

export const changeBodyMaterialHandler = createMaterialMutationEventHandler(setMaterials);
