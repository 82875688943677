import React, { useCallback, useEffect, useState } from 'react';
import {
    AdaptivityProvider,
    Avatar,
    Caption,
    CardGrid,
    Counter,
    Div,
    FixedLayout,
    Group,
    Header,
    HorizontalCell,
    HorizontalScroll,
    Image,
    Link,
    Panel,
    PanelHeader,
    PanelHeaderButton,
    PlatformProvider,
    ScreenSpinner,
    Search,
    Spacing,
    SplitLayout,
    Subhead,
    Tabs,
    TabsItem,
    Title,
} from '@vkontakte/vkui';
import { useLocation, useParams, useRouter } from '@happysanta/router';
import { Icon16Chevron, Icon24ChevronLeft } from '@vkontakte/icons';
import uniq from 'lodash/uniq';

import { useMakeOrder } from '../../../hooks/use-make-order';

import { ABOUT_SALON_MODAL_PAGE, PAGE_SALONS } from '../../../router';
import { getSanityURL } from '../../../util/get-sanity-url';
import { AboutSalonModal } from '../modals/about-salon-modal/about-salon-modal';
import { ButtonFullWidth } from '../../../components/button-full-width/button-full-width';
import { useGetSalonItem } from '../../../queries/use-get-salon-item';
import { type IWithId } from '../../../types/interfaces';

import { ServiceTappable } from './service-tappable';

import styles from './salon-item.module.css';

interface IProps extends IWithId {}

export const SalonItem = ({ id }: IProps) => {
    const router = useRouter();
    const [search, setSearch] = useState('');
    const location = useLocation();
    const salonId = useParams(id).id;
    const { data, isLoading } = useGetSalonItem(salonId);
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e?.target?.value);
    }, []);
    const uniqueServices = uniq(data?.services?.map((service) => service.type.title));
    const [selected, setSelected] = useState('');

    useEffect(() => {
        setSelected(uniqueServices?.[0]);
    }, [isLoading]);

    const backFromSalonItem = () => {
        const salonFromTuning = localStorage.getItem('salonFromTuning');
        localStorage.setItem('salonFromTuning', PAGE_SALONS);
        router.pushPage(salonFromTuning ?? PAGE_SALONS);
    };

    const { makeOrder, order, setOrder, orderId } = useMakeOrder(salonId);

    return (
        <SplitLayout modal={<AboutSalonModal page={data} id={location.getModalId() as string} />}>
            <Panel id={id}>
                <PanelHeader
                    separator={false}
                    before={
                        <PanelHeaderButton onClick={() => backFromSalonItem()}>
                            <Icon24ChevronLeft />
                        </PanelHeaderButton>
                    }
                />
                {isLoading ? (
                    <ScreenSpinner />
                ) : (
                    <>
                        <Group style={{ padding: '0 28px' }} separator={'hide'}>
                            <div className={styles.flexContainer}>
                                <Image src={getSanityURL(data?.image).toString()} size={96} />
                                <Title level="2" className={styles.title}>
                                    {data?.name}
                                </Title>
                                <Subhead className={styles.subHead}>{data?.address}</Subhead>
                                <Subhead onClick={() => router.pushModal(ABOUT_SALON_MODAL_PAGE)}>
                                    <div className={styles.linkAboutPlace}>
                                        <span>О месте</span>
                                        <Icon16Chevron />
                                    </div>
                                </Subhead>
                            </div>
                            <Spacing size={4} />
                            <Search value={search} onChange={onChange} style={{ display: 'none' }} />
                        </Group>
                        <Spacing size={16}></Spacing>
                        {data?.specialists?.length && data?.specialists?.length > 0 ? (
                            <Group
                                style={{ marginBottom: -18 }}
                                header={
                                    <Header
                                        mode="primary"
                                        aside={<Link style={{ display: 'none' }}>Показать всех</Link>}
                                    >
                                        <Title level="3">Специалисты</Title>
                                    </Header>
                                }
                                separator="hide"
                            >
                                <HorizontalScroll
                                    showArrows
                                    getScrollToLeft={(i) => i - 120}
                                    getScrollToRight={(i) => i + 120}
                                >
                                    <div style={{ display: 'flex' }}>
                                        {data?.specialists?.map((specialist) => {
                                            return (
                                                <HorizontalCell
                                                    key={specialist._key}
                                                    header={
                                                        <div style={{ wordBreak: 'break-word' }}>{specialist.name}</div>
                                                    }
                                                >
                                                    <Avatar src={getSanityURL(specialist.image).toString()} size={64} />
                                                </HorizontalCell>
                                            );
                                        })}
                                    </div>
                                </HorizontalScroll>
                            </Group>
                        ) : null}
                        <Group>
                            <Tabs mode="default">
                                <HorizontalScroll arrowSize="m">
                                    {uniqueServices.map((item) => {
                                        return (
                                            <TabsItem
                                                selected={selected === item}
                                                onClick={() => setSelected(item)}
                                                key={item}
                                            >
                                                {item}
                                            </TabsItem>
                                        );
                                    })}
                                </HorizontalScroll>
                            </Tabs>
                            <Group style={{ marginBottom: 80 }}>
                                <CardGrid size="l">
                                    {data?.services
                                        ?.filter((service) => service.type.title === selected)
                                        .map((service) => {
                                            return (
                                                <PlatformProvider value="android" key={service._key}>
                                                    <AdaptivityProvider hasPointer={false}>
                                                        <ServiceTappable
                                                            setOrder={setOrder}
                                                            order={order}
                                                            service={service}
                                                        />
                                                    </AdaptivityProvider>
                                                </PlatformProvider>
                                            );
                                        })}
                                </CardGrid>
                            </Group>
                            {data?.contacts && data.contacts.vkId && orderId && (
                                <FixedLayout
                                    style={{ paddingBottom: 12, paddingTop: 12, background: '#fff' }}
                                    vertical="bottom"
                                >
                                    <Div>
                                        {Object.keys(order)?.length > 0 ? (
                                            <ButtonFullWidth
                                                height={44}
                                                target="_blank"
                                                href={`https://vk.com/write-${data.contacts.vkId}?ref=${orderId}`}
                                                as="a"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                after={<Counter>{Object.keys(order)?.length}</Counter>}
                                                onClick={() => makeOrder()}
                                            >
                                                <Title weight="2" level="3">
                                                    Заказать услугу
                                                </Title>
                                            </ButtonFullWidth>
                                        ) : (
                                            <ButtonFullWidth
                                                height={44}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                disabled
                                            >
                                                <Title weight="2" level="3">
                                                    Выберите услуги
                                                </Title>
                                            </ButtonFullWidth>
                                        )}
                                        <Caption
                                            level="1"
                                            weight="3"
                                            style={{ textAlign: 'center', color: 'rgba(0,0,0,0.5)', marginTop: 16 }}
                                        >
                                            Нажмите на кнопку и напишите нам — мы с вами свяжемся!
                                        </Caption>
                                    </Div>
                                </FixedLayout>
                            )}
                        </Group>
                    </>
                )}
            </Panel>
        </SplitLayout>
    );
};
