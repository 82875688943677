import { useCallback } from 'react';
import { gsap } from 'gsap';
import { Vector3 } from 'three';

import { useViewerOptionsContext } from '../../components/three-context/viewer-options-context-provider';

const cameraInitialPosition = new Vector3(-10, 3.75, 16.25);
const TRANSITION_DURATION = 1;

const makeTransition = (whereFrom: Vector3, whereTo: Vector3, duration: number, onUpdate?: () => void) =>
    gsap.to(whereFrom, {
        x: whereTo.x,
        y: whereTo.y,
        z: whereTo.z,
        onUpdate,
        duration,
    });

export const useMakeCameraTransitions = () => {
    const { camera, setViewerOptions, resetViewerOptions } = useViewerOptionsContext();
    const lookAtLeftDoor = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(14, 1.5, -10), TRANSITION_DURATION, () =>
                setViewerOptions([0, 0, 0]),
            );
        }
    }, [camera]);
    const lookAtRightDoor = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(-14, 1.5, -10), TRANSITION_DURATION, () =>
                setViewerOptions([0, 0, 0]),
            );
        }
    }, [camera]);
    const lookAtBackBumper = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(0, 0.75, -10), TRANSITION_DURATION, () =>
                setViewerOptions([0, 0, 0]),
            );
        }
    }, [camera]);
    const lookAtSpoiler = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(-3, 2, -10), TRANSITION_DURATION, () =>
                setViewerOptions([0.5, 0, 0]),
            );
        }
    }, [camera]);
    const lookAtFrontBumper = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(3, 1, 10), TRANSITION_DURATION, () =>
                setViewerOptions([-0.5, 0, 0]),
            );
        }
    }, [camera]);
    const lookAtBonnet = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(0, 7, 14), TRANSITION_DURATION, () =>
                setViewerOptions([0, 0, 0]),
            );
        }
    }, [camera]);
    const lookAtGlassTone = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(5, 1.5, 8), TRANSITION_DURATION, () => setViewerOptions());
        }
    }, [camera]);
    const lookAtHeadlightsTone = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(0, 0.1, 7), TRANSITION_DURATION, () => setViewerOptions());
        }
    }, [camera]);

    const lookAtWheel = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(9, 1, 0), TRANSITION_DURATION, () =>
                setViewerOptions([0, 0, 1.5]),
            );
        }
    }, [camera]);

    const lookAtSideBumper = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(10, 1, 0), TRANSITION_DURATION, () =>
                setViewerOptions([0, 0, 0]),
            );
        }
    }, [camera]);

    const lookAtNumberPlates = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, new Vector3(0, 0.5, 6), TRANSITION_DURATION, () =>
                setViewerOptions([0, 0, 0]),
            );
        }
    }, [camera]);

    const rollback = useCallback(() => {
        if (camera) {
            makeTransition(camera.position, cameraInitialPosition, TRANSITION_DURATION, () => resetViewerOptions());
        }
    }, [camera]);

    return {
        lookAtLeftDoor,
        lookAtRightDoor,
        lookAtBackBumper,
        lookAtSpoiler,
        lookAtBonnet,
        lookAtGlassTone,
        rollback,
        lookAtWheel,
        lookAtFrontBumper,
        lookAtSideBumper,
        lookAtNumberPlates,
        lookAtMuffler: lookAtBackBumper,
        lookAtHeadlightsTone,
    };
};
