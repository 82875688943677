import React, { useState } from 'react';
import { Avatar, Cell, Panel, PanelHeader, ScreenSpinner, Spacing, SplitLayout } from '@vkontakte/vkui';
import { Icon24AddSquareOutline, Icon24ChevronRight } from '@vkontakte/icons';

import { useRouter } from '@happysanta/router';

import sortBy from 'lodash/sortBy';

import { type IWithId } from '../../types/interfaces';
import { LeadersShareModal } from './modals/leaders-share-modal/leaders-share-modal';
import { BATTLE_LEADERS_MODAL, PAGE_TUNING } from '../../router';
import { CustomHeader } from '../../components/custom-header/custom-header';
import { type ILeader, useGetLeaders } from '../../queries/use-get-leaders';

import { useGetUsers } from '../../queries/use-get-users';
import { type IProfile } from '../profile/profile';
import { getProfileFullName } from '../../util/get-profile-full-name';
import { useBridge } from '../../components/bridge-context-provider/bridge-context-provider';

export interface ILeaderWithProfile extends ILeader {
    profile?: IProfile;
}

interface IProps extends IWithId {}

export const Leaders = ({ id }: IProps) => {
    const router = useRouter();
    const { bridgeSend } = useBridge();
    const [currentLeader, setCurrentLeader] = useState<ILeaderWithProfile>();
    const { data: leaders, isLoading: areLeadersLoading } = useGetLeaders();
    const ids =
        leaders?.reduce((acc, currentValue, currentIndex) => {
            const isLast = currentIndex === leaders.length - 1;
            const idsString = `${currentValue.id}${isLast ? '' : ','}`;
            return acc.concat(idsString);
        }, '') || '';
    const { data: profiles, isLoading: areProfilesLoading } = useGetUsers(ids, bridgeSend, Boolean(ids));

    const leadersWithProfile: ILeaderWithProfile[] | undefined = leaders?.map((l) => {
        const profile = profiles?.find((p) => String(p.id) === String(l.id));
        return {
            ...l,
            profile,
        };
    });

    const isLoading = areLeadersLoading && areProfilesLoading;
    return (
        <SplitLayout modal={<LeadersShareModal person={currentLeader} />}>
            <Panel id={id}>
                <PanelHeader
                    style={{ textAlign: 'center' }}
                    before={
                        <Icon24AddSquareOutline
                            onClick={() => {
                                router.pushPage(PAGE_TUNING);
                            }}
                            style={{
                                paddingLeft: 12,
                                cursor: 'pointer',
                            }}
                        />
                    }
                    separator={false}
                >
                    <CustomHeader>Лидеры</CustomHeader>
                </PanelHeader>
                <Spacing size={18} />
                {isLoading ? (
                    <ScreenSpinner></ScreenSpinner>
                ) : (
                    <>
                        {leadersWithProfile &&
                            sortBy<ILeaderWithProfile>(leadersWithProfile, (l) => {
                                return -l.rating;
                            }).map((leader) => {
                                return (
                                    <Cell
                                        onClick={() => {
                                            setCurrentLeader(leader);
                                            router.pushModal(BATTLE_LEADERS_MODAL);
                                        }}
                                        subtitle={`${leader.rating} баллов`}
                                        before={<Avatar size={48} src={leader?.profile?.photo_100 || ''} />}
                                        key={leader.id}
                                        after={<Icon24ChevronRight fill="#B8C1CC" />}
                                    >
                                        {getProfileFullName(leader?.profile)}
                                    </Cell>
                                );
                            })}
                    </>
                )}
                <Spacing size={60} />
            </Panel>
        </SplitLayout>
    );
};
