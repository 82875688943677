import { type AxiosRequestConfig } from 'axios';

import { getConfigurationInfo } from './get-configuration-info';

export const createAxiosHeaders = () => {
    const { sign, values } = getConfigurationInfo();
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: values,
            Sign: sign,
        },
    };
    return config;
};
