import React, { useEffect, useState } from 'react';
import {
    CardGrid,
    Group,
    Panel,
    PanelHeader,
    Card,
    SimpleCell,
    Button,
    ButtonGroup,
    Headline,
    Caption,
    Banner,
    ModalRoot,
    usePlatform,
} from '@vkontakte/vkui';

import { useLocation, useRouter } from '@happysanta/router';

import { BASE_API } from 'constants/base-api';
import { AboutContestModal } from 'components/about-contest-modal/about-contest-modal';

import { getUserId } from 'util/get-user-id';

import { createAxiosHeaders } from 'util/create-axios-headers';

import axios from 'axios';

import { useBannerData } from 'hooks/use-banner-data';

import {
    ABOUT_CONTEST_MODAL,
    PAGE_BATTLE_CHALLENGE,
    PAGE_CONTEST,
    PAGE_PROFILE,
    PAGE_SALONS,
    PAGE_TUNING,
} from '../../router';

import { sectionButtons } from '../../constants/section-buttons';
import { type IWithId } from '../../types/interfaces';
import { CustomHeader } from '../../components/custom-header/custom-header';
import { useBridge } from '../../components/bridge-context-provider/bridge-context-provider';
import { authenticate } from '../../authenticate';

import '../../index.css';
import styles from './home.module.css';

interface IProps extends IWithId {
    isContest?: boolean;
}

interface ISlide {
    id_custom: number;
    image: string;
    title: string;
    text: string;
}

enum EActions {
    confirm = 'confirm',
    reject = 'reject',
    cancel = 'cancel',
}

interface IActionResponse {
    result: boolean;
    action: EActions;
}

const SET_SEEN_SLIDES_URL = `${BASE_API}/voting/userchangeseencontest`;
const SEEN_SLIDES_URL = `${BASE_API}/voting/userinfo`;
const CONTEST_SLIDES_URL = `${BASE_API}/project/onboarding`;

const INITIAL_ALLOWED_LIST_OF_SECTIONS = [PAGE_TUNING, PAGE_SALONS, PAGE_BATTLE_CHALLENGE, PAGE_PROFILE];

export const Home = ({ id, isContest }: IProps) => {
    const { bridgeSend } = useBridge();
    const [allowedSections, _] = useState<string[]>(INITIAL_ALLOWED_LIST_OF_SECTIONS);
    const [sliderAction, setActionSlider] = useState<IActionResponse | null>(null);
    const bannerData = useBannerData();

    const location = useLocation();
    const router = useRouter();
    const platform = usePlatform();

    const handleElaborateModal = React.useCallback(() => {
        router.pushModal(ABOUT_CONTEST_MODAL);
    }, [id]);

    const fetchSlides = async () => {
        const res = await fetch(CONTEST_SLIDES_URL);
        const data: ISlide[] = await res.json();

        if (!data) {
            return;
        }
        const action = bridgeSend('VKWebAppShowSlidesSheet', {
            slides: data.map((slide) => {
                return {
                    media: {
                        blob: slide.image,
                        type: 'image',
                    },
                    title: slide.title,
                    subtitle: slide.text,
                };
            }),
        });

        const userId = getUserId();
        const config = createAxiosHeaders();
        const body = {
            seen_contest_onboarding: true,
        };
        await axios
            .post(`${SET_SEEN_SLIDES_URL}?vk_id=${userId}`, body, config)
            .then((res) => res.data)
            .catch((err) => err.response.data.Error);

        setActionSlider(await action);
        return await action;
    };

    useEffect(() => {
        async function fetchUserSeenSlides() {
            const userId = getUserId();
            const config = createAxiosHeaders();

            const { seen_contest_onboarding_april } = await axios
                .post(`${SEEN_SLIDES_URL}?vk_id=${userId}`, undefined, config)
                .then((res) => res.data)
                .catch((err) => err.response.data.Error);
            return seen_contest_onboarding_april;
        }

        async function crUser() {
            await authenticate();
        }
        async function checkUser() {
            const seenSlides = await fetchUserSeenSlides();
            if (!seenSlides && isContest) {
                fetchSlides();
            }
        }
        crUser();
        checkUser();
    }, []);

    useEffect(() => {
        if (sliderAction === null) {
            return;
        }
        if (sliderAction.action === EActions.confirm) {
            router.pushPage(PAGE_CONTEST);
        }
    }, [sliderAction]);

    return (
        <Panel id={id}>
            <PanelHeader className="panelHeaders" fixed={false} transparent separator={false}>
                <CustomHeader>
                    Авто Кастомайзер{BASE_API === 'https://vkcars-staging.dnzg.dev' ? ' [Staging]' : ''}
                </CustomHeader>
            </PanelHeader>
            <Group mode="plain">
                <CardGrid size="l">
                    {isContest ? (
                        <Banner
                            onClick={() => {
                                // router.pushPage(path);
                                // @ts-expect-error yandex.metrika expected error
                                ym(94201542, 'reachGoal', `call${path}`);
                            }}
                            key={'join_contest'}
                            mode="image"
                            imageTheme={bannerData?.is_light ? 'light' : 'dark'}
                            style={{
                                paddingInline: 0,
                                width: '100%',
                            }}
                            background={
                                <div
                                    style={{
                                        backgroundImage: `url(${BASE_API}${bannerData?.repeat_background})`,
                                        width: '100%',
                                        height: '100%',
                                        backgroundRepeat: 'repeat',
                                        backgroundSize: 'auto 100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundImage: `url(${BASE_API}${
                                                platform === 'vkcom'
                                                    ? bannerData?.image_banner_desktop
                                                    : bannerData?.image_banner_mobile
                                            })`,
                                            backgroundPosition: 'right center',
                                            backgroundSize: 'auto 100%',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    />
                                </div>
                            }
                            header={bannerData?.title_2 ? <Headline weight="2">{bannerData?.title_2}</Headline> : null}
                            subheader={
                                bannerData?.text_2 ? (
                                    <Caption style={{ maxWidth: platform === 'vkcom' ? 350 : 225 }}>
                                        {bannerData?.text_2}
                                    </Caption>
                                ) : null
                            }
                            actions={
                                <ButtonGroup>
                                    <Button
                                        mode="primary"
                                        appearance="overlay"
                                        onClick={() => router.pushPage(PAGE_CONTEST)}
                                    >
                                        Участвовать
                                    </Button>

                                    <Button mode="secondary" appearance="overlay" onClick={handleElaborateModal}>
                                        Подробнее
                                    </Button>
                                </ButtonGroup>
                            }
                        />
                    ) : null}
                    {sectionButtons.map(({ Icon, label, subtitle, path }) =>
                        allowedSections.includes(path) ? (
                            <Card className={styles.card} mode="shadow" key={label}>
                                <SimpleCell
                                    className={styles.cardCell}
                                    before={Icon}
                                    subtitle={subtitle}
                                    onClick={() => {
                                        router.pushPage(path);
                                        // @ts-expect-error yandex.metrika expected error
                                        ym(94201542, 'reachGoal', `call${path}`);
                                    }}
                                >
                                    {label}
                                </SimpleCell>
                            </Card>
                        ) : null,
                    )}
                </CardGrid>
            </Group>

            <ModalRoot activeModal={location.getModalId()}>
                <AboutContestModal id={ABOUT_CONTEST_MODAL} participate={true} />
            </ModalRoot>
        </Panel>
    );
};
