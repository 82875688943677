import React, { useCallback, useEffect, useState } from 'react';

import {
    Avatar,
    Panel,
    PanelHeader,
    Cell,
    Separator,
    Group,
    Header,
    Link,
    Title,
    CardScroll,
    Card,
    Spacing,
    Switch,
    ScreenSpinner,
} from '@vkontakte/vkui';
import {
    Icon24ChevronCompactRight,
    Icon28Favorite,
    Icon28FavoriteOutline,
    Icon28GiftOutline,
    Icon28HelpCircleOutline,
    Icon28InfoCircleOutline,
    Icon28ShareOutline,
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { useRouter } from '@happysanta/router';

import { getUsers } from '../../util/get-users';
import { useAccessToken } from '../../components/access-token-provider/access-token-provider';
import { getUserId } from '../../util/get-user-id';
import { type IVKApiCallResponse, type IWithId } from '../../types/interfaces';
import { useGetAllProjects } from '../../queries/use-get-all-projects';
import { PAGE_ABOUT, PAGE_HELP, PAGE_PROFILE_PROJECTS } from '../../router';
import { usePersonRating } from '../../queries/use-person-rating';
import { ProvideAccessPlaceholder } from '../../components/provide-access-placeholder/provide-access-placeholder';

import { APP_ID, BASE_API } from '../../constants/base-api';
import { useBridge } from '../../components/bridge-context-provider/bridge-context-provider';
import './style.css';

export interface IProfile {
    id: number;
    first_name: string;
    last_name: string;
    photo_100: string;
}

interface IProps extends IWithId {
    isContest?: boolean;
}

export const Profile = ({ id, isContest }: IProps) => {
    const [favourites, setFavourites] = useState<boolean>(localStorage.getItem('isFav') === 'yes');
    const [notify, setNotify] = useState<boolean>(false);
    const [profile, setProfile] = useState<IProfile | null>(null);
    const { data = [], isLoading: areProjectsLoading } = useGetAllProjects(getUserId());
    const { data: rating, isLoading: isRatingLoading } = usePersonRating();
    const vkId = getUserId();
    const ending = rating ? (rating % 10 === 1 ? '' : [2, 3, 4].includes(rating % 10) ? 'а' : 'ов') : '';
    const token = useAccessToken();
    const { bridgeSend } = useBridge();
    const isProfileLoading = profile === null;
    const isLoading = isProfileLoading || areProjectsLoading || isRatingLoading;
    const router = useRouter();
    const isTokenProvided = token && token !== '';

    useEffect(() => {
        if (isTokenProvided) {
            const func = async () => {
                const data = await getUsers<IProfile>(vkId, bridgeSend, token, 'photo_100');
                setProfile(data[0]);
            };
            func();
        }
    }, [isTokenProvided]);

    useEffect(() => {
        if (isTokenProvided) {
            const func = async () => {
                const permissions: IVKApiCallResponse<{ is_allowed: boolean }> = await bridgeSend(
                    'VKWebAppCallAPIMethod',
                    {
                        method: 'apps.isNotificationsAllowed',
                        params: {
                            v: '5.131',
                            access_token: token,
                            user_id: vkId,
                        },
                    },
                );
                const isAllowed = permissions.response.is_allowed;
                setNotify(isAllowed);
            };
            func();
        }
    }, [isTokenProvided]);

    const addToFav = useCallback(() => {
        bridge.send('VKWebAppAddToFavorites').then((data) => {
            if (data.result) {
                setFavourites(data.result);
                localStorage.setItem('isFav', 'yes');
            }
        });
    }, [bridge, localStorage, setFavourites]);

    const shareApp = useCallback(() => {
        bridge
            .send('VKWebAppShare', {
                link: `https://vk.com/app${APP_ID}`,
            })
            .then(() => {});
    }, [bridge]);

    const allowNotifications = useCallback(() => {
        bridge.send('VKWebAppAllowNotifications').then((data) => {
            if (data.result) {
                setNotify(data.result);
            }
        });
    }, [bridge]);

    const denyNotifications = useCallback(() => {
        bridge.send('VKWebAppDenyNotifications').then((d) => {
            if (d.result) {
                setNotify(false);
            }
        });
    }, [bridge, setNotify]);

    return (
        <Panel id={id}>
            <PanelHeader style={{ textAlign: 'center' }}>
                <Title level="2">Профиль</Title>
            </PanelHeader>
            {isTokenProvided ? (
                <div>
                    {isLoading ? (
                        <ScreenSpinner></ScreenSpinner>
                    ) : (
                        <>
                            <Spacing size={12} />
                            <Cell
                                subtitle={`${rating} балл${ending}`}
                                disabled
                                before={<Avatar src={profile?.photo_100} size={72}></Avatar>}
                            >
                                {`${profile?.first_name} ${profile?.last_name}`}
                            </Cell>
                            <Spacing size={12} />
                            <Separator />
                            <Group
                                separator={'hide'}
                                className="groupContainer"
                                header={
                                    <Header
                                        style={{
                                            margin: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            height: '44px',
                                        }}
                                        mode="primary"
                                        aside={
                                            <Link onClick={() => router.pushPage(PAGE_PROFILE_PROJECTS)}>
                                                Посмотреть все
                                            </Link>
                                        }
                                    >
                                        <Title level="3">Мои проекты</Title>
                                    </Header>
                                }
                            >
                                <CardScroll size="s">
                                    {data
                                        ?.sort(function (a, b) {
                                            return b.id - a.id;
                                        })
                                        .map((item) => {
                                            return (
                                                <React.Fragment key={item.id}>
                                                    <Card
                                                        style={{
                                                            backgroundImage: `url(${BASE_API}/${item.image})`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                        }}
                                                        onClick={() =>
                                                            router.pushPage(PAGE_PROFILE_PROJECTS, {
                                                                id: item.id.toString(),
                                                            })
                                                        }
                                                    >
                                                        <div style={{ paddingBottom: '66%' }} />
                                                    </Card>
                                                </React.Fragment>
                                            );
                                        })}
                                </CardScroll>
                                <Spacing size={15} />
                                <Cell
                                    Component="label"
                                    after={
                                        <Switch
                                            onClick={notify ? denyNotifications : allowNotifications}
                                            checked={notify}
                                        />
                                    }
                                >
                                    <Title weight="3" level="3">
                                        Уведомления
                                    </Title>
                                </Cell>
                                <Spacing size={8} />
                            </Group>
                            <Separator />
                            <Group>
                                <Cell
                                    onClick={() => {
                                        addToFav();
                                    }}
                                    before={favourites ? <Icon28Favorite /> : <Icon28FavoriteOutline />}
                                >
                                    <Title weight="3" level="3">
                                        {favourites ? 'Добавлено в избранное' : 'Добавить в избранное'}
                                    </Title>
                                </Cell>
                                <Cell
                                    onClick={() => {
                                        shareApp();
                                    }}
                                    before={<Icon28ShareOutline />}
                                >
                                    <Title weight="3" level="3">
                                        Поделиться приложением
                                    </Title>
                                </Cell>
                                <Cell
                                    onClick={() => {
                                        router.pushPage(PAGE_HELP);
                                    }}
                                    before={<Icon28HelpCircleOutline />}
                                    after={<Icon24ChevronCompactRight fill="#B8C1CC" />}
                                >
                                    <Title weight="3" level="3">
                                        Помощь
                                    </Title>
                                </Cell>
                                <Cell
                                    onClick={() => {
                                        router.pushPage(PAGE_ABOUT);
                                    }}
                                    before={<Icon28InfoCircleOutline />}
                                    after={<Icon24ChevronCompactRight fill="#B8C1CC" />}
                                >
                                    <Title weight="3" level="3">
                                        О приложении
                                    </Title>
                                </Cell>
                                {isContest ? (
                                    <Cell
                                        before={<Icon28GiftOutline />}
                                        after={<Icon24ChevronCompactRight fill="#B8C1CC" />}
                                    >
                                        <a
                                            target="_blank"
                                            href="https://files.synapse.global/autocustomizer_rules"
                                            style={{ color: 'inherit', textDecoration: 'none' }}
                                        >
                                            <Title weight="3" level="3">
                                                Правила проведения конкурса
                                            </Title>
                                        </a>
                                    </Cell>
                                ) : (
                                    <></>
                                )}
                            </Group>
                        </>
                    )}
                </div>
            ) : (
                <ProvideAccessPlaceholder />
            )}
        </Panel>
    );
};
