import bridge from '@vkontakte/vk-bridge';
import { APP_ID } from 'constants/base-api';
import { useEffect, useState } from 'react';

export const useInitialAccessToken = () => {
    const [accessToken, setAccessToken] = useState<string>('');

    useEffect(() => {
        async function setAccessTokenValue() {
            bridge
                .send('VKWebAppGetAuthToken', {
                    app_id: APP_ID,
                    scope: '',
                })
                .then((data) => {
                    if (data.access_token) {
                        setAccessToken(data.access_token);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        async function getTokenProvision() {
            bridge
                .send('VKWebAppStorageGet', {
                    keys: ['token'],
                })
                .then((storageKeys) => {
                    const isProvided = storageKeys.keys[0].value;
                    if (isProvided !== '') {
                        setAccessTokenValue();
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        getTokenProvision();
    }, []);

    return { accessToken, setAccessToken };
};
