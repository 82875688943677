import React from 'react';
import { ModalRoot } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';

import { type ITuningModalProps } from '../tuning-modal-props';
import { type IDefaults } from '../../../../types/tuning-model';
import { ENABLE_NOTIFICATIONS_MODAL, MODAL_CARD_SAVE, PAGE_TUNING } from '../../../../router';
import { ModalCardSave } from '../modal-card-save/modal-card-save';

export const SaveProjectModal = ({
    currentValue,
    setCurrentValue,
    page = PAGE_TUNING,
    contest,
}: ITuningModalProps<IDefaults>) => {
    const location = useLocation();
    const router = useRouter();

    const handleModalClose = React.useCallback(() => {
        if (page) router.pushPage(page);
        const notificationModal = localStorage.getItem('EnableNotificationsModal');
        if (notificationModal) return;
        router.pushModal(ENABLE_NOTIFICATIONS_MODAL);
    }, []);

    return (
        <ModalRoot onClose={handleModalClose} activeModal={location.getModalId()}>
            <ModalCardSave
                setCurrentVehicle={setCurrentValue}
                currentVehicle={currentValue}
                id={MODAL_CARD_SAVE}
                contest={contest}
            />
        </ModalRoot>
    );
};
