import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';
import { PROJECTS_QUERY_KEY } from '../constants/query-keys';

export interface IProject {
    id: number;
    file?: string;
    image?: string;
    name: string;
    datetime?: string;
}

export const useGetAllProjects = (userId: string) => {
    return useQuery([PROJECTS_QUERY_KEY, userId], async () => {
        const response = await axios.get<IProject[]>(`${BASE_API}/project/userprojects?vk_id=${userId}`);
        return response.data;
    });
};
