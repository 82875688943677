import { type IEvent3dObjectModelConfig } from '../../../types/event-3d-object-model-config';
import { WheelEventType } from './event-types';
import { rimBrakeColorChangeEventHandler } from './rim-brake-color-change-event-handler';
import { rimExternalMaterialChangeEventHandler } from './rim-external-material-change-event';

const mapWheelsConfiguration: IEvent3dObjectModelConfig<WheelEventType> = new Map();
mapWheelsConfiguration.set(WheelEventType.RIM_BRAKE_COLOR_CHANGE, rimBrakeColorChangeEventHandler);
mapWheelsConfiguration.set(WheelEventType.RIM_MATERIAL, rimExternalMaterialChangeEventHandler);

export default mapWheelsConfiguration;
