export type TPrePreparedColor =
    | 'RED'
    | 'BLUE'
    | 'GREEN'
    | 'WHITE'
    | 'BLACK'
    | 'LIGHT_BLUE'
    | 'BEIGE'
    | 'BROWN'
    | 'SILVER';

interface IColorInfo {
    hex: string;
    label: string;
}

export const PRE_PREPARED_COLORS: Record<TPrePreparedColor, IColorInfo> = {
    RED: {
        hex: '#dc3a2d',
        label: 'Красный',
    },
    BLUE: {
        hex: '#3959fe',
        label: 'Синий',
    },
    GREEN: {
        hex: '#37cb51',
        label: 'Зелёный',
    },
    WHITE: {
        hex: '#ffffff',
        label: 'Белый',
    },
    BLACK: {
        hex: '#333333',
        label: 'Черный',
    },
    LIGHT_BLUE: {
        hex: '#3de0f2',
        label: 'Голубой',
    },
    BEIGE: {
        hex: '#ffdc9a',
        label: 'Бежевый',
    },
    BROWN: {
        hex: '#ab5f0c',
        label: 'Коричневый',
    },
    SILVER: {
        hex: '#999999',
        label: 'Серебряный',
    },
};
