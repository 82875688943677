import { Mesh, type MeshStandardMaterial, type Object3D } from 'three';

// Set vehicle materials.
const setMaterials = (material: MeshStandardMaterial, color: string) => {
    // Switch material name.
    switch (material.name) {
        case 'body':
            material.color.setStyle(color);
            material.metalness = 0.4;
            break;
        case 'tire':
            material.roughness = 0.9;
            material.color.set(color);
            break;
        case 'rim':
            material.roughness = 0.1;
            material.color.set(color);
            break;
    }
};

export default function getSetMaterialProperties() {
    // Set object materials.
    const setObjectMaterials = (object: Object3D, color: string) => {
        if (!object) return;

        // Traverse object.
        object.traverseVisible((child) => {
            if (child instanceof Mesh) {
                // Cast shadows from mesh.
                child.castShadow = true;

                // Ensure that the material is always an array.
                const materials = Array.isArray(child.material) ? child.material : [child.material];

                // Set material properties for each material.
                materials.forEach((material) => {
                    setMaterials(material, color);
                });
            }
        });
    };
    return { setObjectMaterials };
}
