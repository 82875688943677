import React, { createContext, useContext, useState } from 'react';

interface INotificationSnackbarContextValue {
    notificationSnackbar: React.ReactNode;
    setNotificationSnackbar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export const NotificationSnackbarContext = createContext<INotificationSnackbarContextValue | null>(null);

export const NotificationSnackbarProvider = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const [notificationSnackbar, setNotificationSnackbar] = useState<React.ReactNode>(null);

    return (
        <NotificationSnackbarContext.Provider
            value={{
                notificationSnackbar,
                setNotificationSnackbar,
            }}
        >
            {children}
        </NotificationSnackbarContext.Provider>
    );
};

export const useNotificationSnackbarLayer = () => {
    const value = useContext(NotificationSnackbarContext);
    if (value === null) {
        throw new Error('useNotificationSnackbarLayer must be used within a NotificationSnackbarProvider.');
    }
    const { notificationSnackbar, setNotificationSnackbar } = value;
    return {
        notificationSnackbar,
        setNotificationSnackbar,
    };
};
