import { useMutation } from 'react-query';

import axios from 'axios';

import { useBridge } from '../components/bridge-context-provider/bridge-context-provider';
import { useAccessToken } from '../components/access-token-provider/access-token-provider';
import { type IVKApiCallResponse } from '../types/interfaces';

import { getUserId } from '../util/get-user-id';
import { APP_ID, BASE_API } from '../constants/base-api';

interface IGetUploadServerData {
    album_id: number;
    upload_url: string;
    user_id: number;
}

interface IPublishResponse {
    photo: string;
    server: number;
    hash: string;
}

interface ISaveWallPhotoItem {
    id: string;
}

type ISaveWallPhotoData = ISaveWallPhotoItem[];

export const useShareApp = () => {
    const { bridgeSend } = useBridge();
    const accessToken = useAccessToken();
    const userId = getUserId();
    return useMutation(async (projectId: number) => {
        const uploadForServer: IVKApiCallResponse<IGetUploadServerData> = await bridgeSend('VKWebAppCallAPIMethod', {
            method: 'photos.getWallUploadServer',
            params: {
                access_token: accessToken,
                v: '5.131',
            },
        });

        const { upload_url } = uploadForServer.response;

        const publishData = {
            url: upload_url,
            id: projectId,
        };
        const { data } = await axios.post<IPublishResponse>(`${BASE_API}/project/publish`, publishData);

        const stringPhoto = data.photo;
        const jsonPhoto: Record<string, any> = JSON.parse(stringPhoto);
        const photoValidJsonString = JSON.stringify(jsonPhoto);

        const { response: saveWallResponse }: IVKApiCallResponse<ISaveWallPhotoData> = await bridgeSend(
            'VKWebAppCallAPIMethod',
            {
                method: 'photos.saveWallPhoto',
                params: {
                    access_token: accessToken,
                    photo: photoValidJsonString,
                    server: data.server,
                    hash: data.hash,
                    v: '5.131',
                },
            },
        );

        const { id: photo_id } = saveWallResponse[0];

        return await bridgeSend('VKWebAppShowWallPostBox', {
            attachments: `photo${userId}_${photo_id}`,
            message: `Присоединяйтесь ко мне в приложении Авто Кастомайзер! 
            
            vk.com/app${APP_ID}`,
            owner_id: +userId,
        });
    });
};
