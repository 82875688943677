import { Panel, PanelHeader, Title } from '@vkontakte/vkui';
import { Icon24ChevronLeft } from '@vkontakte/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { type IWithId } from '../../types/interfaces';
import { router, PAGE_PROFILE, PANEL_ABOUT, PANEL_HELP } from '../../router';

import { BASE_API } from '../../constants/base-api';

const CONTENT_DATA: Record<string, [string, string]> = {
    [PANEL_HELP]: ['Помощь', 'help'],
    [PANEL_ABOUT]: ['О приложении', 'about'],
};

export const Content = ({ id }: IWithId) => {
    const [html, setHTML] = useState('');
    useEffect(() => {
        async function getData() {
            if (!CONTENT_DATA[id][1]) {
                return false;
            }

            const {
                data: { html },
            } = await axios.get(`${BASE_API}/project/${CONTENT_DATA[id][1]}`);
            setHTML(html);
        }

        getData();
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader
                separator={false}
                before={<Icon24ChevronLeft onClick={() => router.pushPage(PAGE_PROFILE)} style={{ paddingLeft: 12 }} />}
                style={{ textAlign: 'center' }}
            >
                <Title level="2">{CONTENT_DATA[id][0]}</Title>
            </PanelHeader>

            <div style={{ padding: 16 }} dangerouslySetInnerHTML={{ __html: html }} />
        </Panel>
    );
};
