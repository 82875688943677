import React, { useEffect, useState } from 'react';
import { FixedLayout, Tabbar, TabbarItem, Tooltip } from '@vkontakte/vkui';
import {
    Icon24ColorPickerOutline,
    Icon28CarOutline,
    Icon28PictureOutline,
    Icon28UploadOutline,
    Icon24Fullscreen,
    Icon28StickerOutline,
    Icon28BillheadOutline,
    Icon28WrenchOutline,
    Icon24HideOutline,
    Icon28StarsOutline,
    Icon24StarsOutline,
    Icon28PincodeOutline,
} from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';

import { useBannerData } from 'hooks/use-banner-data';

import { IconSupport } from '../../components/icon-support/icon-support';
import { IconClips } from '../../components/icon-clips/icon-clips';
import { IconDisk } from '../../components/icon-disk/icon-disk';
import ButtonGoBack from '../button-go-back';
import {
    MODAL_TUNING_COLOR,
    MODAL_TUNING_ENV,
    MODAL_TUNING_LIFT,
    MODAL_TUNING_PICK_CAR,
    MODAL_TUNING_PICK_RIM_MODEL,
    MODAL_TUNING_PICK_RIM_COLOR,
    MODAL_TUNING_PICK_RIM_SIZE,
    MODAL_TUNING_PICK_TIRE_MODEL,
    MODAL_TUNING_PICK_TIRE_SIZE,
    MODAL_CARD_SAVE,
    MODAL_EDIT_BODY_KIT,
    MODAL_TUNING_PICK_STICKER,
    MODAL_TUNING_GLASS_TONE,
    MODAL_TUNING_MATERIAL,
    MODAL_TUNING_RIM_MATERIAL,
    MODAL_TUNING_PICK_RIM_ACCENT,
    MODAL_TUNING_PLATE_NUMBERS,
} from '../../router';
import { useMakeCameraTransitions } from '../../engine/hooks/use-make-camera-transitions';

import './tuning-tabbar.css';
import { type IDefaults } from '../../types/tuning-model';

interface ISubBarProps {
    goBack: VoidFunction;
    hideModelAuto?: boolean;
    setCurrentValue?: (value: Partial<IDefaults>) => void;
}

const TabbarUnderConstruction = ({ title }: { title: string }) => {
    const router = useRouter();

    return (
        <TabbarItem
            data-story="clips"
            text={title}
            onClick={() => {
                router.pushModal(MODAL_EDIT_BODY_KIT);
            }}
        >
            <Icon28WrenchOutline />
        </TabbarItem>
    );
};

const RimsSubbar = ({ goBack }: ISubBarProps) => {
    const router = useRouter();

    return (
        <Tabbar>
            <ButtonGoBack goBack={goBack} />
            <TabbarItem
                onClick={() => router.pushModal(MODAL_TUNING_PICK_RIM_MODEL)}
                data-story="services"
                text="Модель"
            >
                <Icon28BillheadOutline />
            </TabbarItem>
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_PICK_RIM_SIZE)} data-story="clips" text="Размеры">
                <Icon24Fullscreen height={28} width={28} />
            </TabbarItem>
            <TabbarItem
                onClick={() => router.pushModal(MODAL_TUNING_PICK_RIM_ACCENT)}
                data-story="clips"
                text="Суппорты"
            >
                <IconSupport height={28} width={28} />
            </TabbarItem>

            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_PICK_RIM_COLOR)} data-story="clips" text="Цвет">
                <Icon24ColorPickerOutline height={28} width={28} />
            </TabbarItem>
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_RIM_MATERIAL)} data-story="clips" text="Материал">
                <Icon24StarsOutline height={28} width={28} />
            </TabbarItem>
        </Tabbar>
    );
};

const TiresSubBar = ({ goBack }: ISubBarProps) => {
    const router = useRouter();
    return (
        <Tabbar>
            <ButtonGoBack goBack={goBack} />
            <TabbarItem
                onClick={() => {
                    router.pushModal(MODAL_TUNING_PICK_TIRE_MODEL);
                }}
                data-story="services"
                text="Модель"
            >
                <Icon28BillheadOutline />
            </TabbarItem>
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_PICK_TIRE_SIZE)} data-story="clips" text="Размеры">
                <Icon24Fullscreen height={28} width={28} />
            </TabbarItem>
        </Tabbar>
    );
};

const CarSubBar = ({ goBack, hideModelAuto, setCurrentValue }: ISubBarProps) => {
    const router = useRouter();
    const { lookAtNumberPlates } = useMakeCameraTransitions();
    return (
        <Tabbar className="tabbar">
            <ButtonGoBack goBack={goBack} />
            {!hideModelAuto ? (
                <TabbarItem
                    onClick={() => {
                        router.pushModal(MODAL_TUNING_PICK_CAR);
                    }}
                    data-story="services"
                    text="Модель"
                >
                    <Icon28BillheadOutline />
                </TabbarItem>
            ) : null}
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_LIFT)} data-story="messages" text="Подвеска">
                <Icon24Fullscreen height={28} width={28} />
            </TabbarItem>
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_PICK_STICKER)} data-story="clips" text="Рисунок">
                <Icon28StickerOutline />
            </TabbarItem>
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_COLOR)} data-story="clips" text="Цвет">
                <Icon24ColorPickerOutline height={28} width={28} />
            </TabbarItem>
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_GLASS_TONE)} data-story="clips" text="Тонировка">
                <Icon24HideOutline height={28} width={28} />
            </TabbarItem>
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_MATERIAL)} text="Материал">
                <Icon28StarsOutline />
            </TabbarItem>
            <TabbarItem
                onClick={() => {
                    setCurrentValue?.({ number_plates: { type: 'RU', number: [] } });
                    lookAtNumberPlates();
                    router.pushModal(MODAL_TUNING_PLATE_NUMBERS);
                }}
                text="Номера"
            >
                <Icon28PincodeOutline />
            </TabbarItem>
        </Tabbar>
    );
};

export const TuningTabbar = ({
    canvasLoaded,
    hideModelAuto,
    contest,
    setCurrentValue,
}: {
    canvasLoaded: boolean;
    hideModelAuto?: boolean;
    contest?: boolean;
    setCurrentValue?: (value: Partial<IDefaults>) => void;
}) => {
    const router = useRouter();
    const [subBar, setSubBar] = useState<React.ReactNode | null>(null);
    const [tooltip, setTooltip] = useState(false);
    const { lookAtWheel, rollback } = useMakeCameraTransitions();
    const bannerData = useBannerData();

    useEffect(() => {
        const isTooltipShown = localStorage.getItem('isTooltipShown') === '1';
        if (!isTooltipShown) {
            setTooltip(true);
        }
        setTimeout(() => {
            setTooltip(true);
        }, 3000);
        setTimeout(() => {
            setTooltip(false);
        }, 8000);
    }, []);

    if (subBar) {
        return <FixedLayout>{subBar}</FixedLayout>;
    }

    return (
        <Tabbar mode="vertical">
            <TabbarItem
                onClick={() =>
                    setSubBar(
                        <CarSubBar
                            setCurrentValue={setCurrentValue}
                            goBack={() => setSubBar(null)}
                            hideModelAuto={hideModelAuto}
                        />,
                    )
                }
                data-story="feed"
                text="Машина"
            >
                <Icon28CarOutline />
            </TabbarItem>
            <TabbarItem
                onClick={() => {
                    lookAtWheel();
                    setSubBar(
                        <RimsSubbar
                            goBack={() => {
                                rollback();
                                setSubBar(null);
                            }}
                        />,
                    );
                }}
                data-story="messages"
                text="Диски"
            >
                <IconDisk height={26} width={28} />
            </TabbarItem>
            <TabbarItem
                onClick={() => {
                    lookAtWheel();
                    setSubBar(
                        <TiresSubBar
                            goBack={() => {
                                rollback();
                                setSubBar(null);
                            }}
                        />,
                    );
                }}
                data-story="clips"
                text="Шины"
            >
                <IconClips height={28} width={28} />
            </TabbarItem>

            <TabbarUnderConstruction title="Кузов" />
            <TabbarItem onClick={() => router.pushModal(MODAL_TUNING_ENV)} data-story="clips" text="Фон">
                <Icon28PictureOutline />
            </TabbarItem>
            <TabbarItem
                onClick={async () => {
                    if (canvasLoaded) {
                        window.dispatchEvent(new Event('takeScreenshot'));
                        setTimeout(() => {
                            router.pushModal(MODAL_CARD_SAVE);
                        }, 500);
                    }
                }}
                data-story="feed"
                text={contest ? 'Участвовать' : 'Сохранить'}
            >
                <Tooltip
                    text={contest ? bannerData?.tooltip : 'Не забудь сохранить свой сетап!'}
                    isShown={tooltip}
                    onClose={() => {
                        setTooltip(false);
                        localStorage.setItem('isTooltipShown', '1');
                    }}
                    offsetX={10}
                    offsetY={20}
                >
                    <Icon28UploadOutline />
                </Tooltip>
            </TabbarItem>
        </Tabbar>
    );
};
