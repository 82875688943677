import { type IVehicleConfig } from '../types/tuning-model';

export const vehicleConfigs: IVehicleConfig = {
    defaults: {
        id: 'BMW_5_Series_G30_M',
        interiorColor: '#6b6b6b',
        interiorMaterial: 'none',
        lift: -6,
        color: '#B91818',
        glass_tone: undefined,
        headLightsTone: undefined,
        roughness: 'glossy',
        isPearl: { all: false },
        wheel_offset: 0,
        rim: 'BMW_5-Series_G30_M_Disk',
        rim_color: '#858585',
        rim_color_secondary: '#858585',
        rim_diameter: 29,
        rim_width: 18,
        tire: 'BMW_5-Series_G30_M_Tire',
        tire_diameter: 37,
        picture: '',
        tire_color: '#121212',
        stickers: [],
        addons: [],
        mark: 'BMW',
        rim_material: 'glossy',
        number_plates: { number: [] },
    },
    vehicles: {
        default: {
            wheel_offset: 0.75,
            wheelbase: 2.9,
            wheelStartingPoint: -0.05,
            maxTireSize: 46,
        },
    },
    wheels: {
        rims: {
            default: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            A8_2022_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Audi_A6_allroad_disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            bentley_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Bentley_Bentayga_Hybrid_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            'BMW_5-Series_G30_M_Disk': {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            bmw3e46_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Cadillac_Escalade_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Camry_2018_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Ferrari_SF90_Stradale_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Honda_Civic_sedan_2022_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Hyundai_Sonata_2021_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Kia_K5_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            KIA_Rio_hatchback_2023_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            LADA_Priora_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Lad_vesta_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Lamborghini_urus_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Land_Rover_Range_Rover_Sport_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Lexus_ES_2022_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Lexus_RX_VRAY_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            'Mercedes-Benz_AMG_G63_BRABUS_700_Disk': {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            'Mercedes-Benz_C-Class_2022_Disk': {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Mitsubishi_Eclipse_Cross_2022_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            mitsubishi_lancer_evo_x_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            'Nissan_GT-R_Disk': {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Nissan_Qashqai_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            nissan_silvia_s_15_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Nissan_Skyline_2015_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Porsche_Cayenne_2022_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Porsche_Panamera_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Porsche_911_GT3_2022_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Range_Rover_Evoque_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Toyota_Land_Cruiser_300_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            'Toyota_Mark_II_VI_(X80)_Disk': {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            UAZ_Hunter_2017_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Volkswagen_Beetle_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Volkswagen_golf_8_R_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            'Volkswagen_Passat CC_Disk': {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            Volkswagen_Touareg_R_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            'vw_polo-5d_Disk': {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            w223_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            x5_Disk: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
            toyota_4runner: {
                make: 'defaults',
                name: 'defaults',
                width: 0.5,
                od: 1,
            },
        },
        tires: {
            default: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.9,
                id: 0.545,
            },
            A8_2022_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.57,
            },
            Audi_A6_allroad_tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.5,
            },
            bentley_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.5,
            },
            Bentley_Bentayga_Hybrid_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.53,
            },
            'BMW_5-Series_G30_M_Tire': {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.545,
            },
            bmw3е46_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.48,
            },
            Cadillac_Escalade_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.5,
            },
            Camry_2018_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.78,
                id: 0.55,
            },
            Ferrari_SF90_Stradale_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.72,
                id: 0.445,
            },
            Honda_Civic_sedan_2022_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.08,
                od: 0.7,
                id: 0.45,
            },
            Hyundai_Sonata_2021_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.7,
                id: 0.45,
            },
            Kia_K5_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.5,
            },
            KIA_Rio_hatchback_2023_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.72,
                id: 0.45,
            },
            LADA_Priora_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.72,
                id: 0.5,
            },
            Lada_vesta_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.72,
                id: 0.5,
            },
            Lamborghini_urus_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.54,
            },
            Land_Rover_Range_Rover_Sport_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.9,
                id: 0.6,
            },
            Lexus_ES_2022_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.76,
                id: 0.55,
            },
            Lexus_RX_VRAY_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.67,
                id: 0.48,
            },
            lexus_gs300: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.8,
                id: 0.5,
            },
            'Mercedes-Benz_AMG_G63_BRABUS_700_Tire': {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.92,
                id: 0.65,
            },
            'Mercedes-Benz_C-Class_2022_Tire': {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.8,
                id: 0.47,
            },
            Mitsubishi_Eclipse_Cross_2022_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.75,
                id: 0.46,
            },
            mitsubishi_lancer_evo_x_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.75,
                id: 0.46,
            },
            'Nissan_GT-R_Tire': {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.55,
            },
            Nissan_Qashqai_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.9,
                id: 0.6,
            },
            Nissan_silvia_s_15_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.77,
                id: 0.487,
            },
            Nissan_Skyline_2015_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.77,
                id: 0.487,
            },
            Porsche_Cayenne_2022_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.9,
                id: 0.55,
            },
            Porsche_Panamera_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.82,
                id: 0.55,
            },
            Porsche_911_GT3_2022_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.92,
                id: 0.6,
            },
            Range_Rover_Evoque_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.9,
                id: 0.55,
            },
            Toyota_Land_Cruiser_300_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.92,
                id: 0.6,
            },
            'Toyota_Mark_II_VI_(X80)_Tire': {
                make: 'defaults',
                name: 'defaults',
                width: 0.1,
                od: 0.72,
                id: 0.445,
            },
            UAZ_Hunter_2017_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.92,
                id: 0.445,
            },
            Volkswagen_Beetle_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.83,
                id: 0.57,
            },
            Volkswagen_golf_8_R_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.49,
            },
            Volkswagen_Passat_CC_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.74,
                id: 0.47,
            },
            Volkswagen_Touareg_R_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.12,
                od: 0.8,
                id: 0.57,
            },
            'vw_polo-5d_Tire': {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.74,
                id: 0.47,
            },
            w223_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.8,
                id: 0.47,
            },
            x5_Tire: {
                make: 'defaults',
                name: 'defaults',
                width: 0.11,
                od: 0.8,
                id: 0.53,
            },
            thornbird: {
                make: 'defaults',
                name: 'defaults',
                width: 0.267,
                od: 0.92,
                id: 0.445,
            },
        },
    },
};

export const contestConfig: Record<string, IVehicleConfig['defaults']> = {
    Skoda_oct_iii: {
        wheel_offset: 0,
        addons: [],
        id: 'Skoda_oct_iii',
        interiorColor: '#706f6f',
        lift: -6,
        color: '#fff',
        roughness: 'glossy',
        rim: 'Skoda_Octavia_III_disk',
        rim_color: '#858585',
        rim_color_secondary: '#858585',
        rim_diameter: 29,
        rim_width: 18,
        tire: 'Skoda_Octavia_III_tire',
        tire_diameter: 34,
        picture: '',
        tire_color: '#121212',
        stickers: [],
        interiorMaterial: 'none',
        isPearl: { all: false },
        rim_material: 'glossy',
        number_plates: { number: [] },
    },
};
