import React, { useState } from 'react';

import { Spacing, Tabs, TabsItem, useModalRootContext } from '@vkontakte/vkui';

import { RepeatWrapping, TextureLoader } from 'three';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { type IDefaults } from '../../../../types/tuning-model';
import { TuningHeader } from '../tuning-header';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { ChangeExternalMaterialEvent } from '../../../../engine/events/common-events/change-external-material';
import { BASE_API } from '../../../../constants/base-api';
import { ChangeInteriorMaterial } from '../../../../engine/events/body-events-handlers/change-interior-material';
import { BodyEventTypes } from '../../../../engine/events/body-events-handlers/event-types';
import { type IMediaSelectItem } from '../../../../types/select-item';
import { ChooseSingleCard } from '../../../../components/choose-single-card/choose-single-card';
import { ROUGHNESS_VARIANTS } from '../../../../constants/roughness-variants';
import { vehicleConfigs } from '../../../../engine/vehicle-configs';

type TCurrentValue = Pick<IDefaults, 'roughness' | 'interiorMaterial'>;

const interiorMaterials: Array<IMediaSelectItem<TCurrentValue['interiorMaterial']> & { bgUrl?: string }> = [
    {
        value: 'none',
        label: 'Базовый',
        mediaUrl: ``,
    },
    {
        value: 'leather1',
        label: 'Кожа 1',
        mediaUrl: `${BASE_API}/static/assets/images/textures/skin_texture_1.png`,
        bgUrl: `${BASE_API}/static/assets/images/textures/pre_skin_texture_1.png`,
    },
    {
        value: 'leather2',
        label: 'Кожа 2',
        mediaUrl: `${BASE_API}/static/assets/images/textures/skin_texture_2.png`,
        bgUrl: `${BASE_API}/static/assets/images/textures/pre_skin_texture_2.png`,
    },
    {
        value: 'plastic',
        label: 'Ткань',
        mediaUrl: `${BASE_API}/static/assets/images/textures/fabric_texture.png`,
        bgUrl: `${BASE_API}/static/assets/images/textures/pre_fabric_texture.png`,
    },
];
export const EditMaterialModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const vehicle = useDetailsContext().vehicle;
    const [tab, setTab] = useState<'exterior' | 'interior'>('exterior');
    const { updateModalHeight } = useModalRootContext();
    updateModalHeight();

    const handleMaterialReset = React.useCallback(() => {
        if (tab === 'exterior') {
            setCurrentValue({ roughness: vehicleConfigs.defaults.roughness });
            vehicle.current?.dispatchEvent(
                new ChangeExternalMaterialEvent<BodyEventTypes>(
                    vehicle.current,
                    vehicleConfigs.defaults.roughness,
                    BodyEventTypes.CHANGE_BODY_MATERIAL,
                ),
            );
            return;
        }

        setCurrentValue({ interiorMaterial: vehicleConfigs.defaults.interiorMaterial });
        vehicle.current?.dispatchEvent(new ChangeInteriorMaterial(vehicle.current, undefined));
    }, [currentValue]);

    return (
        <div>
            <TuningHeader children="Выберите материал" onReset={handleMaterialReset} />
            <Tabs>
                <TabsItem onClick={() => setTab('exterior')} selected={tab === 'exterior'}>
                    Кузов
                </TabsItem>
                <TabsItem onClick={() => setTab('interior')} selected={tab === 'interior'}>
                    Салон
                </TabsItem>
            </Tabs>
            <Spacing size={10} />
            {tab === 'exterior' && (
                <ChooseSingleCard
                    onClick={(v) => {
                        setCurrentValue({ roughness: v.value });
                        vehicle.current?.dispatchEvent(
                            new ChangeExternalMaterialEvent<BodyEventTypes>(
                                vehicle.current,
                                v.value,
                                BodyEventTypes.CHANGE_BODY_MATERIAL,
                            ),
                        );
                    }}
                    currentValue={currentValue.roughness}
                    variants={ROUGHNESS_VARIANTS}
                />
            )}
            {tab === 'interior' && (
                <ChooseSingleCard
                    onClick={async (v) => {
                        if (v.value !== 'none') {
                            const textureLoader = new TextureLoader();
                            const texture = await textureLoader.loadAsync(v.mediaUrl);
                            texture.wrapS = texture.wrapT = RepeatWrapping;
                            texture.repeat.set(2, 2);
                            vehicle.current?.dispatchEvent(new ChangeInteriorMaterial(vehicle.current, texture));
                        } else {
                            vehicle.current?.dispatchEvent(new ChangeInteriorMaterial(vehicle.current, undefined));
                        }
                        setCurrentValue({ interiorMaterial: v.value });
                    }}
                    currentValue={currentValue.interiorMaterial}
                    variants={interiorMaterials}
                />
            )}
        </div>
    );
};
