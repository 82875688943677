import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';
import { type IAddon } from '../types/tuning-model';

export const useGetAddons = () => {
    return useQuery(['addons'], async () => {
        const { data } = await axios.get<IAddon[]>(`${BASE_API}/project/obves`);
        return data;
    });
};
