import React from 'react';
import { Icon16Chevron, Icon20PlaceOutline } from '@vkontakte/icons';

import { type LocationScheduleInterface } from '../../../../../queries/use-get-salon-item';

import styles from './salon-address-item.module.css';

interface SalonAddressItemInteface {
    addressItem: LocationScheduleInterface;
    withBorderBottom: boolean;
}

function SalonAddressItem({ addressItem, withBorderBottom }: SalonAddressItemInteface) {
    const [showSchedule, setShowSchedule] = React.useState(false);

    const handleScheduleToggle = React.useCallback(() => setShowSchedule(!showSchedule), [showSchedule]);

    return (
        <div className={styles.addressContainer}>
            <Icon20PlaceOutline fill="#99A2AD" />
            <div className={styles.infoContainer}>
                <div className={styles.columnWrapper}>
                    <div>{addressItem.address}</div>
                    <div style={{ color: '#6D7885', fontWeight: '400' }}>{addressItem.phone}</div>
                </div>
                <button className={styles.buttonContainer} onClick={handleScheduleToggle}>
                    {!showSchedule ? 'Показать расписание' : 'Скрыть расписание'}
                    <Icon16Chevron
                        style={{ rotate: showSchedule ? '-90deg' : '90deg', transition: 'rotate .25s ease' }}
                    />
                </button>
                <div className={styles.columnWrapper}>
                    {showSchedule
                        ? addressItem.schedule.map((schedule) => (
                              <div className={styles.textSchedule}>{schedule.days + ': ' + schedule.time}</div>
                          ))
                        : null}
                </div>
                {withBorderBottom ? <div className={styles.separator} /> : null}
            </div>
        </div>
    );
}

export default React.memo(SalonAddressItem);
