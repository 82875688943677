import ChromePicker from 'react-color/lib/components/chrome/Chrome';
import { type ChromePickerProps } from 'react-color/lib/components/chrome/Chrome';
import { useEffect, useRef } from 'react';

import './style.css';

export const ColorPicker = (props: ChromePickerProps & { width: string | number }) => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const palette = document
            .querySelectorAll('.colorPickerContainer .saturation-white>div')
            .item(1)
            .querySelector<HTMLDivElement>('div');
        if (palette) {
            palette.style.width = '30px';
            palette.style.height = '30px';
            palette.style.borderRadius = '50%';
        }

        const picker = document.querySelector<HTMLDivElement>('.chrome-picker');
        if (picker) {
            picker.style.boxShadow = '';
        }
        const pickerChild = picker?.children.item(0) as HTMLDivElement;
        const toFixPadding = picker?.children.item(1) as HTMLDivElement;
        toFixPadding.style.padding = '0 0 20px';
        // TODO: Возможно, тут вместо 0.4 нужно 0.3
        pickerChild.style.height = String(pickerChild?.offsetWidth * 0.4) + 'px';

        const hue = document.querySelector<HTMLDivElement>('.hue-horizontal');
        if (hue) {
            hue.style.height = '30px';
            hue.style.borderBottomLeftRadius = '5px';
            hue.style.borderBottomRightRadius = '5px';
        }

        const pointer = document.querySelector<HTMLDivElement>('.hue-horizontal div div');
        if (pointer) {
            pointer.style.height = '30px';
            pointer.style.width = '30px';
            pointer.style.borderRadius = '50%';
        }

        const unnecessary = document.querySelectorAll<HTMLDivElement>('.flexbox-fix div').item(0);
        if (unnecessary) {
            unnecessary.style.display = 'none';
        }

        const otherTools = document.querySelectorAll<HTMLDivElement>('.chrome-picker>div').item(1);
        const inputs = otherTools.querySelectorAll<HTMLDivElement>('.flexbox-fix').item(1);
        if (inputs) {
            inputs.style.display = 'none';
        }
    }, []);
    useEffect(() => {
        const listener = (e: TouchEvent) => e.stopPropagation;
        ref?.current?.addEventListener('touchstart', (e) => {
            e.stopPropagation();
        });
        return () => ref?.current?.removeEventListener('touchstart', listener);
    }, [ref.current]);

    return (
        <div ref={ref} className="colorPickerContainer">
            <ChromePicker
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                width={props.width}
                disableAlpha
                onChange={(color, event) => {
                    if (props.onChange) {
                        props?.onChange(color, event);
                    }
                }}
                color={props.color}
            />
        </div>
    );
};
