import { useState, useEffect } from 'react';

import { BASE_API } from '../constants/base-api';

const BANNER_URL = `${BASE_API}/project/contest`;

interface IBanner {
    public: {
        name: string;
        image: string;
        url_public: string;
    };
    image_banner_mobile: string;
    image_banner_desktop: string;
    is_light: boolean;
    hex_background: string;
    title_2: string;
    text_2: string;
    aboutcontest_title: string;
    aboutcontest_subhead: string;
    aboutcontest_caption: string;
    repeat_background: string;
    tooltip: string;
    title_save_project: string;
    text_save_project: string;
}

export const useBannerData = () => {
    const [bannerData, setBannerData] = useState<IBanner | null>(null);

    useEffect(() => {
        async function fetchBanner() {
            try {
                const response = await fetch(BANNER_URL);
                const data: IBanner = await response.json();
                setBannerData(data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchBanner();
    }, []);

    return bannerData;
};
