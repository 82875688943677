import React, { useEffect, useState } from 'react';
import { Avatar, Card, Headline, ModalCard, ScreenSpinner, Spacing, Text, Title } from '@vkontakte/vkui';

import { BASE_API } from 'constants/base-api';

import { type IWithId } from '../../../../types/interfaces';
import { type IDefaults } from '../../../../types/tuning-model';
import { useSaveProject } from '../../../../queries/use-save-project';
import { ButtonFullWidth } from '../../../../components/button-full-width/button-full-width';
import { getUserId } from '../../../../util/get-user-id';
import { useSendToBattle, useSendToContest } from '../../../../queries/use-send-to-battle';
import { ORDER_MODAL, PAGE_BATTLE_CHALLENGE, router } from '../../../../router';
import { useShareApp } from '../../../../queries/use-share-app';
import { useGetProvideAccess } from '../../../../hooks/use-get-provide-access';
import { useGetSetAccessToken } from '../../../../components/access-token-provider/access-token-provider';

import { useUsedSalonIdsLayer } from '../../../../components/advertisement-provider/advertisement-provider';

import { useBannerData } from '../../../../hooks/use-banner-data';

import styles from './modal-card-save.module.css';

interface IProps extends IWithId {
    currentVehicle: IDefaults;
    setCurrentVehicle: React.Dispatch<any>;
    contest?: boolean;
}

export const ModalCardSave = ({ id, currentVehicle, contest }: IProps) => {
    const { mutateAsync: saveProject } = useSaveProject();
    const [projectId, setProjectId] = useState<number | null>(null);
    const { mutateAsync: sendToBattle } = useSendToBattle();
    const { mutateAsync: sendToContest } = useSendToContest();
    const { mutateAsync: shareApp } = useShareApp();
    const provideAccess = useGetProvideAccess();
    const setAccessToken = useGetSetAccessToken();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { usedSalonIds } = useUsedSalonIdsLayer();
    const bannerData = useBannerData();

    useEffect(() => {
        saveProject({
            defaults: {
                ...currentVehicle,
                people_id: getUserId(),
            },
        }).then((data) => {
            setProjectId(data);

            if (contest) {
                sendToContest(data);
            }
        });
    }, []);

    const url = `url(${currentVehicle?.picture})`;

    const photoParams = {
        width: 280,
        height: 300,
    };

    return (
        <ModalCard
            header={
                <>
                    {contest ? (
                        <Avatar
                            src={`${BASE_API}${bannerData?.public.image}`}
                            style={{ marginInline: 'auto', marginBottom: 8 }}
                            size={72}
                        />
                    ) : null}
                    <Title level="2">{contest ? bannerData?.title_save_project : `Проект сохранен в галерею`}</Title>
                </>
            }
            subheader={
                contest ? (
                    <Headline level="2" style={{ marginTop: 0 }}>
                        {bannerData?.text_save_project}
                    </Headline>
                ) : null
            }
            id={id}
        >
            {projectId ? (
                <>
                    <Spacing size={16} />
                    <Card
                        style={{
                            backgroundImage: url,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div
                            style={{
                                height: photoParams.height,
                                width: photoParams.width,
                            }}
                        />
                    </Card>
                    <Spacing size={8} />
                    <ButtonFullWidth
                        onClick={() => {
                            if (projectId) {
                                if (!contest) {
                                    sendToBattle(projectId).then(() => {
                                        if (!contest) {
                                            localStorage.setItem('isFromTuning', 'yes');
                                        }

                                        router.pushPage(PAGE_BATTLE_CHALLENGE);
                                    });
                                }
                            }
                        }}
                        height={44}
                        {...(contest
                            ? {
                                  target: '_blank',
                                  href: bannerData?.public.url_public,
                                  style: { alignItems: 'center', justifyContent: 'center', display: 'flex' },
                              }
                            : {})}
                    >
                        <Title weight="3" level="3">
                            {contest ? `Перейти в сообщество` : `Отправить на баттл`}
                        </Title>
                    </ButtonFullWidth>
                    <Spacing size={8} />
                    <div className={usedSalonIds?.size ? styles.saveModalButtonGroup : ''}>
                        {usedSalonIds?.size ? (
                            <ButtonFullWidth onClick={() => router.pushModal(ORDER_MODAL)} mode="secondary" height={44}>
                                <Title weight="3" level="3">
                                    Заказать
                                </Title>
                            </ButtonFullWidth>
                        ) : null}
                        <ButtonFullWidth
                            onClick={async () => {
                                const token = await provideAccess('photos');
                                if (token) {
                                    setAccessToken(token);
                                    setTimeout(() => shareApp(projectId), 100);
                                }
                            }}
                            mode="secondary"
                            height={44}
                        >
                            <Title weight="3" level="3">
                                Поделиться с друзьями
                            </Title>
                        </ButtonFullWidth>
                    </div>
                </>
            ) : projectId === null ? (
                <>
                    <Spacing size={100} />
                    <ScreenSpinner />
                    <Spacing size={100} />
                </>
            ) : (
                <Text style={{ textAlign: 'center' }}>Что-то пошло не так. Пожалуйста, попробуйте позже.</Text>
            )}
        </ModalCard>
    );
};
