import React from 'react';
import { FormItem } from '@vkontakte/vkui';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { vehicleConfigs } from '../../../../engine/vehicle-configs';
import { DebouncedSlider } from '../../../../components/debounced-slider';
import { TuningHeader } from '../tuning-header';

export const EditRimPickSizeModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const handleRimReset = React.useCallback(() => {
        setCurrentValue({
            rim_width: vehicleConfigs.defaults.rim_width,
            rim_diameter: vehicleConfigs.defaults.rim_diameter,
        });
    }, [currentValue]);

    return (
        <div>
            <TuningHeader children="Выберите размер диска" onReset={handleRimReset} />
            <FormItem top="Ширина">
                <DebouncedSlider
                    min={8}
                    max={25}
                    step={1}
                    currentValue={currentValue.rim_width}
                    setCurrentValue={(v) => setCurrentValue({ rim_width: v })}
                />
            </FormItem>
            <FormItem top="Диаметр">
                <DebouncedSlider
                    min={20}
                    max={44}
                    step={3}
                    currentValue={currentValue.rim_diameter}
                    setCurrentValue={(v) => setCurrentValue({ rim_diameter: v })}
                />
            </FormItem>
        </div>
    );
};
