import { useState } from 'react';
import { AdaptivityProvider, AppRoot, ConfigProvider, Epic, Root, View } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import { useLocation } from '@happysanta/router';

import { useCommonViewProps } from 'hooks/use-common-view-props';

import { useInitialAccessToken } from 'hooks/use-initial-access-token';

import { useVehicleConfigs } from 'hooks/use-vehicle-configs';

import {
    PANEL_ABOUT,
    PANEL_BATTLE_CHALLENGE,
    PANEL_BATTLE_LEADERS,
    PANEL_ERROR,
    PANEL_HELP,
    PANEL_MAIN,
    PANEL_PROFILE,
    PANEL_SALON_ITEM,
    PANEL_SALONS,
    PANEL_TUNING,
    VIEW_ABOUT,
    VIEW_BATTLE,
    VIEW_HELP,
    VIEW_MAIN,
    VIEW_PROFILE,
    VIEW_SALONS,
    VIEW_TUNING,
    VIEW_PROFILE_PROJECTS,
    PANEL_PROFILE_PROJECTS,
    VIEW_CONTEST,
    PANEL_CONTEST,
} from './router';
import { Home } from './features/home/home';
import { MyTabbar } from './components/my-tabbar/my-tabbar';
import { Salons } from './features/salons/salons/salons';
import { SalonItem } from './features/salons/salon-item/salon-item';
import { forbidTabbar } from './constants/forbid-tabbar';
import { Profile } from './features/profile/profile';
import { ProfileProjects } from './features/profile-projects';
import { Battle } from './features/battle/battle';
import { Leaders } from './features/battle/leaders';
import { Tuning } from './features/tuning/tuning';
import { Content } from './features/content/content';
import style from './features/home/home.module.css';

import './index.css';
import { AccessTokenProvider } from './components/access-token-provider/access-token-provider';
import { BridgeContextProvider } from './components/bridge-context-provider/bridge-context-provider';
import ErrorPanel from './features/error/error-panel';
import { useIsContest } from './queries/use-is-contest';
import { AdvertisementProvider } from './components/advertisement-provider/advertisement-provider';
import { NotificationSnackbarProvider } from './components/notifications-snackbar-provider/notifications-snackbar-provider';
import SplitLayoutProvider from './components/split-layout-provider/split-layout-provider';

const App = () => {
    const location = useLocation();
    const panelId = location.getViewActivePanel(location.getViewId());
    const [canvasLoaded, setCanvasLoaded] = useState(false);

    const { data: isContest } = useIsContest();
    const { commonViewProps } = useCommonViewProps();
    const { accessToken, setAccessToken } = useInitialAccessToken();
    const { tuningVehicle, setTuningVehicle, contestVehicle, setContestVehicle } = useVehicleConfigs();

    // TODO: Убрав appearance=light с небольшим кол-вом доработок мы можем поддержать темную тему

    return (
        <ConfigProvider appearance="light" hasCustomPanelHeaderAfter>
            <AdaptivityProvider>
                <AccessTokenProvider setToken={(value) => setAccessToken(value)} value={accessToken}>
                    <AdvertisementProvider>
                        <NotificationSnackbarProvider>
                            <BridgeContextProvider>
                                <AppRoot className="app_root">
                                    <SplitLayoutProvider>
                                        <Epic
                                            activeStory="root"
                                            tabbar={
                                                !forbidTabbar.includes(panelId as string) &&
                                                !location.getModalId() && <MyTabbar />
                                            }
                                        >
                                            <Root id="root" activeView={location.getViewId()}>
                                                <View {...commonViewProps(VIEW_MAIN)}>
                                                    <Home isContest={isContest} id={PANEL_MAIN} />
                                                    <ErrorPanel id={PANEL_ERROR} />
                                                </View>
                                                <View {...commonViewProps(VIEW_SALONS)}>
                                                    <Salons id={PANEL_SALONS} />
                                                    <SalonItem id={PANEL_SALON_ITEM} />
                                                </View>
                                                <View {...commonViewProps(VIEW_PROFILE)}>
                                                    <Profile isContest={isContest} id={PANEL_PROFILE} />
                                                </View>
                                                <View {...commonViewProps(VIEW_PROFILE_PROJECTS)}>
                                                    <ProfileProjects id={PANEL_PROFILE_PROJECTS} />
                                                </View>
                                                <View {...commonViewProps(VIEW_HELP)}>
                                                    <Content id={PANEL_HELP} />
                                                </View>
                                                <View {...commonViewProps(VIEW_ABOUT)}>
                                                    <Content id={PANEL_ABOUT} />
                                                </View>
                                                <View {...commonViewProps(VIEW_BATTLE)}>
                                                    <Battle id={PANEL_BATTLE_CHALLENGE} />
                                                    <Leaders id={PANEL_BATTLE_LEADERS} />
                                                </View>
                                                <View className="modalContainer" {...commonViewProps(VIEW_TUNING)}>
                                                    <div className={style.positionTop} id={PANEL_TUNING}>
                                                        {tuningVehicle ? (
                                                            <Tuning
                                                                id={PANEL_TUNING}
                                                                canvasLoaded={canvasLoaded}
                                                                setCanvasLoaded={setCanvasLoaded}
                                                                currentVehicle={tuningVehicle}
                                                                setVehicle={setTuningVehicle}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </View>
                                                <View className="modalContainer" {...commonViewProps(VIEW_CONTEST)}>
                                                    <div id={PANEL_CONTEST}>
                                                        {contestVehicle ? (
                                                            <Tuning
                                                                id={PANEL_CONTEST}
                                                                contest
                                                                canvasLoaded={canvasLoaded}
                                                                setCanvasLoaded={setCanvasLoaded}
                                                                currentVehicle={contestVehicle}
                                                                setVehicle={setContestVehicle}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </View>
                                            </Root>
                                        </Epic>
                                    </SplitLayoutProvider>
                                </AppRoot>
                            </BridgeContextProvider>
                        </NotificationSnackbarProvider>
                    </AdvertisementProvider>
                </AccessTokenProvider>
            </AdaptivityProvider>
        </ConfigProvider>
    );
};

export default App;
