import { Button } from '@vkontakte/vkui';
import React from 'react';

import { ABOUT_CONTEST_MODAL } from 'router';
import { useRouter } from '@happysanta/router';

import styles from './about-contest-button.module.css';

export const AboutContestButton = () => {
    const router = useRouter();

    const handleAboutContestClick = React.useCallback(() => {
        router.pushModal(ABOUT_CONTEST_MODAL);
    }, []);
    return (
        <Button
            className={styles.aboutContestButton}
            onClick={handleAboutContestClick}
            size="s"
            mode="primary"
            appearance="overlay"
            before={
                <svg
                    style={{ padding: 0 }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.0021 3.60906C13.4122 1.06963 16.0769 0.759809 17.6 2.28289C18.8445 3.52735 18.8653 5.5366 17.4524 7.00102L18 6.99999C20.2091 6.99999 22 8.79085 22 11V11.4294C22 12.4364 21.8608 12.9764 21.5993 13.4653C21.2596 14.1004 20.7249 14.558 20.0016 14.8007L20 16.8722C20 18.6553 19.8143 19.3018 19.4657 19.9537C19.1171 20.6055 18.6055 21.1171 17.9537 21.4657C17.3018 21.8143 16.6553 22 14.8722 22H9.12777C7.34473 22 6.69816 21.8143 6.04631 21.4657C5.39446 21.1171 4.88288 20.6055 4.53427 19.9537C4.18565 19.3018 4 18.6553 4 16.8722L3.99936 14.801C3.27553 14.5584 2.74054 14.1007 2.4007 13.4653C2.16103 13.0171 2.02406 12.526 2.00289 11.6717L2 11C2 8.79085 3.79086 6.99999 6 6.99999L6.55091 7.00102C5.13799 5.5366 5.1588 3.52735 6.40326 2.28289C7.92752 0.758629 10.5952 1.07011 12.0021 3.60906ZM11.1 15H5.799L5.801 17.1155C5.81406 17.7877 5.79625 18.4966 6.12153 19.1048C6.67024 20.1308 7.84622 20.19 8.88446 20.199H11.1V15ZM18.2 15H12.9V20.199H15.1155C16.1526 20.1788 17.329 20.1323 17.8785 19.1048C18.1013 18.6882 18.1889 18.2804 18.199 17.1155L18.2 15ZM18 8.79999C16.3131 8.80022 12.9007 8.80141 12.9007 8.80141L12.9 13.199H18.524C19.0693 13.1947 19.7236 13.1558 20.012 12.6164C20.1293 12.397 20.1877 12.1524 20.1983 11.6185L20.2 11C20.2 9.78496 19.215 8.79999 18 8.79999ZM6 8.79999C4.78497 8.79999 3.8 9.78496 3.8 11L3.80174 11.6185C3.81225 12.1524 3.87066 12.397 3.98796 12.6164C4.27616 13.1553 4.92847 13.1945 5.47271 13.1995L11.1 13.199L11.1006 8.80128C11.1006 8.80128 7.68723 8.80022 6 8.79999ZM7.67606 3.55568C6.60685 4.62489 7.59914 6.63202 10.8488 6.9746C10.942 6.98262 11.103 6.99783 11.103 6.99783C10.8434 3.53819 8.87416 2.35758 7.67606 3.55568ZM16.3272 3.55568C15.1291 2.35758 13.1599 3.53819 12.9002 6.99783C12.9002 6.99783 13.0703 6.98184 13.1545 6.9746C16.4041 6.63202 17.3964 4.62489 16.3272 3.55568Z"
                        fill="#2688EB"
                    />
                </svg>
            }
        >
            О конкурсе
        </Button>
    );
};
