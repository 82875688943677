import { type CSSProperties, type Ref, useLayoutEffect, useRef, useState } from 'react';
import { Group, Touch as VKTouch, type TouchProps } from '@vkontakte/vkui';

const circleStyle: CSSProperties = {
    width: 26,
    height: 26,
    borderRadius: '50%',
    background: 'var(--vkui--color_background_accent)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: -13,
    marginTop: -13,
};

const containerStyle: CSSProperties = {
    height: 120,
    position: 'relative',
    backgroundColor: '#F5F5F5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.08)',
};

interface IProps {
    onMovement: (shiftX: number, shiftY: number) => void;
}

export const Touch = ({ onMovement }: IProps) => {
    const [shiftX, setShiftX] = useState(0);
    const [shiftY, setShiftY] = useState(0);
    const [limitX, setLimitX] = useState(0);
    const [limitY, setLimitY] = useState(0);

    const circleRef = useRef<HTMLElement>();
    const startX = useRef<number>(0);
    const startY = useRef<number>(0);

    useLayoutEffect(() => {
        setLimitX(circleRef.current?.offsetLeft || 0);
        setLimitY(circleRef.current?.offsetTop || 0);
    });

    const getValueWithLimit = (value: number, limit: number) => {
        return value > limit ? limit : value < -limit ? -limit : value;
    };

    const onMove: TouchProps['onMove'] = (e) => {
        const rawShiftX = startX.current + e.shiftX;
        const rawShiftY = startY.current + e.shiftY;

        setShiftX(getValueWithLimit(rawShiftX, limitX));
        setShiftY(getValueWithLimit(rawShiftY, limitY));
        onMovement(shiftX, -shiftY);
    };

    const onEnd: TouchProps['onEnd'] = (e) => {
        const shiftX = startX.current + e.shiftX;
        const shiftY = startY.current + e.shiftY;

        startX.current = getValueWithLimit(shiftX, limitX);
        startY.current = getValueWithLimit(shiftY, limitY);
    };

    return (
        <Group>
            <div
                style={{
                    ...containerStyle,
                }}
            >
                <VKTouch
                    getRootRef={circleRef as Ref<HTMLElement>}
                    onMove={onMove}
                    onEnd={onEnd}
                    style={{
                        ...circleStyle,
                        transform: `translate(${shiftX}px, ${shiftY}px)`,
                    }}
                />
            </div>
        </Group>
    );
};
