import { Page, Router } from '@happysanta/router';

export const VIEW_MAIN = 'view_main';
export const PAGE_MAIN = '/';
export const PANEL_MAIN = 'panel_main';
export const PAGE_ERROR = '/error';
export const PANEL_ERROR = 'panel_error';

export const PAGE_PROFILE = '/profile';
export const PANEL_PROFILE = 'panel_profile';
export const VIEW_PROFILE = 'view_profile';

export const PAGE_PROFILE_PROJECTS = '/projects';
export const PANEL_PROFILE_PROJECTS = 'panel_profile_projects';
export const VIEW_PROFILE_PROJECTS = 'view_profile_projects';

export const PAGE_HELP = '/help';
export const PANEL_HELP = 'panel_help';
export const VIEW_HELP = 'view_help';
export const PAGE_ABOUT = '/about';
export const VIEW_ABOUT = 'view_about';
export const PANEL_ABOUT = 'panel_about';

export const PAGE_BATTLE_CHALLENGE = '/battle/challenge';
export const PANEL_BATTLE_CHALLENGE = 'panel_battle_challenge';

export const PAGE_BATTLE_LEADERS = '/battle/leaders';
export const PANEL_BATTLE_LEADERS = 'panel_battle_leaders';

export const VIEW_BATTLE = 'view_battle';

export const PAGE_TUNING = '/tuning';
export const PANEL_TUNING = 'panel_tuning';
export const VIEW_TUNING = 'view_tuning';

export const PAGE_CONTEST = '/contest';
export const PANEL_CONTEST = 'panel_contest';
export const VIEW_CONTEST = 'view_contest';

export const MODAL_TUNING_PICK_TIRE_MODEL = 'modal_tuning_pick_tire_model';
export const MODAL_TUNING_PICK_TIRE_SIZE = 'modal_tuning_pick_tire_size';

export const MODAL_TUNING_PICK_RIM_MODEL = 'modal_tuning_pick_rim_model';
export const MODAL_TUNING_PICK_RIM_COLOR = 'modal_tuning_pick_rim_color';
export const MODAL_TUNING_PICK_RIM_SIZE = 'modal_tuning_pick_rim_size';
export const MODAL_TUNING_PICK_RIM_ACCENT = 'modal_tuning_pick_rim_accent';

export const MODAL_TUNING_COLOR = 'modal_tuning_color';
export const MODAL_TUNING_GLASS_TONE = 'modal_tuning_glass_tone';
export const MODAL_TUNING_MATERIAL = 'modal_tuning_material';
export const MODAL_TUNING_RIM_MATERIAL = 'modal_tuning_rim_material';
export const MODAL_TUNING_ENV = 'modal_tuning_env';
export const MODAL_TUNING_PICK_CAR = 'modal_tuning_pick_car';
export const MODAL_TUNING_PICK_STICKER = 'modal_tuning_pick_sticker';
export const MODAL_TUNING_OFFSET = 'modal_tuning_offset';
export const MODAL_TUNING_LIFT = 'modal_tuning_lift';
export const MODAL_TUNING_PLATE_NUMBERS = 'modal_tuning_plate_numbers';

export const AD_MODAL = 'ad_modal';

export const BASKET_MODAL = 'basket_modal';

export const MODAL_CARD_SAVE = 'modal_card_save';

export const MODAL_PROJECT_SHARE = 'modal_project_share';

export const MODAL_EDIT_BODY_KIT = 'modal_edit_body_kit';

export const ABOUT_CONTEST_MODAL = 'about_contest_modal'

export const PANEL_SALONS = 'panel_salons';
export const PAGE_SALONS = '/salons';
export const PANEL_SALON_ITEM = 'panel_salon_item';
export const PAGE_SALON_ITEM = '/salons/:id([0-9a-zA-Z-]+)';
export const VIEW_SALONS = 'view_salons';

export const SALONS_FILTER_CITY_MODAL_PAGE = 'salons_filter_city_modal_page';
export const SALONS_FILTER_SERVICE_MODAL_PAGE = 'salons_filter_service_modal_page';
export const SALONS_FILTER_CAR_TYPE_MODAL_PAGE = 'salons_filter_car_type_page';

export const ABOUT_SALON_MODAL_PAGE = 'about_salon_modal_page';

export const BATTLE_WELLCOME_MODAL = 'battle_welcome_modal';
export const BATTLE_LEADERS_MODAL = 'battle_leaders_modal';
export const BATTLE_PROFILE_PROJECTS = 'battle_profile_projects';

export const CONTEST_WELCOME_MODAL = 'contest_welcome_modal';

export const ORDER_MODAL = 'order_modal';

export const ENABLE_NOTIFICATIONS_MODAL = 'enable_notifications_modal';

export const routes = {
    [PAGE_MAIN]: new Page(PANEL_MAIN, VIEW_MAIN),
    [PAGE_SALONS]: new Page(PANEL_SALONS, VIEW_SALONS),
    [PAGE_SALON_ITEM]: new Page(PANEL_SALON_ITEM, VIEW_SALONS),
    [PAGE_BATTLE_LEADERS]: new Page(PANEL_BATTLE_LEADERS, VIEW_BATTLE),
    [PAGE_BATTLE_CHALLENGE]: new Page(PANEL_BATTLE_CHALLENGE, VIEW_BATTLE),
    [PAGE_PROFILE]: new Page(PANEL_PROFILE, VIEW_PROFILE),
    [PAGE_PROFILE_PROJECTS]: new Page(PANEL_PROFILE_PROJECTS, VIEW_PROFILE_PROJECTS),
    [PAGE_TUNING]: new Page(PANEL_TUNING, VIEW_TUNING),
    [PAGE_CONTEST]: new Page(PANEL_CONTEST, VIEW_CONTEST),
    [PAGE_HELP]: new Page(PANEL_HELP, VIEW_HELP),
    [PAGE_ABOUT]: new Page(PANEL_ABOUT, VIEW_ABOUT),
    [PAGE_ERROR]: new Page(PANEL_ERROR, VIEW_MAIN),
};

export const router = new Router(routes);
router.start();
