import { memo, useEffect } from 'react';
import { Box3, type EventDispatcher, Group, Vector3 } from 'three';

import { getColorAccordingToCategory } from 'features/tuning/modals/edit-color-modal/utils';

import { useVehicleState } from '../../components/vehicle-state-provider/vehicle-state-provider';

import useAnimateHeight from '../hooks/useAnimateHeight';
import getSetMaterialProperties from '../hooks/get-set-material-properties';
import { type IDefaults } from '../../types/tuning-model';
import { useDetailsContext } from '../../components/details-context-provider/details-context-provider';
import { useConfigureObject3dListeners } from '../hooks/use-configure-object3d-listeners';
import { BASE_API } from '../../constants/base-api';
import { useGetCarModel } from '../../hooks/use-get-car-model';
import { ChangeGlassToneEvent } from '../events/body-events-handlers/change-glass-tone';
import { ChangeExternalMaterialEvent } from '../events/common-events/change-external-material';
import mapBodyConfiguration from '../events/body-events-handlers';
import { type BasicEvent } from '../events/basic-event';
import { BodyEventTypes } from '../events/body-events-handlers/event-types';
import { ColorChangeEvent, type TNamePartOfCar } from '../events/body-events-handlers/color-change-event';
import { NumberPlates } from './number-plates/number-plates';
import Model from './model';

interface IBodyProps
    extends Pick<
        IDefaults,
        | 'id'
        | 'color'
        | 'roughness'
        | 'addons'
        | 'stickers'
        | 'glass_tone'
        | 'interiorColor'
        | 'headLightsTone'
        | 'number_plates'
    > {
    height: number;
}

// Body.
const Body = memo(
    ({
        id,
        height,
        color,
        addons,
        roughness,
        interiorColor,
        glass_tone,
        headLightsTone,
        number_plates,
    }: IBodyProps) => {
        const { vehicleState: currentVehicle } = useVehicleState();
        const modelInfo = useGetCarModel(id);
        const vehicle = useDetailsContext().vehicle;
        const { setObjectMaterials } = getSetMaterialProperties();
        const current = vehicle.current as unknown as EventDispatcher<BasicEvent<BodyEventTypes>>;
        useConfigureObject3dListeners(current, mapBodyConfiguration);

        // Set body color.
        useEffect(() => {
            if (vehicle.current) {
                setObjectMaterials(vehicle.current, color);
                vehicle.current.dispatchEvent(new ChangeGlassToneEvent(vehicle.current, 'glass', glass_tone));
                vehicle.current.dispatchEvent(new ChangeGlassToneEvent(vehicle.current, 'glass_light', headLightsTone));
                vehicle.current.dispatchEvent(
                    new ChangeExternalMaterialEvent<BodyEventTypes>(
                        vehicle.current,
                        roughness,
                        BodyEventTypes.CHANGE_INTERIOR_MATERIAL,
                    ),
                );
                vehicle.current.dispatchEvent(
                    new ColorChangeEvent(BodyEventTypes.INTERIOR_CHANGE, 'interior', vehicle.current, interiorColor),
                );

                if (currentVehicle?.parts)
                    Object.entries(currentVehicle?.parts).forEach((value) => {
                        const [part, color] = value;
                        const existingColor = getColorAccordingToCategory(currentVehicle)?.[part as TNamePartOfCar];

                        // if (existingColor === color || !existingColor) {
                        console.log('haha changed');

                        vehicle?.current?.dispatchEvent(
                            new ColorChangeEvent(
                                BodyEventTypes.COLOR_CHANGE,
                                part as TNamePartOfCar,
                                vehicle.current,
                                existingColor === color || !existingColor ? color : existingColor,
                            ),
                        );
                        // }
                    });
            }
        }, [vehicle.current, addons]);

        useEffect(() => {
            vehicle.current?.dispatchEvent({ type: 'lift' });
        }, [vehicle.current?.position.y]);

        // Animate height.
        useAnimateHeight(vehicle, height, height + 0.1);
        const coords = new Box3().setFromObject(vehicle.current?.children[0] || new Group());
        const frontPlateNumberZCoord = coords.max.z !== -Infinity ? coords.max.z : 0;
        const backPlateNumberZCoord = coords.min.z !== -Infinity ? coords.min.z : 0;
        const frontPlatesConfig = modelInfo?.config?.frontPlatesOffset || [0, 0, 0];
        const backPlatesConfig = modelInfo?.config?.backPlatesOffset || [0, 0, 0];

        return (
            <group position={[0, 0, -0.04]} ref={vehicle} name="Body" key={id}>
                <Model path={modelInfo?.link} />
                {number_plates?.type && (
                    <NumberPlates
                        frontPlatesCoords={[
                            frontPlatesConfig[0],
                            0.3 + frontPlatesConfig[1],
                            frontPlateNumberZCoord + 0.04 + frontPlatesConfig[2],
                        ]}
                        backPlatesCoords={[
                            backPlatesConfig[0],
                            0.3 + backPlatesConfig[1],
                            backPlateNumberZCoord + 0.04 + backPlatesConfig[2],
                        ]}
                        type="RU"
                        symbols={number_plates?.number}
                    />
                )}
                {addons.length ? (
                    <group name="Addons">
                        {addons?.map((addon) => {
                            const position =
                                modelInfo?.config?.addonsPosition?.[addon?.name] ||
                                modelInfo?.config?.addonsPosition?.[addon?.type];
                            const preScale =
                                modelInfo?.config?.addonsScale?.[addon?.name] ||
                                modelInfo?.config?.addonsScale?.[addon?.type];
                            const scale = Array.isArray(preScale)
                                ? new Vector3(...preScale)
                                : new Vector3(1, 1, preScale || 1);

                            return (
                                <Model
                                    scale={scale}
                                    key={addon.name}
                                    path={`${BASE_API}${addon.file}`}
                                    position={position}
                                />
                            );
                        })}
                        {addons
                            ?.filter((a) => a.type === 'side_bumper')
                            .map((addon) => {
                                const position =
                                    modelInfo?.config?.addonsPosition?.[addon?.name] ||
                                    modelInfo?.config?.addonsPosition?.[addon?.type];
                                const scale =
                                    modelInfo?.config?.addonsScale?.[addon?.name] ||
                                    modelInfo?.config?.addonsScale?.[addon?.type];
                                let mirroredPosition;
                                if (position) {
                                    mirroredPosition = [...(position || [])];
                                    mirroredPosition[0] = -mirroredPosition[0];
                                }

                                return (
                                    <Model
                                        mirror
                                        scale={new Vector3(-1, 1, scale || 1)}
                                        key={addon.name}
                                        path={`${BASE_API}${addon.file}`}
                                        position={mirroredPosition || [0, 0, 0]}
                                    />
                                );
                            })}
                    </group>
                ) : null}
            </group>
        );
    },
);

export default Body;
