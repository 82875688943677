import React from 'react';
import { Caption, Card, type CardProps, Spacing } from '@vkontakte/vkui';
import { Icon24CheckCircleFilledBlue } from '@vkontakte/icons';
import cn from 'classnames';

import styles from './card-with-tick.module.css';

interface IProps extends CardProps {
    ticked?: boolean;
    header?: string;
    backgroundUrl?: string;
}

export const CardWithTick = (props: IProps) => {
    const { ticked, header, style, backgroundUrl, className, ...rest } = props;
    return (
        <div style={{ margin: '0px 8px' }}>
            <Card
                className={cn(styles.card, className)}
                style={{
                    ...style,
                    backgroundImage: `url(${backgroundUrl})`,
                }}
                {...rest}
            >
                <div
                    style={{
                        height: 64,
                        width: 64,
                    }}
                />
                {ticked && (
                    <Icon24CheckCircleFilledBlue
                        style={{
                            position: 'absolute',
                            bottom: -5,
                            right: -5,
                        }}
                    />
                )}
            </Card>
            <Spacing size={5} />
            {header && <Caption className={styles.cardHeader}>{header}</Caption>}
        </div>
    );
};
