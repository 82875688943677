import React, { type Dispatch, useState } from 'react';
import { Vector2 } from 'three';
import {
    FormItem,
    ModalPageHeader,
    PanelHeaderButton,
    Spacing,
    Subhead,
    Tabs,
    useModalRootContext,
} from '@vkontakte/vkui';

import { type IStickerItem } from '../../../../../queries/use-get-stickers';
import { CustomHeader } from '../../../../../components/custom-header/custom-header';
import { DebouncedSlider } from '../../../../../components/debounced-slider';
import { useDetailsContext } from '../../../../../components/details-context-provider/details-context-provider';
import { Touch } from '../../../../../components/touch/touch';
import { ColorPicker } from '../../../../../components/color-picker/color-picker';
import { type IStickerModel } from '../../../../../types/tuning-model';
import { CustomTabsItem } from '../../../../../components/custom-tabs-item/custom-tabs-item';

interface IProps {
    onReadyClick: () => void;
    stickers?: IStickerModel[];
    chosenSticker: IStickerModel;
    setCurrentValue: Dispatch<{ stickers: IStickerItem[] }>;
}

type TCategories = 'color' | 'position' | 'scale';

export const StickerConfiguration = ({ onReadyClick, stickers = [], setCurrentValue, chosenSticker }: IProps) => {
    const value = chosenSticker;
    const { moveAndScaleSticker, changeDetailColor } = useDetailsContext();
    const [selected, setSelected] = useState<TCategories>('position');

    const { updateModalHeight } = useModalRootContext();

    return (
        <>
            <ModalPageHeader
                style={{ textAlign: 'center' }}
                after={<PanelHeaderButton onClick={() => onReadyClick()}>Готово</PanelHeaderButton>}
            >
                <CustomHeader>Настройки наклейки</CustomHeader>
            </ModalPageHeader>
            <Tabs
                style={{
                    margin: '0px 10px',
                    marginBottom: '5px',
                }}
            >
                <CustomTabsItem
                    onClick={() => {
                        setSelected('color');
                        updateModalHeight();
                    }}
                    selected={selected === 'color'}
                >
                    Цвет
                </CustomTabsItem>
                <CustomTabsItem
                    onClick={() => {
                        setSelected('position');
                        updateModalHeight();
                    }}
                    selected={selected === 'position'}
                >
                    Положение
                </CustomTabsItem>
                <CustomTabsItem
                    onClick={() => {
                        setSelected('scale');
                        updateModalHeight();
                    }}
                    selected={selected === 'scale'}
                >
                    Размер
                </CustomTabsItem>
            </Tabs>

            {
                <div style={{ marginTop: '20px' }}>
                    {selected === 'color' && (
                        <div>
                            <ColorPicker
                                width={'100%'}
                                color={value?.color || '#fff'}
                                onChange={(color, event) => {
                                    changeDetailColor(value, color.hex);
                                    event.preventDefault();
                                }}
                            />
                        </div>
                    )}
                    {selected === 'position' && (
                        <div style={{ margin: '0 12px' }}>
                            <Subhead style={{ color: '#6d7885' }} weight="3">
                                Перемещайте кружок, чтобы двигать наклейку
                            </Subhead>
                            <Touch
                                onMovement={(shiftX, shiftY) => {
                                    const filteredStickers = stickers?.filter((s) => s.id !== value.id);
                                    setCurrentValue({
                                        stickers: [
                                            ...filteredStickers,
                                            {
                                                ...value,
                                                position: {
                                                    x: shiftX,
                                                    y: shiftY,
                                                },
                                            },
                                        ],
                                    });
                                    moveAndScaleSticker(value, new Vector2(shiftX, shiftY), value?.scale);
                                }}
                            />
                        </div>
                    )}
                    {selected === 'scale' && (
                        <FormItem top="Выберите размер наклейки">
                            <DebouncedSlider
                                min={0.1}
                                max={2.01}
                                step={0.05}
                                currentValue={value.scale || 1}
                                setCurrentValue={(v) => {
                                    stickers.pop();
                                    setCurrentValue({
                                        stickers: [
                                            ...stickers,
                                            {
                                                ...value,
                                                scale: v,
                                            },
                                        ],
                                    });
                                    moveAndScaleSticker(
                                        value,
                                        new Vector2(value.position?.x || 0, value.position?.y || 0),
                                        v,
                                    );
                                }}
                                visualizeValue={(v) => String((Math.round(v * 100) / 100) * 100).slice(0, 3) + '%'}
                            />
                        </FormItem>
                    )}
                    <Spacing size={10} />
                </div>
            }
        </>
    );
};
