import React from 'react';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { vehicleConfigs } from '../../../../engine/vehicle-configs';
import { useSetModalHeight } from '../../../../hooks/use-set-modal-height';

import { ColorPicker } from '../../../../components/color-picker/color-picker';
import { TuningHeader } from '../tuning-header';

export const EditRimPickColorModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    useSetModalHeight();

    const handleRimColourReset = React.useCallback(() => {
        setCurrentValue({
            rim_color: vehicleConfigs.defaults.rim_color,
        });
    }, [currentValue]);

    return (
        <div>
            <TuningHeader children="Выберите цвет" onReset={handleRimColourReset} />
            <ColorPicker
                width="100%"
                color={currentValue.rim_color}
                onChange={(color, event) => {
                    setCurrentValue({ rim_color: color.hex });
                    event.stopPropagation();
                }}
            />
        </div>
    );
};
