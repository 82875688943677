import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useLocation, useRouter } from '@happysanta/router';
import {
    Avatar,
    Button,
    Counter,
    FixedLayout,
    Group,
    Headline,
    Panel,
    PanelHeader,
    Placeholder,
    ScreenSpinner,
    Search,
    SimpleCell,
    Spacing,
    SplitLayout,
    SubnavigationButton,
} from '@vkontakte/vkui';

import { ParticipateContestSnackbar } from 'components/participate-contest-snackbar/participate-contest-snackbar';

import { type IWithId } from '../../../types/interfaces';
import {
    PAGE_SALON_ITEM,
    SALONS_FILTER_CAR_TYPE_MODAL_PAGE,
    SALONS_FILTER_CITY_MODAL_PAGE,
    SALONS_FILTER_SERVICE_MODAL_PAGE,
} from '../../../router';
import { SalonsSearchModal } from '../modals/salons-search-modal/salons-search-modal';
import { CustomHeader } from '../../../components/custom-header/custom-header';

import { useSearchSalons } from '../../../queries/use-search-salons';
import { getSanityURL } from '../../../util/get-sanity-url';
import { CustomScroll } from '../../../components/custom-scroll/custom-scroll';

import '../../../index.css';
import styles from './salons.module.css';

interface IProps extends IWithId {}

export const Salons = ({ id }: IProps) => {
    const router = useRouter();
    const location = useLocation();
    const [search, setSearch] = useState('');

    const [cities, setCities] = useState<string[]>([]);
    const [carTypes, setCarTypes] = useState<string[]>([]);
    const [serviceTypes, setServiceTypes] = useState<string[]>([]);

    const { data, isLoading, refetch, error } = useSearchSalons(cities, carTypes, serviceTypes);
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e?.target?.value);
    }, []);
    const searchedData = data?.filter((salon) => salon.name.toLowerCase().includes(search.toLowerCase()));

    const haveDataToDisplay = searchedData && searchedData?.length > 0;

    const handleFiltersReset = React.useCallback(() => {
        setSearch('');
        setCities([]);
        setCarTypes([]);
        setServiceTypes([]);
    }, []);

    return (
        <SplitLayout
            modal={
                <SalonsSearchModal
                    setServiceTypes={setServiceTypes}
                    setCities={setCities}
                    setCarTypes={setCarTypes}
                    serviceTypes={serviceTypes}
                    cities={cities}
                    carTypes={carTypes}
                    id={location.getModalId() as string}
                    refetch={refetch}
                />
            }
        >
            <Panel id={id}>
                <PanelHeader fixed className="panelHeaders">
                    <CustomHeader>Салоны</CustomHeader>
                </PanelHeader>
                <FixedLayout vertical="top" filled>
                    <Search
                        className={styles.search}
                        value={search}
                        onChange={onChange}
                        placeholder="Поиск по названию"
                    />
                    <div style={{ position: 'relative' }}>
                        <div>
                            <CustomScroll showArrows={false}>
                                <SubnavigationButton
                                    onClick={() => router.pushModal(SALONS_FILTER_CITY_MODAL_PAGE)}
                                    style={{ margin: '12px 4px 20px 4px' }}
                                    expandable
                                    after={
                                        cities.length ? (
                                            <Counter size="s" mode="prominent">
                                                {cities.length}
                                            </Counter>
                                        ) : null
                                    }
                                >
                                    <Headline level="2">Города</Headline>
                                </SubnavigationButton>
                                <SubnavigationButton
                                    onClick={() => router.pushModal(SALONS_FILTER_CAR_TYPE_MODAL_PAGE)}
                                    style={{ margin: '12px 4px 20px 4px' }}
                                    expandable
                                    after={
                                        carTypes.length ? (
                                            <Counter size="s" mode="prominent">
                                                {carTypes.length}
                                            </Counter>
                                        ) : null
                                    }
                                >
                                    <Headline level="2">Марки авто</Headline>
                                </SubnavigationButton>
                                <SubnavigationButton
                                    onClick={() => router.pushModal(SALONS_FILTER_SERVICE_MODAL_PAGE)}
                                    style={{ margin: '12px 4px 20px 4px' }}
                                    expandable
                                    after={
                                        serviceTypes.length ? (
                                            <Counter size="s" mode="prominent">
                                                {serviceTypes.length}
                                            </Counter>
                                        ) : null
                                    }
                                >
                                    <Headline level="2">Услуги</Headline>
                                </SubnavigationButton>
                            </CustomScroll>
                        </div>
                    </div>
                </FixedLayout>
                {error ? (
                    <div className={classnames(styles.salonsContainer, !haveDataToDisplay && styles.emptySalons)}>
                        <Group>
                            <Placeholder>Ошибка загрузки данных</Placeholder>
                        </Group>
                    </div>
                ) : isLoading ? (
                    <ScreenSpinner />
                ) : (
                    <div className={classnames(styles.salonsContainer, !haveDataToDisplay && styles.emptySalons)}>
                        <Group>
                            <Spacing size={32}></Spacing>
                            {haveDataToDisplay ? (
                                searchedData?.map((salon) => {
                                    return (
                                        <SimpleCell
                                            key={salon.id}
                                            onClick={() => {
                                                router.pushPage(PAGE_SALON_ITEM, { id: salon.id });
                                            }}
                                            before={<Avatar src={getSanityURL(salon.image).toString()} size={48} />}
                                            subtitle={salon.address}
                                        >
                                            {salon.name}
                                        </SimpleCell>
                                    );
                                })
                            ) : (
                                <Placeholder
                                    action={
                                        <Button className={styles.emptyFiltersButton} onClick={handleFiltersReset}>
                                            Сбросить фильтры
                                        </Button>
                                    }
                                >
                                    По заданным фильтрам ничего не найдено, попробуйте другие параметры
                                </Placeholder>
                            )}
                        </Group>
                    </div>
                )}
            </Panel>
            <ParticipateContestSnackbar />
        </SplitLayout>
    );
};
