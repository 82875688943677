import { type TDetailName } from '../queries/use-get-stickers';

export const stickerConfiguration: Record<
    TDetailName,
    { canvasHeight: number; canvasWidth: number; offsetY: number; initialHeight: number }
> = {
    body_bonnet: {
        canvasHeight: 500,
        canvasWidth: 500,
        offsetY: 0,
        initialHeight: 200,
    },
    body_leftSide: {
        canvasHeight: 1000,
        canvasWidth: 1000,
        offsetY: 50,
        initialHeight: 100,
    },
    body_rightSide: {
        canvasHeight: 1000,
        canvasWidth: 1000,
        offsetY: 0,
        initialHeight: 100,
    },
};
