import React, { useState } from 'react';

import { type IMediaSelectItem } from '../../types/select-item';
import { CardWithTick } from '../card-with-tick/card-with-tick';
import { CustomScroll } from '../custom-scroll/custom-scroll';

interface IProps<T> {
    onClick: (variant: IMediaSelectItem<T>) => void;
    currentValue: T;
    variants: Array<IMediaSelectItem<T>>;
}

export const ChooseSingleCard = <T,>({ onClick, currentValue, variants }: IProps<T>) => {
    const [selected, setSelected] = useState<T>(currentValue);

    React.useEffect(() => setSelected(currentValue), [currentValue]);

    return (
        <CustomScroll>
            {variants.map((v) => (
                <CardWithTick
                    key={v.mediaUrl}
                    backgroundUrl={v.bgUrl ?? v.mediaUrl}
                    onClick={() => {
                        onClick(v);
                        setSelected(v.value);
                    }}
                    header={v.label}
                    ticked={selected === v.value}
                />
            ))}
        </CustomScroll>
    );
};
