import React, { createContext, useContext } from 'react';

import { type IBackground } from '../queries/use-get-fon';

interface IBgContextType {
    bg?: IBackground;
    updateBg: (newBg: IBackground) => void;
}

const IdContext = createContext<IBgContextType | undefined>(undefined);

export function useBg(): IBgContextType {
    const context = useContext(IdContext);
    if (!context) {
        throw new Error('useId must be used within an IdProvider');
    }
    return context;
}

interface BgProviderProps {
    children: React.ReactNode;
    bg?: IBackground;
    setBg: (bg: IBackground) => void;
}

export function BgProvider({ children, bg, setBg }: BgProviderProps) {
    const value: IBgContextType = {
        bg,
        updateBg: setBg,
    };

    return <IdContext.Provider value={value}>{children}</IdContext.Provider>;
}
