import React, { createContext, useContext } from 'react';

export interface StepsInterface {
    moveToNextStep: () => void;
    moveToPrevStep: () => void;
    moveToBeginning: () => void;
}

export const StepContext = createContext<StepsInterface | null>(null);

export const StepContextProvider = (props: { children: React.ReactNode; value: StepsInterface }) => {
    const { children, value } = props;

    return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};
export const useStepContext = () => {
    const bridge = useContext(StepContext);
    if (!bridge) {
        throw new Error('useStepContext must be used within a Step.');
    }
    return bridge;
};
