import { useState } from 'react';
import {
    ModalPage,
    ModalPageHeader,
    ModalRoot,
    Paragraph,
    Separator,
    Div,
    Spacing,
    Headline,
    Text,
    MiniInfoCell,
    Title,
    Card,
    Header,
} from '@vkontakte/vkui';
import { Icon20PhoneOutline, Icon20MailOutline, Icon24Dismiss } from '@vkontakte/icons';

import { useRouter } from '@happysanta/router';

import { ABOUT_SALON_MODAL_PAGE } from '../../../../router';
import { type IWithId } from '../../../../types/interfaces';
import { ButtonFullWidth } from '../../../../components/button-full-width/button-full-width';
import { CustomHeader } from '../../../../components/custom-header/custom-header';
import { type ISalonPage } from '../../../../queries/use-get-salon-item';
import { getSanityURL } from '../../../../util/get-sanity-url';

import { useBridge } from '../../../../components/bridge-context-provider/bridge-context-provider';
import { CustomScroll } from '../../../../components/custom-scroll/custom-scroll';
import SalonAddressItem from './salon-address-item/salon-address-item';

import styles from './about-salon-modal.module.css';

interface IProps extends IWithId {
    page?: ISalonPage;
}

export const AboutSalonModal = ({ id, page }: IProps) => {
    const router = useRouter();
    const [longDescription, setLongDescription] = useState(false);
    const schedule = page?.locationSchedule;
    const isShortDescription = page?.description && page.description?.length < 100;
    const { bridgeSend } = useBridge();

    // const subscribeToGroup = (group_id: number) => {
    //     bridge
    //         .send('VKWebAppJoinGroup', {
    //             group_id,
    //         })
    //         .then((data) => {
    //             console.log(data);
    //         })
    //         .catch((error) => {
    //             // Ошибка
    //             console.log(error);
    //         });
    // };

    return (
        <ModalRoot onClose={() => router.popPage()} activeModal={id}>
            <ModalPage hideCloseButton dynamicContentHeight id={ABOUT_SALON_MODAL_PAGE}>
                {page ? (
                    <>
                        <ModalPageHeader
                            style={{
                                textAlign: 'center',
                                position: 'sticky',
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 3,
                                padding: '0 10px',
                            }}
                            after={<Icon24Dismiss onClick={() => router.popPage()} />}
                        >
                            <CustomHeader>О месте</CustomHeader>
                        </ModalPageHeader>
                        <Separator />
                        <Div>
                            <Title style={{ paddingBottom: 15 }} level="3">
                                Описание
                            </Title>
                            {isShortDescription ? (
                                <Paragraph weight="3">{page.description}</Paragraph>
                            ) : (
                                <Paragraph weight="3">
                                    {longDescription
                                        ? page.description
                                        : (page.description || '')?.slice(0, 100) + '...'}
                                </Paragraph>
                            )}
                            <Spacing size={4} />
                            {!isShortDescription ? (
                                longDescription ? (
                                    <Headline onClick={() => setLongDescription(false)} className={styles.hideSubhead}>
                                        Скрыть
                                    </Headline>
                                ) : (
                                    <Headline onClick={() => setLongDescription(true)} className={styles.hideSubhead}>
                                        Показать ещё
                                    </Headline>
                                )
                            ) : null}
                        </Div>
                        <Spacing size={6}></Spacing>
                        <Separator />
                        {page.ourWorks && (
                            <>
                                <Header>
                                    <Title level="3">Наши работы</Title>
                                </Header>
                                <CustomScroll
                                    style={{
                                        marginTop: 12,
                                        marginBottom: 28,
                                    }}
                                    showArrows={true}
                                >
                                    {page.ourWorks.map((item, idx) => {
                                        return (
                                            <Card
                                                key={idx}
                                                style={{
                                                    backgroundImage: `url(${getSanityURL(item.image)})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    marginRight: 5,
                                                    marginLeft: idx === 0 ? 15 : 5,
                                                    width: 150,
                                                }}
                                                onClick={() => {
                                                    const url = getSanityURL(item.image);

                                                    if (url) {
                                                        bridgeSend('VKWebAppShowImages', {
                                                            images: [url.toString()],
                                                        }).then();
                                                    }
                                                }}
                                            >
                                                <div style={{ paddingBottom: '66%' }} />
                                            </Card>
                                        );
                                    })}
                                </CustomScroll>
                            </>
                        )}
                        <Separator />
                        {page.contacts && (
                            <Div>
                                <Title className={styles.addresessHeader} level="3">
                                    Адрес {schedule && <div className={styles.addresessCount}>{schedule.length}</div>}
                                </Title>
                                {schedule ? (
                                    schedule.map((address, index) => (
                                        <SalonAddressItem
                                            key={index}
                                            addressItem={address}
                                            withBorderBottom={index !== schedule.length - 1}
                                        />
                                    ))
                                ) : (
                                    <div>
                                        <Text weight="3">
                                            {page.contacts.workDays.from}-{page.contacts.workDays.to}: с{' '}
                                            {page.contacts.workTime.from} до {page.contacts.workTime.to}
                                        </Text>
                                        <Text weight="3">{page.address}</Text>{' '}
                                    </div>
                                )}
                            </Div>
                        )}
                        <Separator />
                        {page.contacts && (
                            <>
                                <Div>
                                    <Title level="3">Контакты</Title>
                                </Div>
                                <MiniInfoCell mode="accent" before={<Icon20PhoneOutline />}>
                                    {page.contacts.telephone}
                                </MiniInfoCell>
                                <MiniInfoCell mode="accent" before={<Icon20MailOutline />}>
                                    {page.contacts.email}
                                </MiniInfoCell>
                                <Spacing size={16} />
                                <Div>
                                    <ButtonFullWidth
                                        height={44}
                                        target="_blank"
                                        href={`https://vk.com/write-${page.contacts.vkId}`}
                                        as="a"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Title weight="2" level="3">
                                            Связаться с нами
                                        </Title>
                                    </ButtonFullWidth>
                                </Div>
                            </>
                        )}
                    </>
                ) : null}
            </ModalPage>
        </ModalRoot>
    );
};
