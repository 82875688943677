import { type MeshStandardMaterial } from 'three';

import { createMaterialMutationEventHandler } from '../create-material-mutation-event-handler';
import { type BasicEvent } from '../basic-event';
import { type BodyEventTypes } from './event-types';
import { ColorChangeEvent } from './color-change-event';

const setMaterials = (material: MeshStandardMaterial, event: BasicEvent<BodyEventTypes>) => {
    if (event instanceof ColorChangeEvent) {
        // Switch material name.
        switch (material.name) {
            case 'interior':
                material.color.setStyle(event.color);
                break;
            default:
        }
    }
};

export const changeInteriorEventHandler = createMaterialMutationEventHandler(setMaterials);
