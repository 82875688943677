import { Vector2 } from 'three';

import { type TDetailName } from '../queries/use-get-stickers';
import { stickerConfiguration } from '../constants/sticker-configuration';

export const fitImageToCanvas = (
    name: TDetailName,
    image: any,
    canvas: HTMLCanvasElement,
    movement: Vector2,
    scale: number
) => {
    const { canvasHeight, canvasWidth, offsetY, initialHeight } = stickerConfiguration[name];
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const canvasContext = canvas.getContext('2d');
    const height = initialHeight * scale;
    const ratio = image.width / image.height;
    const width = height * ratio;

    if (name === 'body_rightSide') {
        canvasContext?.scale(-1, -1);
    } else {
        canvasContext?.scale(1, -1);
    }

    // Почему-то canvas переворачивает изображение. Этот отступ флипает его обратно.
    // offsetY - начальный отступ для leftSide
    const deltaY = -(canvas.height - height) / 2 - height - movement.y + (offsetY || 0);
    // Для правой стороны флипаем по горизонтали, чтобы руками не зеркалить картинки.
    const deltaX =
        name === 'body_rightSide'
            ? -(canvas.width - width) / 2 - width - movement?.x
            : (canvas.width - width) / 2 + movement?.x;
    canvasContext?.drawImage(image, deltaX, deltaY, width, height);
};

export const createCanvas = (name: TDetailName, image: any, movement?: Vector2, scale?: number) => {
    const canvas = document.createElement('canvas');
    fitImageToCanvas(name, image, canvas, movement || new Vector2(0, 0), scale || 1);
    return canvas;
};
