import axios from 'axios';

import { getConfigurationInfo } from './util/get-configuration-info';

const { sign, values } = getConfigurationInfo();
const axiosInstance = axios.create({
    headers: {
        Sign: sign,
        Authorization: values,
    },
});

export default axiosInstance;
