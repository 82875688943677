import React, { createContext, useContext } from 'react';
import bridge, { type RequestIdProp, type RequestProps, type RequestPropsMap } from '@vkontakte/vk-bridge';

import { useGetSetAccessToken } from '../access-token-provider/access-token-provider';

export type BridgeSendInterface<Response> = (
    method: keyof RequestPropsMap,
    options?: RequestProps<keyof RequestPropsMap> & RequestIdProp,
) => Promise<Response | null>;

const BridgeContext = createContext<{ bridgeSend: BridgeSendInterface<any> } | null>(null);

bridge.send('VKWebAppInit', {});
bridge.subscribe((e) => {
    if (e.detail.type === 'VKWebAppViewRestore') {
        bridge.send('VKWebAppSetViewSettings', {
            status_bar_style: 'dark',
        });
    }
});

interface BridgeContextProviderInterface {
    children: React.ReactNode;
}

export const BridgeContextProvider = ({ children }: BridgeContextProviderInterface) => {
    const setAccessToken = useGetSetAccessToken();

    const bridgeSend = React.useCallback(
        function <Response>(
            method: keyof RequestPropsMap,
            options?: RequestProps<keyof RequestPropsMap> & RequestIdProp,
        ): Promise<Response | null> {
            return new Promise((resolve) => {
                bridge
                    .send(method, options)
                    .then((result) => resolve(result as Response))
                    .catch((err) => {
                        console.error(err);
                        setAccessToken('');
                        resolve(null);
                    });
            });
        },
        [setAccessToken],
    );

    return <BridgeContext.Provider value={{ bridgeSend }}>{children}</BridgeContext.Provider>;
};

export const useBridge = () => {
    const bridge = useContext(BridgeContext);
    if (!bridge) {
        throw new Error('useBridge must be used within a BridgeProvider.');
    }
    return bridge;
};
