import { ModalPageHeader, PanelHeaderButton } from '@vkontakte/vkui';

import { ColorPicker } from '../../../../components/color-picker/color-picker';
import { CustomHeader } from '../../../../components/custom-header/custom-header';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { useSetModalHeight } from '../../../../hooks/use-set-modal-height';
import {
    type TNamePearl,
    type TNamePartOfCar,
} from '../../../../engine/events/body-events-handlers/color-change-event';
import { getColorAccordingToCategory, changeColorFn, type TCurrentValue } from './utils';

export const PickColorModal: React.FC<{
    setIsPalette: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentValue: (v: Partial<TCurrentValue>) => void;
    currentValue: TCurrentValue;
    selected: TNamePartOfCar;
    pearlObject: (currentValue: TCurrentValue, selected: TNamePartOfCar) => TNamePearl;
}> = ({ setIsPalette, setCurrentValue, currentValue, selected, pearlObject }) => {
    const vehicle = useDetailsContext().vehicle;
    const color = getColorAccordingToCategory(currentValue)[selected];

    useSetModalHeight();

    return (
        <div>
            <ModalPageHeader
                after={
                    <PanelHeaderButton
                        onClick={() => {
                            setIsPalette(false);
                        }}
                    >
                        Готово
                    </PanelHeaderButton>
                }
                style={{ textAlign: 'center' }}
            >
                <CustomHeader>Выберите цвет</CustomHeader>
            </ModalPageHeader>
            <div style={{ width: '100%' }}>
                <ColorPicker
                    disableAlpha
                    width="100%"
                    onChange={(color, event) => {
                        if (vehicle.current) {
                            changeColorFn(
                                selected,
                                setCurrentValue,
                                color.hex,
                                vehicle.current,
                                pearlObject(currentValue, selected),
                            );
                            event.stopPropagation();
                        }
                    }}
                    color={color}
                />
            </div>
        </div>
    );
};
