import { useQuery } from 'react-query';

import { BASE_API } from '../constants/base-api';
import { QUERY_KEY_IS_CONTEST } from '../constants/query-keys';

export const useIsContest = () => {
    return useQuery(QUERY_KEY_IS_CONTEST, async function getHiddenContest() {
        const response = await fetch(`${BASE_API}/project/iscontest`, {
            method: 'GET',
        });

        const { status } = (await response.json()) as { status: boolean };

        return status;
    });
};
