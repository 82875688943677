import React from 'react';

import { Spacing } from '@vkontakte/vkui';

import { CustomTabsItem } from '../custom-tabs-item/custom-tabs-item';
import { CustomScroll } from '../custom-scroll/custom-scroll';
import { type IWithChildren } from '../../types/interfaces';
import { type ISelectItem } from '../../types/select-item';

interface IProps<T> extends IWithChildren {
    categories: Array<ISelectItem<T>>;
    selected?: T;
    setSelected: React.Dispatch<T>;
}

export const CategoriesSplit = <T,>({ categories, selected, setSelected, children }: IProps<T>) => {
    const selectedIndex = categories.findIndex((c) => c.value === selected);
    return (
        <div>
            <CustomScroll selectedIndex={selectedIndex}>
                {categories.map((category, index) => {
                    const label = category.label;
                    return (
                        <div key={index}>
                            <CustomTabsItem
                                selected={selected === category.value}
                                onClick={() => setSelected(category.value)}
                            >
                                {label}
                            </CustomTabsItem>
                        </div>
                    );
                })}
            </CustomScroll>
            <CustomScroll>{children}</CustomScroll>
            <Spacing size={16} />
        </div>
    );
};
