import { ModalCard, Title, Text, Spacing } from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';
import { Icon56NarrativeOutline } from '@vkontakte/icons';

import { ButtonFullWidth } from 'components/button-full-width/button-full-width';
import { type IWithId } from 'types/interfaces';

import styles from './battle-welcome-modal.module.css';

export const BattleWelcomeModal = ({ id }: IWithId) => {
    const router = useRouter();

    return (
        <ModalCard id={id}>
            <div className={styles.flexContainer}>
                <Spacing size={28} />
                <Icon56NarrativeOutline fill="#2688EB" />
                <Spacing size={17} />
                <Title level="2">Добро пожаловать на баттл!</Title>
                <Spacing size={8} />
                <Text className={styles.text}>
                    Здесь мы выбираем лучшие кастомы. Просто нажимай на понравившуюся машину, чтобы проголосовать
                </Text>
                <Spacing size={32} />
                <ButtonFullWidth onClick={() => router.popPageIfModal()} height={44}>
                    <Title weight="2" level="3">
                        Начать
                    </Title>
                </ButtonFullWidth>
            </div>
        </ModalCard>
    );
};
