import { useMutation } from 'react-query';

import axios from '../axios-instance';
import { getUserId } from '../util/get-user-id';
import { BASE_API } from '../constants/base-api';

export const useVoteForProject = () => {
    return useMutation(async (id: string) => {
        return await axios.post(`${BASE_API}/voting/project?id=${id}&vk_id=${getUserId()}`);
    });
};
