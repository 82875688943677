export interface ISignInfo {
    values: string;
    sign: string;
}

const VK_PREFIX = 'vk_';
/**
 * Объяснение роли функции: https://dev.vk.com/mini-apps/development/launch-params-sign
 */
export const getConfigurationInfo = (): ISignInfo => {
    const configString = window.location.href;
    const url = new URL(configString);
    const params = url.searchParams;
    const sign = params.get('sign') as string;

    return {
        values: params
            .toString()
            .split('&')
            .filter((p) => p.startsWith(VK_PREFIX))
            .sort()
            .join('&'),
        sign
    };
};

export const getVkAccessTokenSettings = () => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    return params.get('vk_access_token_settings') as string;
};
