import { useMutation, useQueryClient } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';
import { PROJECTS_QUERY_KEY } from '../constants/query-keys';
import { getUserId } from '../util/get-user-id';

export const useDeleteProject = (project_id: number) => {
    const queryClient = useQueryClient();
    const userId = getUserId();


    return useMutation(
        async () => {
            await axios.post(`${BASE_API}/project/deleteproject?id=${project_id}`);
        },
        {
            onSuccess: () => queryClient.invalidateQueries([PROJECTS_QUERY_KEY, userId]),
        }
    );
};
