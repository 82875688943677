import React from 'react';
import { useRouter } from '@happysanta/router';
import { Button, Panel, Placeholder } from '@vkontakte/vkui';

import { Icon56ErrorOutline } from '@vkontakte/icons';

import { PAGE_MAIN } from '../../router';
import { type IWithId } from '../../types/interfaces';

const PanelError = ({ id }: IWithId) => {
    const router = useRouter();
    router.stop();

    const restartApp = () => {
        router.start();
        router.replacePage(PAGE_MAIN);
    };

    return (
        <Panel style={{ height: '100vh' }} id={id} centered>
            <Placeholder
                icon={<Icon56ErrorOutline />}
                header="Что-то пошло не так..."
                action={
                    <Button size="m" onClick={restartApp}>
                        Перезагрузить приложение
                    </Button>
                }
                stretched
            >
                Пожалуйста, перезагрузите приложение
            </Placeholder>
        </Panel>
    );
};

export default PanelError;
