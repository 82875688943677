import React, { useEffect } from 'react';
import { HorizontalCell, Image, Spinner, useModalRootContext } from '@vkontakte/vkui';
import { useQueryClient } from 'react-query';

import { TuningHeader } from '../tuning-header';
import { CustomScroll } from '../../../../components/custom-scroll/custom-scroll';
import { useBg } from '../../../../hooks/use-bg';
import { type IBackground } from '../../../../queries/use-get-fon';
import { BASE_API } from '../../../../constants/base-api';

import '../styles.css';

export const EditTuningEnv = () => {
    const { updateBg } = useBg();
    const { updateModalHeight } = useModalRootContext();
    const queryClient = useQueryClient();

    const data = queryClient.getQueryData<IBackground[]>('fons');

    useEffect(() => {
        updateModalHeight();
    }, [data]);

    const handleBackgroundReset = React.useCallback(() => {
        if (!data) return;
        updateBg(data[0]);
    }, [data]);

    return !data ? (
        <>
            <Spinner />
        </>
    ) : (
        <div>
            <TuningHeader children="Выберите фон" onReset={handleBackgroundReset} />
            <CustomScroll>
                {data.map((bg) => (
                    <HorizontalCell
                        key={bg.id}
                        onClick={() => {
                            updateBg(bg);
                        }}
                        header={<div style={{ whiteSpace: 'nowrap' }}>{bg.name}</div>}
                    >
                        <Image size={64} src={`${BASE_API}${bg.image}`} />
                    </HorizontalCell>
                ))}
            </CustomScroll>
        </div>
    );
};
