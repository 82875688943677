import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { getUserId } from '../util/get-user-id';
import { BASE_API } from '../constants/base-api';

export const usePersonRating = () => {
    const vkId = getUserId();
    return useQuery(['person_rating', vkId], async () => {
        const res = await axios.get<{ rating: number }>(`${BASE_API}/voting/personrating?vk_id=${vkId}`);
        return res.data.rating;
    });
};
