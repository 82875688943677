import React, { useState, useRef, useEffect, Children, type CSSProperties } from 'react';
import cn from 'classnames';
import { Icon24ChevronLeft, Icon24ChevronRight } from '@vkontakte/icons';
import { IconButton } from '@vkontakte/vkui';

import { type IWithChildren } from '../../types/interfaces';
import './custom-scroll.css';

interface IProps extends IWithChildren {
    style?: CSSProperties;
    showArrows?: boolean;
    selectedIndex?: number | string;
}

export const CustomScroll = ({ children, style = {}, showArrows = true, selectedIndex }: IProps) => {
    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
    const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollToRef = useRef<HTMLDivElement>(null);

    const [initialMousePosition, setInitialMousePosition] = React.useState<number | null>(null);
    const [currentMousePosition, setCurrentMousePosition] = React.useState(0);

    const handleScrollStop = React.useCallback(() => {
        setInitialMousePosition(null);
        setCurrentMousePosition(0);
    }, []);

    React.useEffect(() => {
        if (!initialMousePosition) return;
        window.addEventListener('mouseup', handleScrollStop);
        window.addEventListener('touchend', handleScrollStop);
        window.addEventListener('mouseleave', handleScrollStop);
        return () => {
            window.removeEventListener('mouseup', handleScrollStop);
            window.removeEventListener('touchend', handleScrollStop);
            window.removeEventListener('mouseleave', handleScrollStop);
        };
    }, [initialMousePosition]);

    const handleMouseDown = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        setInitialMousePosition(event.clientX);
        setCurrentMousePosition(0);
    }, []);

    const handleTouchStart = React.useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        event.preventDefault();
        setInitialMousePosition(event.touches[0].clientX);
        setCurrentMousePosition(0);
    }, []);

    const checkForScrollPosition = () => {
        const { current } = containerRef;
        if (current) {
            const { scrollLeft, scrollWidth, clientWidth } = current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(Math.floor(scrollWidth - scrollLeft - clientWidth) > 0);
        }
    };

    const debounceCheckForScrollPosition = checkForScrollPosition;

    const scrollContainerBy = (scrollToRight: boolean) => {
        if (!containerRef.current) return;
        const scrollValue = Math.round(containerRef.current.clientWidth * 0.75);
        containerRef.current?.scrollBy({
            left: scrollToRight ? scrollValue : -scrollValue,
            behavior: 'smooth',
        });
    };

    const handleScrollMove = React.useCallback(
        (value: number) => {
            if (!initialMousePosition || !containerRef.current) return;
            const mousePositionDelta = value - initialMousePosition;
            const newCurrentMousePosition = currentMousePosition + mousePositionDelta;

            if (Math.abs(mousePositionDelta) < 50) {
                setCurrentMousePosition(newCurrentMousePosition);
                return;
            }

            scrollContainerBy(mousePositionDelta < 0);
            handleScrollStop();
        },
        [initialMousePosition, currentMousePosition, handleScrollStop],
    );

    const handleMouseMove = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleScrollMove(event.clientX),
        [handleScrollMove],
    );

    const handleTouchMove = React.useCallback(
        (event: React.TouchEvent<HTMLDivElement>) => handleScrollMove(event.touches[0].clientX),
        [handleScrollMove],
    );

    useEffect(() => {
        const { current } = containerRef;
        checkForScrollPosition();
        current?.addEventListener('scroll', debounceCheckForScrollPosition);

        return () => {
            current?.removeEventListener('scroll', debounceCheckForScrollPosition);
        };
    }, [children]);

    useEffect(() => {
        if (containerRef.current) {
            if (selectedIndex) {
                const position = scrollToRef.current?.offsetLeft || 0;
                containerRef.current.scrollTo({ left: position - 60 });
            } else {
                containerRef.current.scrollTo({ left: 0 });
            }
        }
    }, [selectedIndex]);

    const childrenArray = Children.toArray(children);
    
    return (
        <div
            onTouchStart={handleTouchStart}
            onMouseDown={handleMouseDown}
            onTouchMove={handleTouchMove}
            onMouseMove={handleMouseMove}
            className="scrollableContainer"
        >
            <div style={style} className="list" ref={containerRef}>
                {childrenArray.map((child: any, index) => {
                    const uniqId = +child.key.replace('.', '').replace('$', '');
                    return (<div ref={selectedIndex === uniqId ? scrollToRef : null} className="item" key={index}>
                        {child}
                    </div>)
                })}
            </div>
            {showArrows && (
                <IconButton
                    aria-label='buttonLeft'
                    disabled={!canScrollLeft}
                    onClick={() => scrollContainerBy(false)}
                    className={cn('button', 'buttonLeft', {
                        'button--hidden': !canScrollLeft,
                    })}
                >
                    <Icon24ChevronLeft />
                </IconButton>
            )}
            {showArrows && (
                <IconButton
                    aria-label='buttonRight'
                    disabled={!canScrollRight}
                    onClick={() => scrollContainerBy(true)}
                    className={cn('button', 'buttonRight', {
                        'button--hidden': !canScrollRight,
                    })}
                >
                    <Icon24ChevronRight />
                </IconButton>
            )}
        </div>
    );
};
