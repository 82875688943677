import { type MeshStandardMaterial, type Object3D } from 'three';

import { BasicEvent } from '../basic-event';
import { BodyEventTypes } from './event-types';
import { createMaterialMutationEventHandler } from '../create-material-mutation-event-handler';

const setMaterials = (material: MeshStandardMaterial, event: BasicEvent<BodyEventTypes>) => {
    if (event instanceof ChangeGlassToneEvent) {
        if (material.name !== event.targetMaterialName) return;
        // Switch material name.
        switch (material.name) {
            case 'glass':
            case 'glass_light':
                material.metalness = 1;
                material.roughness = 0;
                if (event.tone) {
                    material.opacity = 0.5;
                    material.color.setStyle(event.tone);
                    material.emissive.setStyle(event.tone);
                    material.emissiveIntensity = 0.2;
                } else {
                    material.opacity = 0.1;
                    material.color.setStyle('#464545');
                    material.emissive.setStyle('#000');
                    material.emissiveIntensity = 1;
                }
        }
    }
};

export class ChangeGlassToneEvent extends BasicEvent<BodyEventTypes> {
    private _tone?: string;
    private _targetMaterialName: 'glass' | 'glass_light';

    constructor(target: Object3D, targetMaterialName: 'glass' | 'glass_light', tone?: string) {
        super(BodyEventTypes.GLASS_TONE_CHANGE, target);
        this._tone = tone;
        this._targetMaterialName = targetMaterialName;
    }

    get tone(): string | undefined {
        return this._tone;
    }

    set tone(value: string | undefined) {
        this._tone = value;
    }

    get targetMaterialName(): 'glass' | 'glass_light' {
        return this._targetMaterialName;
    }

    set targetMaterialName(value: 'glass' | 'glass_light') {
        this._targetMaterialName = value;
    }
}

export const changeGlassTone = createMaterialMutationEventHandler(setMaterials);
