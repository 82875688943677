import React from 'react';
import { Icon24Dismiss, Icon24InfoCircleOutline } from '@vkontakte/icons';
import { Button, CellButton, IconButton, ModalPage, ModalPageHeader, SimpleCell } from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';

import { useUsedSalonIdsLayer } from '../../../../components/advertisement-provider/advertisement-provider';
import { PAGE_SALON_ITEM } from '../../../../router';
import { type IWithId } from '../../../../types/interfaces';

import styles from './basket-modal.module.css';

interface IProps extends IWithId {}

export const BasketModal = ({ id }: IProps) => {
    const router = useRouter();
    const { usedSalons } = useUsedSalonIdsLayer();
    const [basketBanner, setBasketBanner] = React.useState(false);

    React.useLayoutEffect(() => {
        const banner = localStorage.getItem('basketBanner');
        if (banner) return;
        setBasketBanner(true);
    }, []);

    const handleBannerClose = React.useCallback(() => {
        localStorage.setItem('basketBanner', '1');
        setBasketBanner(false);
    }, []);

    const handleSalonOpen = React.useCallback((salonId: string) => {
        router.pushPage(PAGE_SALON_ITEM, { id: salonId });
    }, []);

    const handleCloseModal = React.useCallback(() => {
        router.popPageIfModal();
    }, []);

    return (
        <ModalPage
            header={
                <ModalPageHeader
                    after={
                        <IconButton onClick={handleCloseModal}>
                            <Icon24Dismiss />
                        </IconButton>
                    }
                >
                    Доступные услуги
                </ModalPageHeader>
            }
            id={id}
        >
            <div style={{ paddingBottom: '24px' }}>
                {basketBanner ? (
                    <div className={styles.infoContainer}>
                        <Icon24InfoCircleOutline fill="#2688EB" />
                        <div className={styles.textContainer}>
                            Корзина собрана из того, что вы настроили в кастомайзере. Выберите салон, в котором хотите
                            заказать услуги.
                            <Button onClick={handleBannerClose}>Понятно</Button>
                        </div>
                    </div>
                ) : null}
                {usedSalons.map((salon) => (
                    <div key={salon.id}>
                        <SimpleCell
                            onClick={() => handleSalonOpen(salon.id)}
                            after={<CellButton onClick={() => handleSalonOpen(salon.id)}>Заказать здесь</CellButton>}
                            subtitle={salon.address}
                        >
                            {salon.name}
                        </SimpleCell>
                    </div>
                ))}
            </div>
        </ModalPage>
    );
};
