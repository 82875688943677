import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Placeholder, ScreenSpinner, Spacing, useModalRootContext } from '@vkontakte/vkui';
import { Icon56BlockOutline, Icon24CancelCircleFillRed } from '@vkontakte/icons';
import uniq from 'lodash/uniq';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { MODAL_EDIT_BODY_KIT } from '../../../../router';
import { TuningHeader } from '../tuning-header';
import { CardWithTick } from '../../../../components/card-with-tick/card-with-tick';
import { type IAddon } from '../../../../types/tuning-model';
import { useGetAddons } from '../../../../queries/use-get-addons';
import { type IModelData } from '../../../../queries/use-GLTF-urls';
import { MODEL_URLS } from '../../../../constants/query-keys';
import { BASE_API } from '../../../../constants/base-api';
import { useMakeCameraTransitions } from '../../../../engine/hooks/use-make-camera-transitions';
import { CustomScroll } from '../../../../components/custom-scroll/custom-scroll';
import { CustomTabsItem } from '../../../../components/custom-tabs-item/custom-tabs-item';

const mapDetailNameToLabel: Record<IAddon['type'], string> = {
    spoiler: 'Спойлер',
    front_bumper: 'Передний бампер',
    back_bumper: 'Задний бампер',
    muffler: 'Глушитель',
    side_bumper: 'Боковой обвес',
    number_plate: 'Номера',
};

export const EditBodyKitModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const { data: addons = [], isLoading } = useGetAddons();
    const { lookAtBackBumper, lookAtSpoiler, lookAtFrontBumper, lookAtSideBumper, lookAtMuffler } =
        useMakeCameraTransitions();
    const queryClient = useQueryClient();
    const { updateModalHeight } = useModalRootContext();
    const { cars } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};

    const lookAtObject: Record<IAddon['type'], VoidFunction> = useMemo(
        () => ({
            back_bumper: lookAtBackBumper,
            spoiler: lookAtSpoiler,
            front_bumper: lookAtFrontBumper,
            side_bumper: lookAtSideBumper,
            muffler: lookAtMuffler,
            number_plate: () => {},
        }),
        [lookAtSpoiler, lookAtBackBumper, lookAtFrontBumper, lookAtSideBumper, lookAtMuffler, lookAtMuffler],
    );

    const currentCar = cars?.find((c) => c.model_id === currentValue.id);
    const carPermissions: Record<IAddon['type'], boolean | undefined> = useMemo(
        () => ({
            back_bumper: currentCar?.is_back_bumper !== false,
            spoiler: currentCar?.is_spoiler,
            front_bumper: currentCar?.is_obves,
            side_bumper: true,
            muffler: true,
            number_plate: false,
        }),
        [currentCar],
    );
    const categories = uniq(addons?.map((a) => a.type))
        .filter((c) => carPermissions[c])
        .filter(
            (type) =>
                addons
                    .filter((addon) => addon.type === type)
                    .filter((a) => {
                        const isTuningDetailForbidden =
                            currentCar?.config?.addonsPosition?.[a.name] === false ||
                            currentCar?.config?.addonsPosition?.[a.type] === false;
                        return !isTuningDetailForbidden;
                    }).length > 0,
        );
    const [selected, setSelected] = useState<IAddon['type']>(categories[0]);

    const addAddon = (addon: IAddon) => {
        const filteredAddons = currentValue.addons.filter((addons) => addons.type !== addon.type);

        setCurrentValue({ addons: [...filteredAddons, addon] });
    };

    const removeAddon = (addon: IAddon) => {
        setCurrentValue({
            addons: [...currentValue.addons.filter((curAdd) => curAdd.name !== addon.name)],
        });
    };

    useEffect(() => {
        updateModalHeight();
    });

    useEffect(() => {
        if (selected) {
            lookAtObject[selected]();
        }
    }, [selected, lookAtObject]);

    if (isLoading || !cars) {
        return (
            <>
                <TuningHeader>Выберите обвес</TuningHeader>
                <Spacing size={80} />
                <ScreenSpinner />
                <Spacing size={80} />
            </>
        );
    }

    return (
        <div>
            <Spacing size={10} />
            <TuningHeader promotion={selected === 'muffler' ? 'muffler' : MODAL_EDIT_BODY_KIT}>
                Выберите обвес
            </TuningHeader>
            {categories.length ? (
                <>
                    <CustomScroll>
                        {categories.map((category, index) => (
                            <div key={index}>
                                <CustomTabsItem
                                    selected={selected === category}
                                    onClick={() => {
                                        setSelected(category);
                                    }}
                                >
                                    {mapDetailNameToLabel?.[category] || category}
                                </CustomTabsItem>
                            </div>
                        ))}
                    </CustomScroll>
                    <CustomScroll>
                        {addons
                            .filter((addon) => addon.type === selected)
                            ?.map((addon, index) => {
                                const { name, image } = addon;
                                const isTuningDetailForbidden =
                                    currentCar?.config?.addonsPosition?.[addon.name] === false ||
                                    currentCar?.config?.addonsPosition?.[addon.type] === false;
                                const ticked = currentValue.addons.map((a) => a.name).includes(name);
                                return (
                                    <>
                                        {!isTuningDetailForbidden && (
                                            <div style={{ position: 'relative' }} key={index}>
                                                {ticked && (
                                                    <Icon24CancelCircleFillRed
                                                        onClick={() => removeAddon(addon)}
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: 73,
                                                            left: 1,
                                                            zIndex: 80,
                                                        }}
                                                    />
                                                )}
                                                <CardWithTick
                                                    backgroundUrl={`${BASE_API}${image}`}
                                                    header={name}
                                                    onClick={() => addAddon(addon)}
                                                    ticked={ticked}
                                                />
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                    </CustomScroll>
                    <Spacing size={10} />
                </>
            ) : (
                <Placeholder icon={<Icon56BlockOutline />} header="Для этой машины нет обвесов">
                    Выберите другую
                </Placeholder>
            )}
        </div>
    );
};
