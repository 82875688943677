import React, { createContext, useContext, useState } from 'react';

import { type ISalonItem } from '../../queries/use-search-salons';

interface IAdvertisementContextValue {
    usedSalons: ISalonItem[];
    setUsedSalons: React.Dispatch<React.SetStateAction<ISalonItem[]>>;
    advertisementSnackBar: React.ReactNode;
    setAdvertisementSnackBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    usedService?: string;
    setUsedService: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const AdvertisementContext = createContext<IAdvertisementContextValue | null>(null);

export const AdvertisementProvider = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const [usedSalons, setUsedSalons] = useState<ISalonItem[]>([]);
    const [advertisementSnackBar, setAdvertisementSnackBar] = useState<React.ReactNode>(null);
    const [usedService, setUsedService] = useState<string>();

    return (
        <AdvertisementContext.Provider
            value={{
                usedSalons,
                setUsedSalons,
                advertisementSnackBar,
                setAdvertisementSnackBar,
                usedService,
                setUsedService,
            }}
        >
            {children}
        </AdvertisementContext.Provider>
    );
};

export const useUsedSalonIdsLayer = () => {
    const value = useContext(AdvertisementContext);
    if (value === null) {
        throw new Error('useUsedSalonIdsLayer must be used within a AdvertisementProvider.');
    }
    const { usedSalons, setUsedSalons } = value;
    return {
        usedSalons,
        setUsedSalons,
    };
};

export const useAdsSnackbarLayer = () => {
    const value = useContext(AdvertisementContext);
    if (value === null) {
        throw new Error('useAdsSnackbarLayer must be used within a AdvertisementProvider.');
    }
    const { advertisementSnackBar, setAdvertisementSnackBar } = value;
    return {
        advertisementSnackBar,
        setAdvertisementSnackBar,
    };
};

export const useUsedService = () => {
    const value = useContext(AdvertisementContext);
    if (value === null) {
        throw new Error('useUsedService must be used within a AdvertisementProvider.');
    }
    const { usedService, setUsedService } = value;
    return {
        usedService,
        setUsedService,
    };
};
