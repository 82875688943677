import React, { createContext, type RefObject, useContext } from 'react';

import { type Group, type Vector2 } from 'three';

import { type IStickerItem, type TDetailName } from '../../queries/use-get-stickers';
import { type IStickerModel } from '../../types/tuning-model';

export interface IDetailsContextValue {
    removeDetail: (name: TDetailName) => void;
    moveAndScaleSticker: (sticker: IStickerModel, deltaMovement?: Vector2, scale?: number) => void;
    addNewDetail: (sticker: IStickerItem & { color?: string }) => void;
    changeDetailColor: (sticker: IStickerItem & { color?: string }, color: string) => void;
    clearDetails: () => void;
    children: React.ReactNode;
    vehicle: RefObject<Group>;
    wheelsRef: RefObject<Group>;
    setVisibleResetButton: (visible: boolean) => void;
}

const DetailsContext = createContext<Omit<IDetailsContextValue, 'children'> | null>(null);

export const DetailsContextProvider = (props: IDetailsContextValue) => {
    const {
        children,
        removeDetail,
        moveAndScaleSticker,
        addNewDetail,
        changeDetailColor,
        clearDetails,
        vehicle,
        setVisibleResetButton,
        wheelsRef,
    } = props;

    return (
        <DetailsContext.Provider
            value={{
                removeDetail,
                clearDetails,
                addNewDetail,
                changeDetailColor,
                vehicle,
                moveAndScaleSticker,
                setVisibleResetButton,
                wheelsRef,
            }}
        >
            {children}
        </DetailsContext.Provider>
    );
};

export const useDetailsContext = () => {
    const bridge = useContext(DetailsContext);
    if (!bridge) {
        throw new Error('useDetailsContext must be used within a DetailsContextProvider.');
    }
    return bridge;
};
