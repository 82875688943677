import { type RefObject, useCallback, useState, type Dispatch } from 'react';
import { CanvasTexture, type Group, type Mesh, MeshBasicMaterial, TextureLoader, type Vector2 } from 'three';

import { type TDetailName } from '../queries/use-get-stickers';
import { BASE_API } from '../constants/base-api';
import { createCanvas } from '../util/create-canvas';
import { type IDefaults, type IStickerModel } from '../types/tuning-model';
import { type IDetailsContextValue } from '../components/details-context-provider/details-context-provider';

/**
 * Хук для конфигурации поведения наклеек.
 * @param currentVehicle - Объект состояние машины
 * @param vehicleRef - Ref с 3D-моделью машины
 * @param setVehicle - Функция для изменения состояния машины
 */
export const useConfigureStickerControls = (
    currentVehicle: IDefaults,
    vehicleRef: RefObject<Group>,
    setVehicle: Dispatch<Partial<IDefaults>>,
): Omit<IDetailsContextValue, 'setVisibleResetButton' | 'children' | 'wheelsRef'> => {
    const [details, setDetails] = useState<Mesh[]>([]);

    // Удаление детали по имени
    const removeDetail = useCallback(
        (name: TDetailName) => {
            if (vehicleRef.current) {
                const remove = details.find((d) => d.name === `sticker_${name}`) as Mesh;
                if (!remove) return;
                const scene = vehicleRef.current.children[0];
                scene.remove(remove);
                setDetails(details.filter((d) => `sticker_${d.name}` !== name));
                const newStickers = currentVehicle.stickers.filter((sticker) => sticker.name !== name);
                setVehicle({ stickers: newStickers });
            }
        },
        [vehicleRef, details, currentVehicle.stickers, setDetails, setVehicle],
    );

    // Перемещение и скалирование стикера, для удобства объединенные в одну функцию.
    const moveAndScaleSticker = useCallback(
        async (sticker: IStickerModel, deltaMovement?: Vector2, scale?: number) => {
            if (vehicleRef.current) {
                const moveDetail = details.find((d) => d.name === `sticker_${sticker.name}`) as Mesh;
                if (!moveDetail) return;
                const canvas = createCanvas(sticker.name, sticker?.texture?.image, deltaMovement, scale);
                const canvasTexture = new CanvasTexture(canvas);
                moveDetail.material = new MeshBasicMaterial({
                    transparent: true,
                    map: canvasTexture,
                    color: sticker.color,
                });
            }
        },
        [vehicleRef, details, currentVehicle.stickers, setDetails, setVehicle],
    );

    // Добавление новой детали
    const addNewDetail = useCallback(
        async (sticker: IStickerModel) => {
            if (vehicleRef.current) {
                const group = vehicleRef.current.children[0];
                const originalDetail = group.children?.find((c) => {
                    return c.name === sticker.name;
                }) as Mesh;
                const detailWithSticker = originalDetail.clone(true);
                detailWithSticker.name = `sticker_${originalDetail.name}`;

                const textureLoader = new TextureLoader();
                const texture = await textureLoader.loadAsync(BASE_API + sticker.sticker);

                const canvas = createCanvas(sticker.name, texture.image);
                const canvasTexture = new CanvasTexture(canvas);
                detailWithSticker.material = new MeshBasicMaterial({
                    transparent: true,
                    map: canvasTexture,
                    color: '#fff',
                });
                group.add(detailWithSticker);

                removeDetail(sticker.name);

                const filteredDetails = details.filter((d) => d.name !== detailWithSticker.name);
                const newStickers = currentVehicle.stickers.filter((s) => s.name !== sticker.name);
                setVehicle({ stickers: [...newStickers, { ...sticker, texture }] });
                setDetails([...filteredDetails, detailWithSticker]);
            }
        },
        [vehicleRef, currentVehicle.stickers, details, setDetails, setVehicle],
    );

    // Удаление всех наклеек
    const clearDetails = useCallback(() => {
        if (vehicleRef.current) {
            const scene = vehicleRef?.current?.children[0];
            details.forEach((d) => scene?.remove(d));
            setDetails([]);
            setVehicle({ stickers: [] });
        }
    }, [details, currentVehicle.stickers, vehicleRef, setDetails, setVehicle]);

    // Изменение цвета наклейки
    const changeDetailColor = useCallback(
        (sticker: IStickerModel, color: string) => {
            const detail = details.find((d) => d.name === `sticker_${sticker.name}`) as Mesh;
            const material = detail.material as MeshBasicMaterial;
            material.color.set(color);
            const filteredStickers = currentVehicle.stickers.filter((s) => sticker.name !== s.name);
            setVehicle({
                stickers: [
                    ...filteredStickers,
                    {
                        ...sticker,
                        color,
                    },
                ],
            });
        },
        [currentVehicle.stickers, setVehicle],
    );

    return {
        changeDetailColor,
        addNewDetail,
        removeDetail,
        clearDetails,
        vehicle: vehicleRef,
        moveAndScaleSticker,
    };
};
