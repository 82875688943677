export const AD_COPYRIGHT = {
    'modal_tuning_color': 'Оклейка доступна в салонах', 
    'modal_tuning_glass_tone': 'Тонировка стекол доступна в салонах', 
    'modal_tuning_material': 'Детейлинг салона доступен в салонах',
    'modal_tuning_rim_material': 'Заказ и доработка дисков доступны в салонах',
    'modal_tuning_pick_rim_model': 'Заказ и доработка дисков доступны в салонах',
    'rim': 'Заказ и доработка дисков доступны в салонах',
    'modal_tuning_pick_rim_color': 'Заказ и доработка дисков доступны в салонах',
    'modal_tuning_pick_rim_size': 'Заказ и доработка дисков доступны в салонах',
    'modal_tuning_pick_rim_accent': 'Заказ и доработка дисков доступны в салонах',
    'modal_tuning_pick_sticker': 'Нанесение наклеек доступно в салонах', 
    'modal_tuning_offset': 'доступно в салонах',
    'modal_tuning_pick_tire_model': 'Установка и ремонт шин доступны в салонах',
    'modal_tuning_pick_tire_size': 'Установка и ремонт шин доступны в салонах',
    'modal_edit_body_kit': 'Кузовные доработки доступны в салонах',
    'muffler': 'Кузовные доработки доступны в салонах',
    'modal_tuning_lift': 'Доработка подвески доступна в салонах'
}