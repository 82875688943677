import { Cell, Headline, Subhead } from '@vkontakte/vkui';
import cn from 'classnames';

import { type Dispatch, useEffect, useState } from 'react';

import { type IService } from '../../queries/use-get-salon-item';

import { parseNumberToStringValue } from '../../util/parse-number-to-value-string';

import styles from './service-decsription-card.module.css';

interface IServiceDescriptionProps {
    name: string;
    fromPrice: boolean;
    price: number;
    description: string;
    duration?: number;
    active?: boolean;
    disabled?: boolean;
    _key: string;
    setOrder: Dispatch<React.SetStateAction<Record<string, IService | null>>>;
    order: Record<string, IService>;
}

export const ServiceDescriptionCard = ({
    name,
    _key,
    fromPrice,
    price,
    description,
    duration,
    active,
    disabled,
    order,
    setOrder,
}: IServiceDescriptionProps) => {
    const [selected, setSelected] = useState(false);

    const createOrder = (service: IService) => {
        setOrder((prevValue: Record<string, IService | null>) => {
            if (prevValue[service._key]) {
                prevValue[service._key] = null;

                const filteredObj = Object.fromEntries(
                    Object.entries(prevValue).filter(([_, value]) => value !== null),
                );

                return { ...filteredObj };
            } else {
                return { ...prevValue, [service._key]: service };
            }
        });
    };

    useEffect(() => {
        setSelected(Object.keys(order).includes(_key));
    }, [order]);

    const orderCollection = { name, _key, fromPrice, price, description, duration };

    return (
        <Cell multiline mode="selectable" checked={selected} onChange={() => createOrder(orderCollection as IService)}>
            <div>
                <div className={styles.serviceDescriptionContainer}>
                    <Headline
                        className={cn(disabled && styles.serviceTextDisabled, active && styles.serviceTextActive)}
                        weight="2"
                        level="1"
                    >
                        {name}
                    </Headline>
                    {!disabled && (
                        <Headline
                            className={cn(styles.servicePrice, active && styles.serviceTextActive)}
                            weight="2"
                            level="2"
                        >
                            {fromPrice && 'от '}
                            {parseNumberToStringValue(price)}
                        </Headline>
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    <Subhead className={cn(styles.subHead, disabled && styles.serviceTextDisabled)}>
                        {description}
                    </Subhead>
                    {!disabled && (
                        <Subhead className={cn(styles.serviceDuration, styles.subHead)}>
                            {duration ? `${duration} часа ` : null}
                        </Subhead>
                    )}
                </div>
            </div>
        </Cell>
    );
};
