import React, { Suspense, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { DefaultLoadingManager } from 'three';
import { ScreenSpinner } from '@vkontakte/vkui';

import Vehicle from './vehicle';
import Screenshot from './screenshot';
import { useBg } from '../../hooks/use-bg';
import { VolumetricEnvironment } from './volumetric-environment';
import { CAMERA_INITIAL_POSITION } from '../../constants/viewer-initial-config';
import { useViewerOptionsContext } from '../../components/three-context/viewer-options-context-provider';
import { CameraLookAt } from '../../components/camera-look-at/camera-look-at';

interface IProps {
    currentVehicle: any;
    setVehicle: React.Dispatch<any>;
    cameraAutoRotate: boolean;
    setCanvasLoaded: (canvasLoaded: boolean) => void;
}

const ThreeCanvas = ({ currentVehicle, setVehicle, cameraAutoRotate, setCanvasLoaded }: IProps) => {
    const { controlsEnabled, viewerTarget } = useViewerOptionsContext();
    const { bg } = useBg();

    const { cameraRef } = useViewerOptionsContext();
    const panelHeaderHeight = getComputedStyle(document.documentElement).getPropertyValue(
        '--vkui--size_panel_header_height--regular',
    );
    const internalTabbarHeight = getComputedStyle(document.documentElement).getPropertyValue(
        '--vkui_internal--tabbar_height',
    );

    useEffect(() => {
        const loadingManager = DefaultLoadingManager;
        loadingManager.onStart = () => {
            setCanvasLoaded(false);
        };
        loadingManager.onLoad = () => {
            setCanvasLoaded(true);
        };

        return () => {
            loadingManager.onStart = undefined;
            loadingManager.onLoad = () => {};
        };
    }, []);

    return (
        <div>
            <Suspense fallback={<ScreenSpinner />}>
                <Canvas style={{ height: `calc(100vh - ${panelHeaderHeight} - ${internalTabbarHeight})` }} shadows>
                    <OrbitControls
                        makeDefault
                        enabled={controlsEnabled}
                        minDistance={6}
                        maxDistance={16}
                        maxPolarAngle={Math.PI / 2}
                        autoRotate={cameraAutoRotate}
                        autoRotateSpeed={-0.3}
                        dampingFactor={0.025}
                        enablePan={false}
                    />

                    <PerspectiveCamera
                        ref={cameraRef}
                        castShadow={true}
                        makeDefault
                        fov={30}
                        position={CAMERA_INITIAL_POSITION}
                    />
                    <CameraLookAt lookAtPosition={viewerTarget} />
                    <directionalLight castShadow={true} position={[0, 5, 2]} intensity={0.5} color={0xffffff} />
                    <ambientLight position={[0, 0, 0]} intensity={0.5} color={0xffffff} />

                    <Vehicle currentVehicle={currentVehicle} />
                    {bg ? <VolumetricEnvironment bg={bg} /> : null}

                    <Screenshot setVehicle={setVehicle} />
                </Canvas>
            </Suspense>
        </div>
    );
};

export default ThreeCanvas;
