import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';

export interface IBackground {
    id: number;
    file?: string;
    image?: string;
    hdr_file?: string;
    name: string;
}
export const useGetFon = () => {
    return useQuery(['fons'], async () => {
        const response = await axios.get<IBackground[]>(`${BASE_API}/project/getfon`);
        return response.data;
    });
};
