import { Environment, useGLTF } from '@react-three/drei';
import { FrontSide, Material, Mesh } from 'three';
import React, { useEffect } from 'react';

import { type IBackground } from '../../queries/use-get-fon';
import { BASE_API } from '../../constants/base-api';

export const VolumetricEnvironment = ({ bg }: { bg: IBackground }) => {
    const model = useGLTF(`${BASE_API}${bg.file}`);
    useEffect(() => {
        model.scene.visible = true;
        model.scene.receiveShadow = true;
        model.scene.traverseVisible((child) => {
            if (child instanceof Mesh) {
                const material = child.material;
                child.receiveShadow = true;
                if (material instanceof Material) {
                    material.side = FrontSide;
                } else {
                    material.forEach((m: Material) => {
                        m.side = FrontSide;
                    });
                }
            }
        });
    }, [model]);
    return (
        <>
            <Environment background files={`${BASE_API}${bg.hdr_file}`} />
            <primitive object={model.scene} />
        </>
    );
};
