export function parseNumberToStringValue(number: number) {
    if (!number) {
        return null;
    }
    const stringNumber = number.toString();
    let result = '';

    for (let i = stringNumber.length - 1; i >= 0; i--) {
        const needDivider = (stringNumber.length - i) % 3 === 0;
        result = (needDivider ? ' ' : '') + stringNumber[i] + result;
    }

    return result + '₽';
}
