import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder({
    projectId: 'obfsgn0y',
    dataset: 'production',
});

function urlFor(source: string) {
    return builder.image(source);
}

export const getSanityURL = (imageId?: string) => {
    if (!imageId) {
        return '';
    }

    return urlFor(imageId).toString();
};
