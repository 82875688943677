import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';

import { useGetAllProjects } from '../queries/use-get-all-projects';
import { BASE_API } from '../constants/base-api';
import { getUserId } from '../util/get-user-id';

export function useMakeOrder(salonId: string) {
    const [order, setOrder] = useState({});
    const [orderId, setOrderId] = useState('');
    const vk_id = getUserId();

    useEffect(() => {
        setOrderId(() => {
            const id = [...Array(12)].map(() => Math.random().toString(36)[2]).join('');

            return id;
        });
    }, [order]);

    const { data = [] } = useGetAllProjects(getUserId());

    const makeOrder = useCallback(() => {
        const services = Object.values(order);
        const screenshots = data?.sort(function (a, b) {
            return b.id - a.id;
        });
        const screenshot = screenshots[0]?.image;
        const text = `${services
            .map((s: any) => {
                const description = s.description || null;
                const finalDescription = description ? ` (${description})` : '';

                return `— ${s.name}${finalDescription}`;
            })
            .join('\n')}${screenshot ? `\nСкриншот проекта: ${BASE_API}/${screenshot}` : ``}`;

        if (!text || !salonId || !vk_id || !orderId) {
            return false;
        }

        axios({
            method: 'post',
            url: 'https://vk-chat-chi.vercel.app/api/order',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: { text, public_id: salonId, vk_id, _id: orderId },
        }).catch((err) => {
            console.error(err);
        });
    }, [order, orderId, vk_id, salonId]);

    return { makeOrder, order, setOrder, orderId };
}
