import React from 'react';
import { Div, FixedLayout, SegmentedControl, Tabbar, TabbarItem } from '@vkontakte/vkui';
import { Icon28CarOutline, Icon28ArticleOutline, Icon28CupOutline, Icon28UserCircleOutline } from '@vkontakte/icons';
import { useLocation, useRouter } from '@happysanta/router';

import { ParticipateContestSnackbar } from 'components/participate-contest-snackbar/participate-contest-snackbar';

import { useAccessToken } from 'components/access-token-provider/access-token-provider';

import {
    PAGE_BATTLE_CHALLENGE,
    PAGE_BATTLE_LEADERS,
    PAGE_PROFILE,
    PAGE_SALONS,
    PAGE_TUNING,
    PANEL_BATTLE_CHALLENGE,
    VIEW_BATTLE,
    VIEW_PROFILE,
    VIEW_PROFILE_PROJECTS,
    VIEW_SALONS,
    VIEW_TUNING,
} from '../../router';

export const MyTabbar = () => {
    const router = useRouter();
    const location = useLocation();
    const token = useAccessToken();
    const isAccessTokenProvided = token && token !== '';

    return (
        <FixedLayout vertical="bottom">
            {location.getViewId() === VIEW_BATTLE && isAccessTokenProvided && (
                <>
                    <Div style={{ backgroundColor: '#FFFF' }}>
                        <SegmentedControl
                            options={[
                                {
                                    value: PAGE_BATTLE_CHALLENGE,
                                    label: 'Баттл',
                                },
                                {
                                    value: PAGE_BATTLE_LEADERS,
                                    label: 'Лидеры',
                                },
                            ]}
                            value={
                                location.getPanelId() === PANEL_BATTLE_CHALLENGE
                                    ? PAGE_BATTLE_CHALLENGE
                                    : PAGE_BATTLE_LEADERS
                            }
                            onChange={(value) => value && router.pushPage(value as string)}
                        />
                    </Div>
                    <ParticipateContestSnackbar />
                </>
            )}
            <Tabbar>
                <TabbarItem
                    onClick={() => {
                        router.pushPage(PAGE_TUNING);
                        // @ts-expect-error yandex.metrika expected error
                        ym(94201542, 'reachGoal', `call${PAGE_TUNING}`);
                    }}
                    selected={location.getViewId() === VIEW_TUNING}
                    data-story="feed"
                    text="Тюнинг"
                >
                    <Icon28CarOutline />
                </TabbarItem>
                <TabbarItem
                    onClick={() => {
                        router.pushPage(PAGE_SALONS);
                        // @ts-expect-error yandex.metrika expected error
                        ym(94201542, 'reachGoal', `call${PAGE_SALONS}`);
                    }}
                    selected={location.getViewId() === VIEW_SALONS}
                    data-story="services"
                    text="Салоны"
                >
                    <Icon28ArticleOutline />
                </TabbarItem>
                <TabbarItem
                    onClick={() => {
                        router.pushPage(PAGE_BATTLE_CHALLENGE);
                        // @ts-expect-error yandex.metrika expected error
                        ym(94201542, 'reachGoal', `call${PAGE_BATTLE_CHALLENGE}`);
                    }}
                    selected={location.getViewId() === VIEW_BATTLE}
                    data-story="messages"
                    text="Баттл"
                >
                    <Icon28CupOutline />
                </TabbarItem>
                <TabbarItem
                    onClick={() => {
                        router.pushPage(PAGE_PROFILE);
                        // @ts-expect-error yandex.metrika expected error
                        ym(94201542, 'reachGoal', `call${PAGE_PROFILE}`);
                    }}
                    selected={location.getViewId() === VIEW_PROFILE || location.getViewId() === VIEW_PROFILE_PROJECTS}
                    data-story="clips"
                    text="Профиль"
                >
                    <Icon28UserCircleOutline />
                </TabbarItem>
            </Tabbar>
        </FixedLayout>
    );
};
