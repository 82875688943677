import { memo, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { Group, Mesh } from 'three';

import { type IModelProps } from '../../types/model-props';

// Model loader.
export const Model = memo(({ path, mirror, ...props }: IModelProps) => {
    if (!path) {
        return null;
    }
    const model = useGLTF(path);
    useEffect(() => {
        if (model) {
            model.scene.traverseVisible((child) => {
                if (child instanceof Mesh) {
                    child.material = child.material.clone();
                }
            });
        }
    }, [model]);
    if (mirror) {
        const newModel = new Group();
        newModel.copy(model.scene, true);
        return <primitive object={newModel} {...props} />;
    }
    return <primitive object={model.scene} {...props} />;
});

export default Model;
