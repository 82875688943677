import axios from 'axios';

import { getUserId } from './util/get-user-id';
import { createAxiosHeaders } from './util/create-axios-headers';
import { BASE_API } from './constants/base-api';

export const authenticate = async () => {
    const userId = getUserId();
    const config = createAxiosHeaders();

    try {
        return await axios
            .post(`${BASE_API}/voting/createuser?vk_id=${userId}`, undefined, config)
            .then((res) => {
                return res;
            })
            .catch((err) => err.response.data.Error);
    } catch (ignored) {}
};
