import React, { type Dispatch, type SetStateAction } from 'react';
import {
    Link,
    ModalPage,
    ModalPageHeader,
    ModalRoot,
    useAdaptivityWithJSMediaQueries,
    ViewWidth,
} from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';

import { Icon24Dismiss } from '@vkontakte/icons';

import { type IWithId } from '../../../../types/interfaces';
import {
    PAGE_SALONS,
    SALONS_FILTER_CAR_TYPE_MODAL_PAGE,
    SALONS_FILTER_CITY_MODAL_PAGE,
    SALONS_FILTER_SERVICE_MODAL_PAGE,
} from '../../../../router';
import { FilterModal } from '../filter-modal/filter-modal';
import { FILTER_TYPES } from '../../../../queries/use-get-filter-options';

interface IProps extends IWithId {
    cities: string[];
    setCities: Dispatch<SetStateAction<string[]>>;
    serviceTypes: string[];
    setServiceTypes: Dispatch<SetStateAction<string[]>>;
    carTypes: string[];
    setCarTypes: Dispatch<SetStateAction<string[]>>;
    refetch: VoidFunction;
}

const PageHeader = ({ setIds, header }: { setIds: (v: string[]) => void; header: string }) => {
    const router = useRouter();
    return (
        <ModalPageHeader
            style={{ padding: '0 16px' }}
            before={
                <Link
                    onClick={() => {
                        setIds([]);
                        router.pushPage(PAGE_SALONS);
                    }}
                >
                    Очистить
                </Link>
            }
            after={
                <Icon24Dismiss
                    onClick={() => {
                        router.pushPage(PAGE_SALONS);
                    }}
                />
            }
        >
            {header}
        </ModalPageHeader>
    );
};
export const SalonsSearchModal = ({
    id,
    cities,
    setCities,
    serviceTypes,
    setServiceTypes,
    setCarTypes,
    carTypes,
    refetch,
}: IProps) => {
    const router = useRouter();
    const { viewWidth } = useAdaptivityWithJSMediaQueries();
    return (
        <ModalRoot onClose={() => router.popPage()} activeModal={id}>
            <ModalPage
                onClose={() => {
                    router.pushPage(PAGE_SALONS);
                    refetch();
                }}
                settlingHeight={viewWidth === ViewWidth.MOBILE ? 100 : undefined}
                id={SALONS_FILTER_CITY_MODAL_PAGE}
                hideCloseButton
                size="l"
                header={<PageHeader setIds={setCities} header="Города" />}
            >
                <FilterModal
                    setSelectedIds={setCities}
                    filterType={FILTER_TYPES.CITIES}
                    refetch={refetch}
                    selectedIds={cities}
                    multiSelect={false}
                />
            </ModalPage>
            <ModalPage
                onClose={() => {
                    router.pushPage(PAGE_SALONS);
                    refetch();
                }}
                settlingHeight={100}
                size="l"
                hideCloseButton
                id={SALONS_FILTER_SERVICE_MODAL_PAGE}
                header={<PageHeader setIds={setServiceTypes} header="Услуги" />}
            >
                <FilterModal
                    setSelectedIds={setServiceTypes}
                    filterType={FILTER_TYPES.SERVICES}
                    refetch={refetch}
                    selectedIds={serviceTypes}
                />
            </ModalPage>
            <ModalPage
                onClose={() => {
                    router.pushPage(PAGE_SALONS);
                    refetch();
                }}
                settlingHeight={100}
                size="l"
                hideCloseButton
                id={SALONS_FILTER_CAR_TYPE_MODAL_PAGE}
                header={<PageHeader setIds={setCarTypes} header="Марки машин" />}
            >
                <FilterModal
                    setSelectedIds={setCarTypes}
                    filterType={FILTER_TYPES.CAR_TYPES}
                    refetch={refetch}
                    selectedIds={carTypes}
                />
            </ModalPage>
        </ModalRoot>
    );
};
