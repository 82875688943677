import React from 'react';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { vehicleConfigs } from '../../../../engine/vehicle-configs';
import { TuningHeader } from '../tuning-header';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { ChooseSingleCard } from '../../../../components/choose-single-card/choose-single-card';
import { ROUGHNESS_VARIANTS } from '../../../../constants/roughness-variants';
import { ChangeExternalMaterialEvent } from '../../../../engine/events/common-events/change-external-material';

import { WheelEventType } from '../../../../engine/events/wheel-events-handlers/event-types';

export const EditRimMaterial = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const { wheelsRef } = useDetailsContext();

    const handleRimMaterialReset = React.useCallback(() => {
        setCurrentValue({
            rim_material: vehicleConfigs.defaults.rim_material,
        });
        wheelsRef.current?.dispatchEvent(
            new ChangeExternalMaterialEvent(
                wheelsRef.current,
                vehicleConfigs.defaults.rim_material,
                WheelEventType.RIM_MATERIAL,
            ),
        );
    }, [currentValue]);

    return (
        <div>
            <TuningHeader children="Выберите материал дисков" onReset={handleRimMaterialReset} />
            <ChooseSingleCard
                onClick={(v) => {
                    setCurrentValue({ rim_material: v.value });

                    wheelsRef.current?.dispatchEvent(
                        new ChangeExternalMaterialEvent(wheelsRef.current, v.value, WheelEventType.RIM_MATERIAL),
                    );
                }}
                currentValue={currentValue.rim_material}
                variants={ROUGHNESS_VARIANTS}
            />
        </div>
    );
};
