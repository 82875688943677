import { useMutation } from 'react-query';

import { type IDefaults } from '../types/tuning-model';
import { getConfigurationInfo } from '../util/get-configuration-info';
import { BASE_API } from '../constants/base-api';

interface IVehicleWithUser extends IDefaults {
    people_id: string;
}

interface ISaveVehicle {
    defaults: IVehicleWithUser;
}

export const useSaveProject = () => {
    const { values, sign } = getConfigurationInfo();
    return useMutation(async (value: ISaveVehicle) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.roughness;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.addons;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.stickers;
        delete value.defaults.bootColor;
        delete value.defaults.bonnetColor;
        delete value.defaults.rightSideColor;
        delete value.defaults.leftSideColor;
        delete value.defaults.bumperColor;
        delete value.defaults.glass_tone;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.addonsInfo;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.addons;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.interiorMaterial;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.interiorColor;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete value.defaults.roughness;
        delete value.defaults.mark;
        const response = await fetch(`${BASE_API}/project/new`, {
            method: 'POST',
            headers: {
                Authorization: values,
                Sign: sign,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        });
        const data = (await response.json()) as { id: number };
        return data.id;
    });
};
