import React, { useState } from 'react';

import { Spacing } from '@vkontakte/vkui';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { MODAL_TUNING_COLOR } from '../../../../router';
import { TuningHeader } from '../tuning-header';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import {
    type TNamePearl,
    type TNamePartOfCar,
} from '../../../../engine/events/body-events-handlers/color-change-event';
import { useSetModalHeight } from '../../../../hooks/use-set-modal-height';
import { PRE_PREPARED_COLORS, type TPrePreparedColor } from './pre-prepared-colors';
import { CardWithTick } from '../../../../components/card-with-tick/card-with-tick';
import { CategoriesSplit } from '../../../../components/categories-split/categories-split';
import { PART_OF_CARS_SELECT_ITEM } from '../../../../constants/part-of-cars-select-items';
import { changeColorFn, getColorAccordingToCategory, getIsPearl, pearlObject, PEARL_BASE_COLOR } from './utils';
import { vehicleConfigs } from '../../../../engine/vehicle-configs';

import { PickColorModal } from './pick-color';

import styles from './edit-color-modal.module.css';

export const EditColorModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const [selected, setSelected] = useState<TNamePartOfCar>(PART_OF_CARS_SELECT_ITEM[0].value);
    const prePreparedColors = Object.keys(PRE_PREPARED_COLORS) as TPrePreparedColor[];
    const vehicle = useDetailsContext().vehicle;
    const [isPalette, setIsPalette] = useState(false);
    const isColorSelectedFromPalette = prePreparedColors.every((colorLabel) => {
        return (
            !getIsPearl(currentValue)?.isPearl?.[selected] &&
            PRE_PREPARED_COLORS[colorLabel].hex !== getColorAccordingToCategory(currentValue)[selected]
        );
    });

    useSetModalHeight();

    const selectColor = (color: string, pearlObject: TNamePearl) => {
        if (vehicle.current) {
            changeColorFn(selected, setCurrentValue, color, vehicle.current, pearlObject);
        }
    };

    const handleColorReset = React.useCallback(() => {
        if (!vehicle.current) return;
        changeColorFn('all', setCurrentValue, vehicleConfigs.defaults.color, vehicle.current);
    }, []);

    if (isPalette) {
        return (
            <PickColorModal
                setIsPalette={setIsPalette}
                setCurrentValue={setCurrentValue}
                currentValue={currentValue}
                selected={selected}
                pearlObject={pearlObject}
            />
        );
    }

    return (
        <div>
            <Spacing size={10} />
            <TuningHeader
                promotion={selected === 'interior' ? 'interior' : MODAL_TUNING_COLOR}
                children="Выберите цвет"
                onReset={handleColorReset}
            />
            <CategoriesSplit categories={PART_OF_CARS_SELECT_ITEM} setSelected={setSelected} selected={selected}>
                <CardWithTick
                    className={styles.conicGradinent}
                    onClick={() => setIsPalette(true)}
                    ticked={isColorSelectedFromPalette}
                />

                {selected !== 'interior' ? (
                    <CardWithTick
                        className={styles.pearl}
                        onClick={() => {
                            selectColor(PEARL_BASE_COLOR, pearlObject(currentValue, selected, true));
                            // @ts-expect-error yandex.metrika expected error
                            ym(94201542, 'reachGoal', 'pearlColor');
                        }}
                        ticked={getIsPearl(currentValue)?.isPearl?.[selected]}
                    />
                ) : null}

                {prePreparedColors.map((colorKey) => {
                    return (
                        <CardWithTick
                            key={colorKey}
                            ticked={
                                getColorAccordingToCategory(currentValue)[selected] ===
                                PRE_PREPARED_COLORS[colorKey].hex
                            }
                            style={{
                                backgroundColor: PRE_PREPARED_COLORS[colorKey].hex,
                                border: '1px #00000014 solid',
                            }}
                            onClick={() =>
                                selectColor(PRE_PREPARED_COLORS[colorKey].hex, pearlObject(currentValue, selected))
                            }
                        />
                    );
                })}
            </CategoriesSplit>
        </div>
    );
};
