import { type IMediaSelectItem } from '../types/select-item';
import { type TRoughnessType } from '../engine/events/common-events/change-external-material';
import { BASE_API } from './base-api';

export const ROUGHNESS_VARIANTS: Array<IMediaSelectItem<TRoughnessType>> = [
    {
        value: 'glossy',
        label: 'Глянцевый',
        mediaUrl: `${BASE_API}/static/assets/images/materials/GlossyTexture.png`,
    },
    {
        value: 'semiMatte',
        label: 'Полуматовый',
        mediaUrl: `${BASE_API}/static/assets/images/materials/SemiMatteTexture.png`,
    },
    {
        value: 'matte',
        label: 'Матовый',
        mediaUrl: `${BASE_API}/static/assets/images/materials/MatteTexture.png`,
    },
];
