import { type ISelectItem } from '../types/select-item';
import { type TNamePartOfCar } from '../engine/events/body-events-handlers/color-change-event';

export const PART_OF_CARS_SELECT_ITEM: Array<ISelectItem<TNamePartOfCar>> = [
    {
        label: 'Вся машина',
        value: 'all',
    },
    {
        label: 'Капот',
        value: 'body_bonnet',
    },
    {
        label: 'Бампер',
        value: 'body_backBumper',
    },
    {
        label: 'Обе стороны',
        value: 'both_sides',
    },
    {
        label: 'Правая сторона',
        value: 'body_rightSide',
    },
    {
        label: 'Левая сторона',
        value: 'body_leftSide',
    },
    {
        label: 'Багажник',
        value: 'body_boot',
    },
    {
        label: 'Салон',
        value: 'interior',
    },
];
