import { Div, Headline, Subhead, Tappable } from '@vkontakte/vkui';
import { Icon20CheckCircleOn } from '@vkontakte/icons';

import { type IService } from 'queries/use-get-salon-item';

import { useEffect, useState } from 'react';

import { parseNumberToStringValue } from '../../../util/parse-number-to-value-string';

import styles from './salon-item.module.css';

interface ITappable {
    setOrder: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
    service: IService;
    order: object;
}

export const ServiceTappable = ({ setOrder, order, service }: ITappable) => {
    const [selected, setSelected] = useState(false);
    const createOrder = (serivce: any) => {
        setOrder((prevValue: any) => {
            if (prevValue[serivce._key]) {
                prevValue[serivce._key] = null;

                const filteredObj = Object.fromEntries(
                    Object.entries(prevValue).filter(([_, value]) => value !== null),
                );

                return { ...filteredObj };
            } else {
                return { ...prevValue, [serivce._key]: serivce };
            }
        });
    };

    useEffect(() => {
        setSelected(Object.keys(order).includes(service._key));
    }, [order]);

    return (
        <Tappable
            activeMode="background"
            hasActive
            onClick={() => {
                createOrder(service);
            }}
            style={{
                position: 'relative',
                backgroundColor: selected ? '#fff' : '#F5F5F5',
                borderRadius: 8,
                width: '100%',
                userSelect: 'none',
                marginBottom: 16,
                boxShadow: selected ? '0px 2px 24px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)' : '',
            }}
        >
            {selected ? (
                <Icon20CheckCircleOn color="#2688EB" style={{ position: 'absolute', right: '-8px', top: '-8px' }} />
            ) : null}

            <Div>
                <div style={{ display: 'flex' }}>
                    <Headline weight="2" level="2" style={{ color: selected ? '#2D81E0' : 'inherit' }}>
                        {service.name}
                    </Headline>
                    <Headline
                        style={{
                            marginLeft: 'auto',
                            whiteSpace: 'nowrap',
                            color: selected ? '#2D81E0' : 'inherit',
                        }}
                        weight="2"
                        level="2"
                    >
                        {service.fromPrice && 'от '}
                        {parseNumberToStringValue(service.price)}
                    </Headline>
                </div>
                <div style={{ display: 'flex' }}>
                    <Subhead className={styles.subHead}>{service.description}</Subhead>
                    <Subhead
                        style={{
                            marginLeft: 'auto',
                            whiteSpace: 'nowrap',
                        }}
                        className={styles.subHead}
                    >
                        {service.duration ? `${service.duration} часа ` : null}
                    </Subhead>
                </div>
            </Div>
        </Tappable>
    );
};
