import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';

import { type IProject } from './use-get-all-projects';

export const useGetBattleProfileProjects = (userId: string | number) => {
    return useQuery(['voting', userId], async () => {
        const response = await axios.get<IProject[]>(`${BASE_API}/voting/userprojects?vk_id=${userId}`);
        return response.data;
    });
};
