import {
    Banner,
    Button,
    Link,
    ModalPageHeader,
    ScreenSpinner,
    Separator,
    Spacing,
    Subhead,
    Text,
    Title,
} from '@vkontakte/vkui';

import { Icon24Dismiss, Icon24InfoCircleOutline } from '@vkontakte/icons';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from '@happysanta/router';

import { PAGE_TUNING } from '../../../../router';
import { CustomHeader } from '../../../../components/custom-header/custom-header';
import { useGetSalonBucket } from '../../../../queries/use-get-salon-bucket';
import { ServiceDescriptionCard } from '../../../../components/service-description/service-description-card';
import { useMakeOrder } from '../../../../hooks/use-make-order';
import { type ISalonPage } from '../../../../queries/use-get-salon-item';
import { useUsedSalonIdsLayer } from '../../../../components/advertisement-provider/advertisement-provider';

import styles from './order-modal.module.css';

import '../../../../index.css';

const MiniSalon = ({ salon, isLast }: { salon: ISalonPage; isLast?: boolean }) => {
    const { makeOrder, order, setOrder, orderId } = useMakeOrder(salon.id);

    return (
        <>
            <div key={salon.id}>
                <div className={styles.groupHeader}>
                    <div>
                        <Title className={styles.salonName} level="3" weight="2">
                            {salon.name}
                        </Title>
                        {salon?.services ? (
                            <Subhead className={styles.salonServiceCounter} weight="3">
                                {salon?.services.length}
                            </Subhead>
                        ) : null}
                        <Subhead className={styles.salonAddress} weight="3">
                            {salon.address}
                        </Subhead>
                    </div>
                    {orderId ? (
                        <div>
                            {Object.keys(order)?.length > 0 && salon.contacts ? (
                                <Link
                                    href={`https://vk.com/write-${salon.contacts.vkId}?ref=${orderId}`}
                                    target="_blank"
                                    onClick={() => makeOrder()}
                                    className={styles.orderHereLink}
                                >
                                    Заказать
                                </Link>
                            ) : (
                                <Text>Выберите услуги</Text>
                            )}
                        </div>
                    ) : null}
                </div>
                <div>
                    {salon.services?.map((service: any) => (
                        <ServiceDescriptionCard
                            name={service.name}
                            fromPrice={service.fromPrice}
                            price={service.price}
                            description={service.description}
                            duration={service.duration}
                            _key={service._key}
                            key={service._key}
                            order={order}
                            setOrder={setOrder}
                        />
                    ))}
                </div>
            </div>
            <Spacing size={8} />
            {!isLast ? <Separator /> : null}
        </>
    );
};

export const OrderModal = () => {
    const router = useRouter();
    const [isNotificationHidden, setNotificationHidden] = useState(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { usedSalonIds } = useUsedSalonIdsLayer();

    const { data: salons, isLoading, remove } = useGetSalonBucket(Array.from(usedSalonIds.keys()));

    useEffect(() => {
        const localIsNotificationHidden = localStorage.getItem('isNotificationHidden') === '1';

        setNotificationHidden(localIsNotificationHidden);
    }, []);

    const hideNotification = useCallback(() => {
        localStorage.setItem('isNotificationHidden', '1');

        setNotificationHidden(true);
    }, []);

    if (isLoading) {
        return <ScreenSpinner />;
    }

    return salons && salons?.length > 0 ? (
        <div>
            <ModalPageHeader
                className={styles.modalHeader}
                after={
                    <Icon24Dismiss
                        onClick={() => {
                            router.pushPage(PAGE_TUNING);
                            remove();
                        }}
                    />
                }
            >
                <CustomHeader>Корзина</CustomHeader>
            </ModalPageHeader>
            {!isNotificationHidden ? (
                <Banner
                    before={<Icon24InfoCircleOutline fill="#2688EB" />}
                    subheader={
                        <Subhead id={styles.banner}>
                            Корзина собрана из того, что вы настроили в приложении. Выберите салон, в котором хотите
                            заказать услуги.
                        </Subhead>
                    }
                    actions={<Button onClick={() => hideNotification()}>Понятно</Button>}
                />
            ) : null}
            <Spacing size={4}></Spacing>
            {salons?.map((salon, idx) => (
                <MiniSalon salon={salon} isLast={idx === salons.length - 1} key={salon.id} />
            ))}
            <Spacing size={8} />
        </div>
    ) : null;
};
