import { Mesh, TextureLoader, type MeshStandardMaterial, type Object3D } from 'three';

import { BasicEvent } from '../basic-event';
import { type BodyEventTypes } from './event-types';

export type TNamePartOfCar =
    | 'all'
    | 'body_bonnet'
    | 'body_leftSide'
    | 'body_rightSide'
    | 'body_backBumper'
    | 'body_boot'
    | 'interior'
    | 'both_sides';

export interface TNamePearl {
    all?: boolean;
    body_bonnet?: boolean;
    body_leftSide?: boolean;
    body_rightSide?: boolean;
    body_backBumper?: boolean;
    body_boot?: boolean;
    interior?: boolean;
    both_sides?: boolean;
}

export class ColorChangeEvent extends BasicEvent<BodyEventTypes> {
    private _color: string;
    private _name: TNamePartOfCar;
    private _isPearl?: TNamePearl;

    constructor(type: BodyEventTypes, name: TNamePartOfCar, target: Object3D, color: string, isPearl?: TNamePearl) {
        super(type, target);
        this._name = name;
        this._color = color;
        this._isPearl = isPearl;
    }

    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = value;
    }

    get name(): TNamePartOfCar {
        return this._name;
    }

    set name(value: TNamePartOfCar) {
        this._name = value;
    }

    get isPearl(): TNamePearl | undefined {
        return this._isPearl;
    }

    set isPearl(value: TNamePearl) {
        this._isPearl = value;
    }
}

const setMaterials = async (material: MeshStandardMaterial, event: BasicEvent<BodyEventTypes>) => {
    if (event instanceof ColorChangeEvent && material.name === 'body') {
        if (event.isPearl?.[event.name]) {
            const textureLoader = new TextureLoader();
            const texture = await textureLoader.loadAsync('https://files.synapse.global/gradtest');

            material.color.setStyle(event.color);
            material.map = texture;
            material.transparent = true;
            material.roughness = 0.5;
        } else {
            material.color.setStyle(event.color);
            material.map = null;
        }
    }
};

export const colorChangeEventHandler = (event: BasicEvent<BodyEventTypes>) => {
    if (event instanceof ColorChangeEvent) {
        event.target.traverseVisible((child) => {
            const shouldBothSidesBeColored =
                event.name === 'both_sides' && (child.name === 'body_leftSide' || child.name === 'body_rightSide');

            if (
                child instanceof Mesh &&
                (child.name === event.name || event.name === 'all' || shouldBothSidesBeColored)
            ) {
                setMaterials(child.material, event);
            }
        });
    }
};
