import { type PerspectiveCamera } from 'three';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { type IWithChildren } from '../../types/interfaces';
import { VIEWER_INITIAL_TARGET } from '../../constants/viewer-initial-config';

export interface IViewerOptionsContextProps extends IWithChildren {
    cameraRef: (node: PerspectiveCamera) => void;
    camera?: PerspectiveCamera;
    controlsEnabled: boolean;
    viewerTarget: [number, number, number];
    setViewerOptions: (value?: [number, number, number]) => void;
    resetViewerOptions: () => void;
}

const ViewerOptionsContext = createContext<Omit<IViewerOptionsContextProps, 'children'> | null>(null);

export const ViewerOptionsContextProvider = (props: IWithChildren) => {
    const { children } = props;
    const [camera, setCamera] = useState<PerspectiveCamera>();
    const cameraRef = useCallback((node: PerspectiveCamera) => {
        if (node !== null) {
            setCamera(node);
        }
    }, []);

    const [controlsEnabled, setControlsEnabled] = useState(true);
    const [viewerTarget, setViewerTarget] = useState<[number, number, number]>(VIEWER_INITIAL_TARGET);

    const resetViewerOptions = useCallback(() => {
        setControlsEnabled(true);
        setViewerTarget(VIEWER_INITIAL_TARGET);
    }, [setViewerTarget, setControlsEnabled]);

    const setViewerOptions = useCallback(
        (target: [number, number, number] = VIEWER_INITIAL_TARGET) => {
            setControlsEnabled(false);
            setViewerTarget([...target]);
        },
        [setViewerTarget, setControlsEnabled],
    );

    return (
        <ViewerOptionsContext.Provider
            value={{
                resetViewerOptions,
                camera,
                cameraRef,
                controlsEnabled,
                setViewerOptions,
                viewerTarget,
            }}
        >
            {children}
        </ViewerOptionsContext.Provider>
    );
};

export const useViewerOptionsContext = () => {
    const viewerOptions = useContext(ViewerOptionsContext);
    if (!viewerOptions) {
        throw new Error('useViewerOptionsContext must be used within a ThreeContextProvider.');
    }
    return viewerOptions;
};
