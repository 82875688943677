import { useQuery } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';

export type TDetailName = 'body_bonnet' | 'body_leftSide' | 'body_rightSide';

export interface IStickerItem {
    id: string;
    sticker: string;
    name: TDetailName;
    is_color: boolean;
    sticker_icon: string;
    position?: {
        x: number;
        y: number;
    };
    scale?: number;
}

export const useGetStickers = () => {
    return useQuery('stickers', async () => {
        const result = await axios.get<IStickerItem[]>(`${BASE_API}/project/getsticker`);
        return result.data;
    });
};
