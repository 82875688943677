import axiosInstance from 'axios-instance';
import { BASE_API } from 'constants/base-api';
import { useReducer, useEffect, useState } from 'react';
import { type IVehicleConfig, type IDefaults } from 'types/tuning-model';
import { vehicleConfigs } from 'engine/vehicle-configs';

const cleanConfig = (config: any) => {
    const cleaned: any = {};
    Object.keys(config).forEach((key) => {
        if (config[key] !== undefined && config[key] !== null && config[key] !== '' && key !== 'config') {
            cleaned[key] = config[key];
        }
    });
    return cleaned;
};

export const useVehicleConfigs = () => {
    const [contestConfig, setContestConfig] = useState<IVehicleConfig['defaults']>();

    // Basic vehicle
    const [tuningVehicle, setTuningVehicle] = useReducer(
        (currentVehicle: IDefaults, newState: Partial<IDefaults>): IDefaults => ({ ...currentVehicle, ...newState }),
        vehicleConfigs.defaults,
    );

    // Contest vehicle
    const [contestVehicle, setContestVehicle] = useReducer(
        (currentVehicle: IDefaults, newState: Partial<IDefaults>) => ({
            ...currentVehicle,
            ...newState,
        }),
        vehicleConfigs.defaults,
    );

    useEffect(() => {
        async function postContestCar() {
            const { data: projectContestConfig } = await axiosInstance.post(`${BASE_API}/project/carcontest`);
            const dynamicConfig = JSON.parse(projectContestConfig?.config || '{}') || {};
            const additionalConfig = JSON.parse(projectContestConfig?.picture || '{}') || {};
            const { parts, picture, ...rest } = additionalConfig;

            const finalConfig: IVehicleConfig['defaults'] = {
                ...dynamicConfig,
                ...cleanConfig(projectContestConfig),
                parts,
                ...(picture && { picture }),
            };

            const config = {
                ...vehicleConfigs.defaults,
                id: finalConfig.id,
                parts,
                ...(finalConfig.rim && { rim: finalConfig.rim }),
                ...(finalConfig.rim_color && { rim_color: finalConfig.rim_color }),
                ...(finalConfig.tire && { tire: finalConfig.tire }),
                ...(finalConfig.tire_color && { tire_color: finalConfig.tire_color }),
                ...(finalConfig.mark && { mark: finalConfig.mark }),
                ...(finalConfig.maxTireSize && { tire_diameter: finalConfig.maxTireSize - 2 }),
                ...(finalConfig.rim_diameter && { rim_diameter: finalConfig.rim_diameter }),
                ...(finalConfig.defaultLift && {
                    lift:
                        finalConfig.defaultLift ||
                        Math.ceil(((finalConfig?.minLift || -8) + (finalConfig?.maxLift || 0)) / 2),
                }),
                ...(finalConfig.color && { color: finalConfig.color }),
                ...(finalConfig.parts && { parts: finalConfig.parts }),
                ...rest,
            };

            setContestConfig(config);
        }

        postContestCar();
    }, []);

    useEffect(() => {
        if (contestConfig) {
            setContestVehicle({ ...contestConfig });
        }
    }, [contestConfig]);

    return { tuningVehicle, setTuningVehicle, contestVehicle, setContestVehicle, contestConfig };
};
