import { useMutation } from 'react-query';

import axios from '../axios-instance';
import { BASE_API } from '../constants/base-api';

export const useSendToBattle = () => {
    return useMutation(async (id: number) => {
        return await axios.post(`${BASE_API}/voting/pushbattle?id=${id}&in_battle=1`);
    });
};

export const useSendToContest = () => {
    return useMutation(async (id: number) => {
        return await axios.post(`${BASE_API}/voting/pushbattle?id=${id}&in_contest=1`);
    });
};
