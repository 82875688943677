import React from 'react';
import { useParams, useRouter } from '@happysanta/router';
import {
    ActionSheet,
    ActionSheetItem,
    Panel,
    PanelHeader,
    PlatformProvider,
    PopoutWrapper,
    Title,
} from '@vkontakte/vkui';
import { Icon24ChevronLeft } from '@vkontakte/icons';

import CardProject from '../../components/card-project';
import { SplitLayoutContext } from '../../components/split-layout-provider/split-layout-provider';
import { BASE_API } from '../../constants/base-api';
import { PAGE_PROFILE } from '../../router';
import { useGetAllProjects } from '../../queries/use-get-all-projects';
import { getUserId } from '../../util/get-user-id';
import { type IWithId } from '../../types/interfaces';
import { type IProject } from '../../queries/use-get-all-projects';

import DeleteAlert from './deleteAlert';

import './style.css';

export const ProfileProjects = ({ id }: IWithId) => {
    const router = useRouter();
    const { id: projectId } = useParams(id);
    const { data = [] } = useGetAllProjects(getUserId());
    const { setPopout } = React.useContext(SplitLayoutContext);
    const newData = data.map((item) => `${BASE_API}/${item.image}`);

    const baseTargetRef = React.useRef<SVGSVGElement | null>(null);

    const handleProjectDelete = React.useCallback((item: IProject) => {
        setPopout(<DeleteAlert projectId={item.id} onClose={() => setPopout(null)} />);
    }, []);

    const handleOpenPopout = React.useCallback((item: IProject) => {
        setPopout(
            <PlatformProvider value="ios">
                <PopoutWrapper alignY="center" alignX="center">
                    <ActionSheet onClose={() => setPopout(null)} toggleRef={baseTargetRef}>
                        <ActionSheetItem href={`${BASE_API}/${item.image}`} download={item.name} autoClose>
                            Скачать изображение
                        </ActionSheetItem>
                        <ActionSheetItem autoClose mode="destructive" onClick={() => handleProjectDelete(item)}>
                            Удалить проект
                        </ActionSheetItem>
                    </ActionSheet>
                </PopoutWrapper>
            </PlatformProvider>,
        );
    }, []);

    React.useEffect(() => {
        if (!projectId || data.length === 0) return;
        const element = document.getElementById(projectId);
        if (!element) return;
        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 500);
    }, [projectId, data]);

    return (
        <Panel id={id}>
            <PanelHeader
                before={<Icon24ChevronLeft onClick={() => router.pushPage(PAGE_PROFILE)} style={{ paddingLeft: 12 }} />}
                style={{ textAlign: 'center', position: 'sticky', top: 0, left: 0, right: 0, zIndex: 3 }}
            >
                <Title level="2">Мои проекты</Title>
            </PanelHeader>
            {data
                .sort((a, b) => b.id - a.id)
                .map((item, index) => (
                    <CardProject
                        dataImages={newData}
                        key={item.id}
                        item={item}
                        index={index}
                        dataLenght={data.length}
                        haveCell={false}
                        setPopout={handleOpenPopout}
                    />
                ))}
        </Panel>
    );
};
