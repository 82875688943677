import React, { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import { ScreenSpinner, Spacing, useModalRootContext } from '@vkontakte/vkui';

import { Icon24CancelCircleFillRed } from '@vkontakte/icons';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { CustomTabsItem } from '../../../../components/custom-tabs-item/custom-tabs-item';
import { CustomScroll } from '../../../../components/custom-scroll/custom-scroll';
import { CardWithTick } from '../../../../components/card-with-tick/card-with-tick';
import { Steps } from '../../../../components/steps/steps';
import { Step } from '../../../../components/steps/step';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { TuningHeader } from '../tuning-header';

import { BASE_API } from '../../../../constants/base-api';

import { type TDetailName, useGetStickers } from '../../../../queries/use-get-stickers';

import '../styles.css';
import { StickerConfiguration } from './steps/sticker-configuration';
import { useMakeCameraTransitions } from '../../../../engine/hooks/use-make-camera-transitions';

const mapDetailName: Record<TDetailName, string> = {
    body_bonnet: 'Капот',
    body_leftSide: 'Левая сторона',
    body_rightSide: 'Правая сторона',
};

export const EditPickStickerModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const { data: stickers, isLoading } = useGetStickers();
    const detailsHelper = useDetailsContext();
    const { updateModalHeight } = useModalRootContext();
    const [selected, setSelected] = useState<TDetailName>('body_bonnet');
    const { lookAtLeftDoor, lookAtRightDoor, lookAtBonnet, rollback } = useMakeCameraTransitions();

    const categories = uniq(stickers?.map((sticker) => sticker.name));
    const chosenSticker = currentValue.stickers?.find((s) => s.name === selected);
    const selectedIndex = categories.findIndex((c) => c === selected);

    const [selectedSticker, setSelectedSticker] = useState<number | undefined>(
        chosenSticker ? +chosenSticker?.id : undefined,
    );

    const handleStickerReset = React.useCallback(() => {
        setCurrentValue({ stickers: [] });
        detailsHelper.clearDetails();
    }, [currentValue]);

    useEffect(() => {
        updateModalHeight();
    }, [isLoading]);

    useEffect(() => {
        if (categories?.length) {
            setSelected(categories[0]);
        }
    }, [categories.length === 0]);
    useEffect(() => {
        if (chosenSticker) {
            setSelectedSticker(+chosenSticker?.id);
        }
    }, [chosenSticker]);

    return isLoading ? (
        <>
            <Spacing size={40} />
            <ScreenSpinner />
            <Spacing size={40} />
        </>
    ) : (
        <div>
            <Steps>
                <Step>
                    {({ moveToNextStep }) => {
                        updateModalHeight();
                        return (
                            <>
                                <TuningHeader children={'Выберите наклейку'} onReset={handleStickerReset} />
                                <CustomScroll selectedIndex={selectedIndex}>
                                    {categories.map((category) => {
                                        return (
                                            <div key={category}>
                                                <CustomTabsItem
                                                    selected={selected === category}
                                                    onClick={() => setSelected(category)}
                                                >
                                                    {mapDetailName[category]}
                                                </CustomTabsItem>
                                            </div>
                                        );
                                    })}
                                </CustomScroll>
                                <CustomScroll selectedIndex={selectedSticker}>
                                    {stickers
                                        ?.filter((sticker) => sticker.name === selected)
                                        ?.map((sticker) => {
                                            const isTicked =
                                                selected && currentValue.stickers.some((s) => s.id === sticker.id);
                                            if (isTicked) {
                                                setSelectedSticker(+sticker.id);
                                            }
                                            return (
                                                <div style={{ position: 'relative' }} key={sticker.id}>
                                                    {isTicked && (
                                                        <Icon24CancelCircleFillRed
                                                            onClick={() => detailsHelper.removeDetail(sticker.name)}
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: 73,
                                                                left: 1,
                                                                zIndex: 80,
                                                            }}
                                                        />
                                                    )}
                                                    <CardWithTick
                                                        style={{
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundColor: '#EAEAEA',
                                                        }}
                                                        backgroundUrl={`${BASE_API}${sticker.sticker_icon}`}
                                                        onClick={() => {
                                                            if (selected) {
                                                                if (!isTicked) {
                                                                    detailsHelper.addNewDetail(sticker);
                                                                }
                                                                updateModalHeight();
                                                                detailsHelper.setVisibleResetButton(true);
                                                                if (sticker.is_color) return;
                                                                switch (selected) {
                                                                    case 'body_leftSide':
                                                                        lookAtLeftDoor();
                                                                        break;
                                                                    case 'body_rightSide':
                                                                        lookAtRightDoor();
                                                                        break;
                                                                    case 'body_bonnet':
                                                                        lookAtBonnet();
                                                                        break;
                                                                }
                                                                moveToNextStep();
                                                            }
                                                        }}
                                                        ticked={isTicked}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 64,
                                                                width: 64,
                                                            }}
                                                        />
                                                    </CardWithTick>
                                                </div>
                                            );
                                        })}
                                </CustomScroll>
                                <Spacing size={10} />
                            </>
                        );
                    }}
                </Step>
                <Step>
                    {({ moveToPrevStep }) => {
                        updateModalHeight();
                        if (!chosenSticker) {
                            return null;
                        }
                        return (
                            <StickerConfiguration
                                onReadyClick={() => {
                                    rollback();
                                    moveToPrevStep();
                                }}
                                stickers={currentValue.stickers}
                                chosenSticker={chosenSticker}
                                setCurrentValue={setCurrentValue}
                            />
                        );
                    }}
                </Step>
            </Steps>
        </div>
    );
};
