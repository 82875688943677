import React from 'react';
import { createRoot } from 'react-dom/client';

import { RouterContext } from '@happysanta/router';

import { QueryClient, QueryClientProvider } from 'react-query';

import App from './app';
import { router } from './router';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const queryClient = new QueryClient();

window.addEventListener('error', (event) => event.preventDefault());

root.render(
    <QueryClientProvider client={queryClient}>
        <RouterContext.Provider value={router}>
            <App />
        </RouterContext.Provider>
    </QueryClientProvider>,
);
