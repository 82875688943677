import { useQuery } from 'react-query';
import axios from 'axios';

export interface IService {
    _key: string;
    name: string;
    description: string;
    duration?: number;
    price: number;
    type: {
        title: string;
        _id: string;
    };
    fromPrice: boolean;
}

export interface ScheduleInterface {
    _key: string;
    days: string;
    time: string;
}

export interface LocationScheduleInterface {
    _key: string;
    address: string;
    phone: string;
    schedule: ScheduleInterface[];
}

export interface ISalonPage {
    id: string;
    name?: string;
    description?: string;
    image?: string;
    locationSchedule?: LocationScheduleInterface[];
    address?: string;
    ourWorks?: Array<{ image: string }>;
    specialists?: Array<{ _key: string; name: string; image: string }>;
    contacts?: {
        telephone: string;
        vkId: string;
        email: string;
        workDays: { from: string; to: string };
        workTime: { from: string; to: string };
    };
    services?: IService[];
}

export const useGetSalonItem = (id: string) => {
    const PROJECT_ID = 'obfsgn0y';
    const DATASET = 'production';

    const SALON = encodeURIComponent(`*[_type == "salon" 
&& _id == "${id}"
]{
    ...,
    "id":_id,
    services[]{
        ...,
        type->{title,_id}
    },
    "address": address + ", " + city->.title, 
    specialists[]{
            name,
            _key,
            image
    },
    ourWorks[]{
        "image": asset
    },
    autofirms[]->{title,_id},
    "image": salonImage
}`);
    const URL = `https://${PROJECT_ID}.apicdn.sanity.io/v2021-10-21/data/query/${DATASET}?query=${SALON}`;

    return useQuery([id], async () => {
        const response = await axios.get<{ result: ISalonPage[] }>(URL);
        return response.data?.result[0];
    });
};
