import React from 'react';
import { useRouter } from '@happysanta/router';
import { Avatar, ModalCard, Snackbar } from '@vkontakte/vkui';
import { Icon16Done, Icon56AdvertisingOutline } from '@vkontakte/icons';

import { useBridge } from 'components/bridge-context-provider/bridge-context-provider';
import { ButtonFullWidth } from 'components/button-full-width/button-full-width';
import { useNotificationSnackbarLayer } from 'components/notifications-snackbar-provider/notifications-snackbar-provider';

import { type IWithId } from 'types/interfaces';

export const EnableNotificationsModal = ({ id }: IWithId) => {
    const router = useRouter();
    const { bridgeSend } = useBridge();
    const { setNotificationSnackbar } = useNotificationSnackbarLayer();

    const handleClose = React.useCallback(() => {
        router.popPageIfModal();
        setNotificationSnackbar(
            <Snackbar
                onClose={() => setNotificationSnackbar(null)}
                subtitle="Вы можете поменять настройки в профиле"
                before={
                    <Avatar size={24} style={{ background: 'var(--vkui--color_background_accent)' }}>
                        <Icon16Done fill="#fff" width={14} height={14} />
                    </Avatar>
                }
            >
                Вы не будете получать уведомления
            </Snackbar>,
        );
        localStorage.setItem('EnableNotificationsModal', '1');
    }, [router]);

    const handleNotificationsEnable = React.useCallback(async () => {
        router.popPageIfModal();
        await bridgeSend('VKWebAppAllowNotifications');
        setNotificationSnackbar(
            <Snackbar
                onClose={() => setNotificationSnackbar(null)}
                subtitle="Вы можете поменять настройки в профиле"
                before={
                    <Avatar size={24} style={{ background: 'var(--vkui--color_background_accent)' }}>
                        <Icon16Done fill="#fff" width={14} height={14} />
                    </Avatar>
                }
            >
                Уведомления включены
            </Snackbar>,
        );
        localStorage.setItem('EnableNotificationsModal', '1');
    }, [router]);

    return (
        <ModalCard
            id={id}
            onClose={handleClose}
            icon={<Icon56AdvertisingOutline />}
            header="Будьте в курсе всего"
            subheader="Узнавайте первыми о новых конфигурациях в авто кастомайзере, появлении новых салонов и лучших местах в баттле. Разрешите уведомления?"
            actions={
                <div style={{ display: 'flex', gap: '12px', width: '100%' }}>
                    <ButtonFullWidth mode="secondary" height={44} onClick={handleClose}>
                        Не сейчас
                    </ButtonFullWidth>
                    <ButtonFullWidth height={44} onClick={handleNotificationsEnable}>
                        Разрешить
                    </ButtonFullWidth>
                </div>
            }
        />
    );
};
