import React, { type ReactNode } from 'react';
import { Button, type ButtonProps } from '@vkontakte/vkui';

interface IProps extends ButtonProps {
    height: number;
    children: ReactNode;
}

export const ButtonFullWidth = (props: IProps) => {
    const { height, children, ...rest } = props;
    return (
        <Button
            {...rest}
            style={{
                width: '100%',
                height,
                ...rest.style,
            }}
        >
            {children}
        </Button>
    );
};
