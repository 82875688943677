import React, { createContext, useContext, useMemo } from 'react';

import { type IDefaults } from '../../types/tuning-model';
import { type IWithChildren } from '../../types/interfaces';

interface IVehicleStateContextValue {
    vehicleState: IDefaults;
    setVehicle: React.Dispatch<Partial<IDefaults>>;
}

export const VehicleStateContext = createContext<IVehicleStateContextValue | null>(null);

export const VehicleStateProvider = ({
    children,
    vehicleState,
    setVehicle,
}: IVehicleStateContextValue & IWithChildren) => {
    const value = useMemo(() => ({ vehicleState, setVehicle }), [setVehicle, vehicleState]);

    return <VehicleStateContext.Provider value={value}>{children}</VehicleStateContext.Provider>;
};

export const useVehicleState = () => {
    const value = useContext(VehicleStateContext);

    if (value === null) {
        throw new Error('useVehicleState must be used within a VehicleStateContext.');
    }

    return value;
};
