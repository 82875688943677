import { Alert } from '@vkontakte/vkui';

import { useDeleteProject } from '../../../queries/use-delete-project';

interface Props {
    onClose: () => void;
    projectId: number;
}
export default function DeleteAlert({ onClose, projectId }: Readonly<Props>) {
    const { mutateAsync: deleteProject } = useDeleteProject(projectId);

    return (
        <Alert
            onClose={onClose}
            header="Удаление проекта"
            text="Вы уверены, что хотите удалить этот проект?"
            actions={[
                {
                    title: 'Отмена',
                    autoClose: true,
                    mode: 'cancel',
                },
                {
                    title: 'Удалить',
                    mode: 'destructive',
                    autoClose: true,
                    action: deleteProject,
                },
            ]}
        />
    );
}
