import { Icon24ChevronLeft } from '@vkontakte/icons';
import { IconButton } from '@vkontakte/vkui';

interface ISubBarProps {
    goBack: VoidFunction;
}

const ButtonGoBack = ({ goBack }: ISubBarProps) => {
    return (
        <IconButton
            onClick={() => goBack()}
            style={{
                position: 'relative',
                width: 40,
                height: 49,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 12,
                color: 'var(--vkui--color_text_tertiary)',
            }}
        >
            <Icon24ChevronLeft color="#74A2D6" />
        </IconButton>
    );
};

export default ButtonGoBack;
