import {
    Cell,
    Div,
    FixedLayout,
    ScreenSpinner,
    Title,
    useAdaptivityWithJSMediaQueries,
    ViewWidth,
} from '@vkontakte/vkui';
import { Icon24CheckCircleOn } from '@vkontakte/icons';
import { type Dispatch, type SetStateAction, useCallback, useState } from 'react';
import { useRouter } from '@happysanta/router';

import { ButtonFullWidth } from '../../../../components/button-full-width/button-full-width';
import { type FILTER_TYPES, useGetFilterOptions } from '../../../../queries/use-get-filter-options';
import { PAGE_SALONS } from '../../../../router';

// const DefaultItem = ({ isDefault, onClick }: { isDefault: boolean; onClick: () => void }) => {
//     return (
//         <Cell onClick={() => onClick()} after={isDefault ? <Icon24CheckCircleOn /> : null}>
//             Любой
//         </Cell>
//     );
// };

interface IProps {
    setSelectedIds: Dispatch<SetStateAction<string[]>>;
    selectedIds: string[];
    filterType: FILTER_TYPES;
    refetch: VoidFunction;
    multiSelect?: boolean;
}

export const FilterModal = ({ selectedIds, filterType, setSelectedIds, multiSelect = true, refetch }: IProps) => {
    const router = useRouter();
    const [selectedOptions, setSelectedOptions] = useState(selectedIds);
    const { data: options, isLoading } = useGetFilterOptions(filterType);
    // const isDefault = selectedOptions.length === 0;
    const { viewWidth, isDesktop } = useAdaptivityWithJSMediaQueries();
    const onChoose = useCallback((id: string) => {
        setSelectedOptions((prevState) => {
            return multiSelect ? [...prevState, id] : [id];
        });
    }, []);
    const onUnChoose = useCallback((id: string) => {
        setSelectedOptions((prevState) => {
            return prevState.filter((originId) => originId !== id);
        });
    }, []);
    // const onChooseDefault = useCallback(() => {
    //     setSelectedOptions([]);
    // }, []);

    const button = (
        <ButtonFullWidth
            onClick={() => {
                router.pushPage(PAGE_SALONS);
                setSelectedIds(selectedOptions);
                refetch();
            }}
            style={{
                bottom: 0,
            }}
            height={44}
        >
            <Title level="3" weight="2">
                Показать результаты
            </Title>
        </ButtonFullWidth>
    );
    return (
        <div>
            {isLoading ? (
                <ScreenSpinner />
            ) : (
                <Div style={{ paddingBottom: '90px' }}>
                    {/* <DefaultItem onClick={onChooseDefault} isDefault={isDefault} /> */}
                    {options?.map((option) => {
                        const isChosen = selectedOptions.find((id) => id === option.id);
                        return (
                            <Cell
                                key={option.id}
                                onClick={isChosen ? () => onUnChoose(option.id) : () => onChoose(option.id)}
                                after={isChosen ? <Icon24CheckCircleOn /> : null}
                            >
                                {option.title}
                            </Cell>
                        );
                    })}
                </Div>
            )}
            {viewWidth === ViewWidth.MOBILE && !isDesktop ? (
                <FixedLayout
                    style={{
                        paddingBottom: 0,
                        backgroundColor: '#fff',
                    }}
                    vertical="bottom"
                >
                    <Div style={{ paddingBottom: 32 }}>{button}</Div>
                </FixedLayout>
            ) : (
                <Div
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: '#fff',
                    }}
                >
                    {button}
                </Div>
            )}
        </div>
    );
};
