import React from 'react';

interface IсonInterface {
    width: number;
    height: number;
}

export const IconDisk: React.FC<IсonInterface> = ({ height, width }) => {
    return (
        <div
            style={{
                height,
                width,
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 23 23" fill="none">
                <path d="M11.5 0.430273C5.39601 0.430273 0.430273 5.39601 0.430273 11.5C0.430273 17.604 5.39601 22.5697 11.5 22.5697C17.604 22.5697 22.5697 17.604 22.5697 11.5C22.5697 5.39601 17.604 0.430273 11.5 0.430273ZM11.5 20.8026C6.37072 20.8026 2.19738 16.6298 2.19738 11.5C2.19738 6.37073 6.37073 2.19738 11.5 2.19738C16.6293 2.19738 20.8026 6.37073 20.8026 11.5C20.8026 16.6298 16.6293 20.8026 11.5 20.8026Z"
                    fill="#99A2AD"
                    stroke="#99A2AD"
                    strokeWidth="0.2" />
                <path d="M7.20411 5.84618C4.08653 8.21434 3.47686 12.6771 5.84502 15.7947C8.21304 18.9121 12.676 19.5219 15.7935 17.1538C18.9111 14.7856 19.5206 10.3227 17.1526 7.20527C14.7845 4.08769 10.3217 3.47802 7.20411 5.84618ZM10.4562 8.80485L8.84713 6.68658C10.3096 5.88006 12.0273 5.80398 13.5208 6.39236L12.1919 8.69656C11.6224 8.5563 11.0177 8.58816 10.4562 8.80485ZM10.4636 12.2863C10.035 11.7221 10.1361 10.9026 10.7125 10.4648C11.2888 10.027 12.1052 10.149 12.534 10.7136C12.9675 11.2843 12.856 12.1016 12.2852 12.5352C11.7143 12.9688 10.8971 12.857 10.4636 12.2863ZM8.61216 11.4161L6.03597 12.0782C5.86962 10.4814 6.40379 8.84697 7.57303 7.65441L9.18209 9.77266C8.82257 10.2556 8.62967 10.8297 8.61216 11.4161ZM10.4851 16.8978C8.70663 16.5652 7.17008 15.3892 6.43273 13.6283L9.00982 12.9661C9.38621 13.6103 9.924 14.0279 10.5896 14.2452L10.4851 16.8978ZM14.1015 12.7488L16.6491 13.4189C16.2987 14.3649 15.6867 15.2257 14.8257 15.8797C13.9986 16.5079 13.0491 16.8607 12.0839 16.9619L12.1884 14.3086C12.5625 14.2184 12.9258 14.0578 13.253 13.8093C13.6337 13.5201 13.9111 13.1509 14.1015 12.7488ZM13.5787 9.49519L14.9079 7.1902C16.3827 8.35384 17.0928 10.0991 16.9788 11.8512L14.3713 11.1652C14.2943 10.4756 14.0143 9.94285 13.5787 9.49519Z"
                    fill="#99A2AD"
                    stroke="#99A2AD"
                    strokeWidth="0.2" />
            </svg>

        </div>
    );
};
