import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import React, { useState } from 'react';

import { useQueryClient } from 'react-query';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { vehicleConfigs } from '../../../../engine/vehicle-configs';
import { MODEL_URLS } from '../../../../constants/query-keys';
import { CardWithTick } from '../../../../components/card-with-tick/card-with-tick';
import { CategoriesSplit } from '../../../../components/categories-split/categories-split';
import { TuningHeader } from '../tuning-header';
import { BASE_API } from '../../../../constants/base-api';

import { type IModelData, type IModelInfo } from '../../../../queries/use-GLTF-urls';

import '../styles.css';

export const EditTirePickTireModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const queryClient = useQueryClient();
    const { tires } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};
    const preSelectedTire = tires?.find((tire) => tire.model_id === currentValue.tire)?.mark;
    const [selected, setSelected] = useState<string | undefined>(preSelectedTire);
    const { cars } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};
    const carMarks = uniq(cars?.map((car) => car.mark));
    const categories = uniqBy<IModelInfo>(tires, (tire) => tire.mark)
        .filter((tire) => tire.mark === currentValue.mark || !carMarks.includes(tire.mark))
        .map((tire) => ({
            label: tire.mark,
            value: tire.mark,
        }));

    const handleTireReset = React.useCallback(() => {
        setCurrentValue({ tire: vehicleConfigs.defaults.tire });
    }, [currentValue]);

    return (
        <>
            <TuningHeader children="Выберите модель" onReset={handleTireReset} />
            <CategoriesSplit categories={categories} selected={selected} setSelected={setSelected}>
                {tires
                    ?.filter((tire) => tire.mark === selected)
                    ?.map((t) => {
                        return (
                            <div key={t.model_id}>
                                <CardWithTick
                                    backgroundUrl={`${BASE_API}/static/assets/images/tire.png`}
                                    header={t.model_name}
                                    onClick={() => {
                                        setCurrentValue({ tire: t.model_id });
                                    }}
                                    ticked={t.model_id === currentValue.tire}
                                />
                            </div>
                        );
                    })}
            </CategoriesSplit>
        </>
    );
};
