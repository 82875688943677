import React from 'react';
import { ModalRoot } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';

import { ModalProjectShare } from '../../../profile-projects/modals/project-share';
import { type IProject } from '../../../../queries/use-get-all-projects';
import { type IProfile } from '../../../profile/profile';
import { BATTLE_PROFILE_PROJECTS, BATTLE_WELLCOME_MODAL, MODAL_PROJECT_SHARE } from '../../../../router';
import { BattleWelcomeModal } from './battle-welcome-modal';
import { BattleProfileProjectsModal } from './battle-projects-modal';

interface BattleModalsInterface {
    profile: IProfile | null;
}

export const BattleModals = ({ profile }: BattleModalsInterface) => {
    const location = useLocation();
    const router = useRouter();

    const [selectedProject, setSeletedProject] = React.useState<IProject | null>(null);

    return (
        <ModalRoot onClose={() => router.popPage()} activeModal={location.getModalId()} >
            <BattleWelcomeModal id={BATTLE_WELLCOME_MODAL} />
            <BattleProfileProjectsModal
                profile={profile}
                onShareProject={setSeletedProject}
                id={BATTLE_PROFILE_PROJECTS}
            />
            <ModalProjectShare project={selectedProject} id={MODAL_PROJECT_SHARE} />
        </ModalRoot>
    );
};
