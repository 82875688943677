import React from 'react';
import { Avatar, Card, Cell, ModalCard, ModalRoot, ScreenSpinner, Spacing, Title } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { Icon24Share } from '@vkontakte/icons';

import { BATTLE_LEADERS_MODAL } from '../../../../router';
import { ButtonFullWidth } from '../../../../components/button-full-width/button-full-width';

import { APP_ID, BASE_API } from '../../../../constants/base-api';

import { useGetBestProject } from '../../../../queries/use-get-best-project';
import { useBridge } from '../../../../components/bridge-context-provider/bridge-context-provider';
import { type ILeaderWithProfile } from '../../leaders';

import styles from './leaders-share-modal.module.css';

export const LeadersShareModal = ({ person }: { person?: ILeaderWithProfile }) => {
    const { data, isLoading } = useGetBestProject(person?.profile?.id || 0);
    const { bridgeSend } = useBridge();
    const router = useRouter();
    const location = useLocation();
    return (
        <ModalRoot onClose={() => router.popPage()} activeModal={location.getModalId()}>
            <ModalCard
                className={styles.modal}
                header={
                    !isLoading ? (
                        <div className={styles.cellInModal}>
                            <Cell
                                disabled
                                subtitle={`${person?.rating} баллов`}
                                before={<Avatar src={person?.profile?.photo_100} size={48} />}
                            >
                                {person?.profile?.first_name} {person?.profile?.last_name}
                            </Cell>
                        </div>
                    ) : null
                }
                id={BATTLE_LEADERS_MODAL}
            >
                <Spacing size={8}></Spacing>
                {!isLoading ? (
                    <>
                        <Card>
                            <div
                                style={{
                                    height: '40vh',
                                    margin: '0 auto',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundImage: `url(${BASE_API}/${data?.image})`,
                                }}
                            ></div>
                        </Card>
                        <Spacing size={8} />
                        <ButtonFullWidth
                            onClick={() => {
                                bridgeSend('VKWebAppShare', { link: `https://vk.com/services?w=app${APP_ID}` }).then(
                                    () => {
                                        router.popPageIfModal();
                                    },
                                );
                            }}
                            before={<Icon24Share />}
                            mode="secondary"
                            height={44}
                        >
                            <Title weight="3" level="3">
                                Поделиться
                            </Title>
                        </ButtonFullWidth>
                    </>
                ) : (
                    <>
                        <Spacing size={100}>
                            <ScreenSpinner />
                        </Spacing>
                    </>
                )}
            </ModalCard>
        </ModalRoot>
    );
};
