import { useBridge } from '../components/bridge-context-provider/bridge-context-provider';
import { APP_ID } from '../constants/base-api';

export const useGetProvideAccess = () => {
    const { bridgeSend } = useBridge();
    return async (scope?: string) => {
        const data = await bridgeSend('VKWebAppGetAuthToken', {
            app_id: APP_ID,
            scope: scope || '',
        });
        return data.access_token;
    };
};
