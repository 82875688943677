import React from 'react';
import { Tappable } from '@vkontakte/vkui';
import classNames from 'classnames';

import { type IWithChildren } from '../../types/interfaces';

import style from './custom-tabs-item.module.css';

export const CustomTabsItem = ({
    selected,
    children,
    onClick,
}: { selected: boolean; onClick: VoidFunction } & IWithChildren) => {
    return (
        <Tappable
            onClick={onClick}
            hasActive
            className={classNames(style.tabsItem, selected && style.tabsItemSelected)}
        >
            {children}
        </Tappable>
    );
};
